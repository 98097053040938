import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DatePipe, CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { RouterModule } from '@angular/router';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
// NG Translate
import { TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalModule, MsalService, MsalInterceptor } from '@azure/msal-angular';
import { HttpInterceptorService } from './core/interceptors/http-interceptor.service';

import { environment } from '../environments/environment';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DialogTemplateComponent } from '@anotationComponent/dialog-template/dialog-template.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';
import { LoaderService } from '@core-services/loader.service';
import { AuthGuardService } from '@core/services/auth-guard.service';
import { AuthService } from '@core/services/adal.service';
@NgModule({
  declarations: [AppComponent, DialogTemplateComponent],
  imports: [
    AgmCoreModule.forRoot({
      apiKey: environment.mapApiKey,
      libraries: ['places'],
    }),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    CommonModule,
    InputSwitchModule,
    AppRoutingModule,
    MsalModule,
    MatDialogModule,
    MatSnackBarModule,
    MatRadioModule,
    MsalModule.forRoot(
      environment.authConfig.config,
      environment.authConfig.angularConfig
    ),
    TranslateModule.forRoot({}),
  ],
  exports: [RouterModule],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    DatePipe,
    MsalService,
    LoaderService,
    AuthGuardService,
    AuthService,
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
