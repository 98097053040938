import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelcase',
})
export class CamelCase implements PipeTransform {
  transform(value: string): string {
    const val = value.charAt(0).toUpperCase() + value.slice(1);
    return val.replace(/([A-Z])/g, ' $1').trim();
  }
}
