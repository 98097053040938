import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'gdx-submit-modal',
  templateUrl: './submit-modal.component.html',
  styleUrls: ['./submit-modal.component.scss'],
})
export class SubmitModalComponent implements OnInit {
  @Output('cancel') cancel = new EventEmitter();
  @Output('save') save = new EventEmitter();
  @Output('continue') continue = new EventEmitter();

  @Input() mainMessage = '';
  @Input() cancelMessage = '';
  @Input() confirmMessage = '';
  @Input() saveMessage = '';
  @Input() lessOptions = false;
  blur = false;
  showModal = false;
  ngOnInit(): void {
    this.blur = true;
  }
  toggleModal(): void {
    this.showModal = !this.showModal;
  }
  close(): void {
    this.blur = false;
    this.showModal = false;
    this.cancel.emit(false);
  }
  proceed(): void {
    this.continue.emit();
  }

  saveData(): void {
    this.save.emit();
  }
}
