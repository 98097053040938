<div
  *ngIf="showGeniusDataModel"
  style="z-index: 999999"
  class="overflow-hidden fixed inset-0 z-50 outline-none h-screen focus:outline-none justify-center items-center flex"
>
  <div class="relative w-auto my-4 mx-auto max-w-xl">
    <!--content-->
    <div
      class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
    >
      <div
        class="cursor-pointer flex items-center p-2 justify-end"
        (click)="close()"
      >
        <span [inlineSVG]="collapse"> </span>
      </div>
      <!--header-->
      <div
        class="flex items-start h-10 border-b border-solid border-blueGray-200 rounded-t justify-between ml-10 mr-10"
      >
        <div>
          <h4 class="text-left absolute text-blue-800 text-2xl">
            Genius Data Platform
          </h4>
        </div>
      </div>

      <!--body-->
      <div class="relative pl-12 pr-10 pb-8">
        <div class="my-2 text-blueGray-500 leading-relaxed font-medium">
          <div><span class="text-red-600 text-base">Vision</span></div>
          <div class="pt-2 pb-2">
            <span class="text-base"
              >A Self Service Enterprise Data Platform</span
            >
          </div>
          <div class="text-red-600 text-base">Guiding Principles</div>
          <ol type="1" class="list-decimal">
            <li class="pt-2">
              <span class="text-base pl-3">
                Accelerate Data Engineering. Empower Data Scientists</span
              >
            </li>
            <li>
              <span class="text-base pl-3">
                Unified enterprise/sector wide catalog
              </span>
            </li>
            <li>
              <span class="text-base pl-3">
                Bring your own storages, computes and even code
              </span>
            </li>
            <li>
              <div class="text-base pl-3 flex flex-col">
                <div>GDC and GDE Modules for External Data</div>
                <div class="font-light pt-2 pb-2 text-base">
                  Make Data, Emergent, BioE; Shop Floor Data
                </div>
              </div>
            </li>
            <li>
              <span class="text-base pl-3">
                Accelerators, IDEs, Notebooks and SDK
              </span>
            </li>
            <li>
              <span class="text-base pl-3">
                Centralized end-to-end monitoring with SLA
              </span>
            </li>
            <li><span class="text-base pl-3"> GxP Compliant </span></li>
            <li>
              <span class="text-base pl-3"
                >In-built data testing and data quality tools</span
              >
            </li>
            <li>
              <span class="text-base pl-3"
                >Integrate best in class products</span
              >
            </li>
            <li>
              <span class="text-base pl-3"
                >Multi Tenant; Multi App Platform</span
              >
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="showGeniusDataModel"
  class="opacity-25 fixed inset-0 z-40 bg-black"
></div>
`
