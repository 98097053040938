<div
  class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
>
  <div class="relative w-auto my-6 mx-auto max-w-2xl">
    <!--content-->
    <div
      class="rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
    >
      <!--body-->
      <div class="relative rounded-lg w-96">
        <div class="my-4 text-blueGray-500 text-lg leading-relaxed font-bold">
          <div class="text-center text-2xl p-3">{{ mainMessage }}</div>

          <div class="container my-12 mx-auto px-6"></div>
        </div>
      </div>

      <div
        class="rounded-b-3xl flex items-center justify-center rounded-b overflow-hidden"
      >
        <div
          *ngIf="!lessOptions"
          (click)="proceed()"
          class="bg-blue-dark text-white w-full flex justify-center cursor-pointer h-10"
        >
          <span class="pt-2">{{ confirmMessage }}</span>
        </div>
        <div
          *ngIf="saveMessage != ''"
          (click)="saveData()"
          class="bg-green-dark text-white w-full flex justify-center cursor-pointer h-10"
        >
          <span class="pt-2">{{ saveMessage }}</span>
        </div>
        <div
          (click)="close()"
          class="bg-warmGray text-trueGray-dark w-full flex justify-center cursor-pointer h-10"
        >
          <span class="pt-2">{{ cancelMessage }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="blur" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
`
