import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { LABELS } from '@shared/shared.message';
@Component({
  selector: 'gdx-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  public isGdxPlatformModalFooter = false;
  public version = environment.version;
  public updateAt = environment.updateAt;
  public currentYear = new Date().getUTCFullYear();
  LABELS = LABELS;

  aboutGDT($event: any): void {
    this.isGdxPlatformModalFooter = true;
  }
  close($event: any): void {
    this.isGdxPlatformModalFooter = false;
  }
}
