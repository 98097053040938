import {
  Component,
  Input,
  OnInit,
  Output,
  OnChanges,
  EventEmitter,
  SimpleChanges,
  ChangeDetectorRef,
  HostBinding,
} from '@angular/core';
import { btnTypes } from '../../enum/button';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'gdx-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
})
export class ToggleButtonComponent {
  @Input('parentForm') parentForm: any;
  @Input('name') name: any = '';
  @Input('id') id: any = '';
  @Input('control') control: any = '';
  @Input('checked') checked: any = false;
  @Input('label') label: any;
  @Input('value') value: any;
  @Output('onChange') onChange = new EventEmitter();

  onToggle(event: any): any {
    this.onChange.emit(event);
  }
}
