import {
  Component,
  OnInit,
  DoCheck,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataService } from 'src/app/core/services/data.service';
import { btnTypes } from '../../enum/button';
import * as moment from 'moment';
import { AppService } from '../../../core/services/app.service';

@Component({
  selector: 'gdx-common-filter',
  templateUrl: './common-filter.component.html',
})
export class CommonFilterComponent implements OnInit, DoCheck {
  @Output() expanded = new EventEmitter<boolean>();
  @Output() onApplyFilter = new EventEmitter<object>();
  @Input() commonClass = '';
  rolesData = [];
  categories = [];
  events = [];
  actions = [];
  table = [];
  apps = [];
  esignReasons = [];
  status = [{ name: 'success' }, { name: 'failed' }];
  isAdvanced = false;
  filterOpen = false;
  isShow = true;
  expand = 'assets/images/filter.svg';
  collapse = 'assets/images/cross.svg';
  advanced = '(+)';
  notAdvanced = '(-)';
  public btnType = btnTypes;
  public commonFilter: FormGroup = new FormGroup({});
  allSelectPlaceHolder = 'Select';
  maxDate: any = new Date();
  errorMessage = '';
  isAppDisabled = false;

  constructor(
    public formBuilder: FormBuilder,
    private dataService: DataService,
    private appService: AppService
  ) {}

  commonForm(): void {
    this.commonFilter = this.formBuilder.group({
      selectedPeriod: [
        [moment(new Date(new Date().setHours(0, 0, 0))).toDate(), new Date()],
      ],
      selectedUserId: '',
      selectedEsignReason: '',
      selectedRole: '',
      selectedEvent: '',
      selectedCategory: '',
      selectedStatus: '',
      selectedApp: '',
      selectedAction: '',
      selectedTable: '',
    });
  }

  ngOnInit(): void {
    this.commonForm();
  }
  ngDoCheck(): void {
    this.dataService.onHide.subscribe((result) => {
      this.isShow = result;
    });
  }

  expandCollapse = (): any => {
    this.filterOpen = !this.filterOpen;
    this.expanded.emit(this.filterOpen);
  };

  expandCollapseAdvanced = (): any => {
    this.isAdvanced = !this.isAdvanced;
  };
  applyFilter(event: any): void {
    const filterData = this.commonFilter.value;
    const filterdValue = {
      startDate: new Date(),
      endDate: new Date(),
      app: [] as any,
      user: [] as any,
      esignReason: [] as any,
      role: [] as any,
      event: [] as any,
      category: [] as any,
      status: '',
      action: [] as any,
      table: [] as any,
      reportType: 0,
    };
    Object.keys(filterData).map((item: string) => {
      const key = item.replace('selected', '');
      this.checkData(item, key, filterdValue, filterData);
    });
    this.setDate(filterdValue);
    this.onApplyFilter.emit({ filterdValue, event });
    this.expandCollapse();
  }

  setDate(filterdValue: {
    startDate: Date;
    endDate: Date;
    app: any;
    user: any;
    esignReason: any;
    role: any;
    event: any;
    category: any;
    status: string;
    action: any;
    table: any;
    reportType: number;
  }): void {
    if (
      this.commonFilter.controls.selectedPeriod &&
      this.commonFilter.controls.selectedPeriod.value[0] &&
      this.commonFilter.controls.selectedPeriod.value[1]
    ) {
      const startDate = moment(
        this.commonFilter.controls.selectedPeriod.value[0]
      );
      const endDate = moment(
        this.commonFilter.controls.selectedPeriod.value[1]
      );
      const diff = endDate.diff(startDate, 'days');
      this.dateCheck(diff, filterdValue);
    }
  }

  dateCheck(
    diff: number,
    filterdValue: {
      startDate: Date;
      endDate: Date;
      app: any;
      user: any;
      esignReason: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    }
  ): void {
    if (diff === 0) {
      filterdValue.startDate = moment(
        new Date(filterdValue.startDate).setHours(0, 0, 0)
      ).toDate();
      filterdValue.endDate = moment(
        new Date(filterdValue.startDate).setHours(23, 59, 59)
      ).toDate();
    }
  }

  checkData(
    item: string,
    key: string,
    filterdValue: {
      startDate: Date;
      endDate: Date;
      app: any;
      user: any;
      esignReason: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    },
    filterData: any
  ): void {
    if (item.includes(key)) {
      if (key === 'Period') {
        this.periodCheck(filterdValue, filterData);
      } else if (key === 'UserId') {
        this.useridCheck(filterData, filterdValue);
      } else if (key === 'Status') {
        this.statusCheck(filterData, item, filterdValue);
      } else if (key === 'EsignReason') {
        this.esignReasonCheck(filterData, item, filterdValue);
      } else if (key === 'Category') {
        this.categoryCheck(filterData, item, filterdValue);
      } else if (key === 'Role') {
        this.roleCheck(filterData, item, filterdValue);
      } else if (key === 'Event') {
        this.eventCheck(filterData, item, filterdValue);
      } else if (key === 'App') {
        this.appCheck(filterData, item, filterdValue);
      } else if (key === 'Action') {
        this.actioncheck(filterData, item, filterdValue);
      } else if (key === 'Table') {
        this.tableCheck(filterData, item, filterdValue);
      } else {
      }
    }
  }

  tableCheck(
    filterData: any,
    item: string,
    filterdValue: {
      startDate: Date;
      endDate: Date;
      app: any;
      user: any;
      esignReason: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    }
  ): void {
    if (filterData[item] && filterData[item].length) {
      filterdValue.table = filterData[item].length
        ? filterData[item].map((item7: any) => item7.name)
        : [];
    }
  }

  actioncheck(
    filterData: any,
    item: string,
    filterdValue: {
      startDate: Date;
      endDate: Date;
      app: any;
      user: any;
      esignReason: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    }
  ): void {
    if (filterData[item] && filterData[item].length) {
      filterdValue.action = filterData[item].length
        ? filterData[item].map((item6: any) => item6.name)
        : [];
    }
  }

  appCheck(
    filterData: any,
    item: string,
    filterdValue: {
      startDate: Date;
      endDate: Date;
      app: any;
      user: any;
      esignReason: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    }
  ): void {
    if (filterData[item] && filterData[item].length) {
      filterdValue.app = filterData[item].length
        ? filterData[item].map((item5: any) => item5.name)
        : [];
    }
  }

  eventCheck(
    filterData: any,
    item: string,
    filterdValue: {
      startDate: Date;
      endDate: Date;
      app: any;
      user: any;
      esignReason: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    }
  ): void {
    if (filterData[item] && filterData[item].length) {
      filterdValue.event = filterData[item].length
        ? filterData[item].map((item4: any) => item4.name)
        : [];
    }
  }

  roleCheck(
    filterData: any,
    item: string,
    filterdValue: {
      startDate: Date;
      endDate: Date;
      app: any;
      user: any;
      esignReason: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    }
  ): void {
    if (filterData[item] && filterData[item].length) {
      filterdValue.role = filterData[item].length
        ? filterData[item].map((item3: any) => item3.name)
        : [];
    }
  }

  categoryCheck(
    filterData: any,
    item: string,
    filterdValue: {
      startDate: Date;
      endDate: Date;
      app: any;
      user: any;
      esignReason: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    }
  ): void {
    if (filterData[item] && filterData[item].length) {
      filterdValue.category = filterData[item].length
        ? filterData[item].map((item2: any) => item2.name)
        : [];
    }
  }

  esignReasonCheck(
    filterData: any,
    item: string,
    filterdValue: {
      startDate: Date;
      endDate: Date;
      app: any;
      user: any;
      esignReason: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    }
  ): void {
    if (filterData[item] && filterData[item].length) {
      filterdValue.esignReason = filterData[item].length
        ? filterData[item].map((item1: any) => item1.name)
        : [];
    }
  }

  statusCheck(
    filterData: any,
    item: string,
    filterdValue: {
      startDate: Date;
      endDate: Date;
      app: any;
      user: any;
      esignReason: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    }
  ): void {
    if (filterData[item] && filterData[item].name) {
      filterdValue.status = filterData[item].name ? filterData[item].name : '';
    }
  }

  useridCheck(
    filterData: any,
    filterdValue: {
      startDate: Date;
      endDate: Date;
      app: any;
      user: any;
      esignReason: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    }
  ): void {
    if (filterData.selectedUserId.length > 0) {
      filterdValue.user = filterData.selectedUserId
        .replace(/\s/g, '')
        .split(',');
    }
  }

  periodCheck(
    filterdValue: {
      startDate: Date;
      endDate: Date;
      app: any;
      user: any;
      esignReason: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    },
    filterData: any
  ): void {
    filterdValue.startDate = filterData.selectedPeriod[0]
      ? filterData.selectedPeriod[0]
      : '';
    filterdValue.endDate = filterData.selectedPeriod[1]
      ? filterData.selectedPeriod[1]
      : moment(new Date(filterdValue.startDate).setHours(11, 59, 59)).toDate();
  }

  resetFilter(): void {
    this.commonForm();
    const app = this.appService.getApp();
    if (app && app.appId) {
      this.commonFilter.patchValue({
        selectedApp: [{ name: app.appCode, id: app.appId }],
      });
      this.isAppDisabled = true;
    } else {
      this.isAppDisabled = false;
    }
    this.commonFilter.patchValue({
      selectedPeriod: [
        moment(new Date(new Date().setHours(0, 0, 0))).toDate(),
        new Date(),
      ],
    });
  }
}
