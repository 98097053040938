export const DISPLAYED_COLUMNS = [
  'select',
  'fileName',
  'classification',
  'grading',
  'updatedAt',
  'action',
];
export enum DateType {
  FromDate = 'fromDate',
  ToDate = 'toDate',
}
export const SORT_BY = 'Sort By';
export const EMPTY_STRING = '';
export enum ItemType {
  CreatedBy = 'createdBy',
  Search = 'search',
  Annotation = 'annotation',
  MediaType = 'media',
}
export const NO_LINE = 'noline';
export enum NavigationUrls {
  ImageDetails = '/image',
  Preview = '/preview',
}

export const ANNOTATION_STATUS = {
  ALL: 'All',
  ANNOTATED: 'annotated',
  NON_ANNOTATED: 'non-annotated',
  IMAGES_ANNOTATED: 'imagesAnnotated',
  DATASET_CREATED: 'datasetCreated',
  IMAGES_EXTRACTED: 'imagesExtracted',
  ANNOTATION_IN_PROGRESS: 'annotationInProgress',
  EXTRACTION_ERROR: 'extractionError',
};

export const MEDIA_TYPE = {
  ALL: 'All',
  IMAGE: 'image',
  VIDEO: 'video',
};

export const VIDEO_DETAILS = {
  FILE_NAME: 'FILE NAME',
  TRAY_NAME: 'TRAY NAME',
  STATUS: 'STATUS',
};

export const MESSAGES = {
  NO_MEDIA:
    'No Media to display with current filter, Please change the filter.',
  LIST_TEXT1: '1. Please go to Filters to start with and view images.',
  LIST_TEXT2: '2. Once you save Filter, You can start from the saved filter.',
  DELETED_SUCCESS: ' Deleted Successfully.',
  IMG_DELETED: 'Images deleted successfully',
  INSPECTION_TOOLTIP: 'Image is sent for Inspection',
  NOT_ANNOTATED: 'Selected Media is not annotated',
  DIFFERENT_TRAY: 'Selected Media have different tray Id!',
  DATASET_ADDED: 'Dataset Successfully Added',
};

export const LABELS = {
  TOTAL_ITEMS: 'Total Items',
  NUMBER_OF_ITEMS: 'Number of items per page',
  ALL: 'All',
  APPLY_FILTER: 'Applied Filters',
  CreateDataset: 'Create Dataset',
  UpdateLine: 'Update Line No.',
  Delete: 'Delete',
  DELETE_WARNING: 'Do you want to delete?',
  Cancel: 'Cancel',
  Pass_Fail: 'Pass/ Fail',
  Download: 'Download',
  Classify: 'Classify',
  Send_for_inspection: 'Send for inspection',
  TotalSelected: 'Total Selected',
  XLX: 'XLX',
  CSV: 'CSV',
  User: 'User',
  AnnotationType: 'Annotation Type',
  MediaType: 'Media Type',
  Filters: 'Filters',
  Date: 'Date',
  Instruction: 'Instruction',
  NonAnnotated: 'Non-annotated',
  SipGradingTool: 'SIP Grading Tool',
  SentForInspection: 'SentForInspection',
};
export const DATE_TIME_FORMAT_REQ = 'YYYY-MM-DD';
