import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'gdx-autocomplete',
  templateUrl: './autocomplete.component.html',
  styles: [],
})
export class AutocompleteComponent {
  @Input('id') id = '';
  @Input('name') name = '';
  @Input('placeholder') placeholder = 'Select';
  @Input('control') control = '';
  @Input('parentForm') parentForm: FormGroup = new FormGroup({});
  @Input('class') class = '';
  @Input('disabled') disabled = false;
  @Input('minLength') minLength = 1;
  @Input('filterField') filterField = 'name';
  @Input('multiple') multiple = false;
  @Input('inputStyleClass') inputStyleClass = '';
  @Input('panelStyleClass') panelStyleClass = '';
  @Input('filteredEntities') filteredEntities: any[] = [];
  @Input('emptyMessage') emptyMessage = 'No data found';
  @Output('onFilter') onFilter = new EventEmitter();
  @Output('onSelect') onSelect = new EventEmitter();
  @Output('onUnselect') onUnselect = new EventEmitter();
  @Output('onBlur') onBlur = new EventEmitter();
  @Output('onKeyUp') onKeyUp = new EventEmitter();

  filterEntity(event: any): any {
    this.onFilter.emit(event.query);
  }

  selectEntity(event: any): any {
    this.onSelect.emit(event);
  }

  unselectEntity(event: any): any {
    this.onUnselect.emit(event);
  }

  blurEntity(event: any): any {
    this.onBlur.emit(event);
  }

  keyUpEntity(event: any): any {
    this.onKeyUp.emit(event);
  }
}
