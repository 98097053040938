<ul class="z-50">
  <li
    class="bg-emerald-200 text-sm font-medium text-gray-600 border border-gray-200 border-b-0 hover:bg-gray-300 hover:text-black cursor-pointer focus:outline-none p-2"
  >
    <div (click)="downloadClick('csv')" class="text-center cursor-pointer">
      Download as CSV
    </div>
  </li>
  <li
    class="bg-emerald-200 text-sm font-medium text-gray-600 border border-gray-200 border-b-0 hover:bg-gray-300 hover:text-black cursor-pointer focus:outline-none p-2"
  >
    <div (click)="downloadClick('xlsx')" class="text-center cursor-pointer">
      Download as Excel
    </div>
  </li>
</ul>
