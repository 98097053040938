const labels = {
  expName: 'Experiment Name',
  runExp: 'RUN EXPERIMENT',
  dataset: 'Dataset',
  noData: 'No Data',
  pleaseSelectTray: 'Please select a Tray',
  nameRequired: 'Name is Required',
  nameExists: 'Experiment Name already exists',
  kitName: 'Kit Name',
  trayName: 'Tray Name',
  searchTray: 'Search for Tray...',
  noRecords: 'No records found.',
};

export { labels };
