import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();
  private imageListingDatSource = new BehaviorSubject('default message');
  imageListingData = this.imageListingDatSource.asObservable();
  private fullScreenDataSource = new BehaviorSubject(false);
  fullScreenData = this.fullScreenDataSource.asObservable();
  public srchclear = false;
  public dateclear = false;

  private navigatedToAddImageSource = new BehaviorSubject(false);
  navigatedToAddImage = this.navigatedToAddImageSource.asObservable();

  private userRoleSource = new BehaviorSubject('NOROLE');
  userRole = this.userRoleSource.asObservable();
  private userPermissionSource = new BehaviorSubject([]);
  userPermission = this.userPermissionSource.asObservable();
  private currentPageSource = new BehaviorSubject('default page');
  currentPage = this.currentPageSource.asObservable();
  private currentPageNumberSource = new BehaviorSubject(1);
  currentPageNumber = this.currentPageNumberSource.asObservable();
  private userPrefDataSource = new BehaviorSubject({});
  userPrefData = this.userPrefDataSource.asObservable();
  private selectedUserRoleSource = new BehaviorSubject('NOROLE');
  selectedUserRole = this.selectedUserRoleSource.asObservable();
  private setUserAllRoleSource = new BehaviorSubject({});
  selectedUserAllRole = this.setUserAllRoleSource.asObservable();
  private gradingStatusSource = new BehaviorSubject([]);
  gradingStatusList = this.gradingStatusSource.asObservable();

  private failReasonSource = new BehaviorSubject([]);
  failReasonList = this.failReasonSource.asObservable();

  private setAllAppSource = new BehaviorSubject([]);
  selectedAllApp = this.setAllAppSource.asObservable();
  private selectedBreadcrumbSource = new BehaviorSubject([]);
  selectedBreadcrumb = this.selectedBreadcrumbSource.asObservable();
  private isPlatformAdminData = new BehaviorSubject(false);
  isPlatformAdmin = this.isPlatformAdminData.asObservable();
  modeladdeditsubject: BehaviorSubject<any>;
  connectionaddeditSubject: BehaviorSubject<any>;
  datasetEditSubject: BehaviorSubject<any>;
  hierarchyTypeaddeditSubject: BehaviorSubject<any>;
  recommendHierarchyaddeditSubject: BehaviorSubject<any>;
  totaldatasetEditSubject: BehaviorSubject<any>; // for editing total dataset
  goToAddImage: BehaviorSubject<any>;
  selectedAppObjr: Subject<any> = new Subject<any>();
  appConfigData: Subject<any> = new Subject<any>();
  tntReload: Subject<any> = new Subject<any>();
  appsData = [];
  constructor() {
    this.modeladdeditsubject = new BehaviorSubject({
      editMode: false,
      objId: '',
    });
    this.connectionaddeditSubject = new BehaviorSubject({
      editMode: false,
      id: '',
      parentName: '',
    });
    this.datasetEditSubject = new BehaviorSubject({
      editMode: false,
      id: '',
      parentName: '',
    });
    this.hierarchyTypeaddeditSubject = new BehaviorSubject({
      editMode: false,
      id: '',
    });
    this.recommendHierarchyaddeditSubject = new BehaviorSubject({
      editMode: false,
      id: '',
    });
    this.totaldatasetEditSubject = new BehaviorSubject({
      editMode: false,
      id: '',
    });
    this.goToAddImage = new BehaviorSubject({
      isEditMasterDataset: false,
    });
  }
  changeAppEdit(type: any): void {
    this.modeladdeditsubject.next(type);
  }
  changeTotalDataset(dObj: any): void {
    this.totaldatasetEditSubject.next(dObj);
  }
  changeConnEdit(connObj: any): void {
    this.connectionaddeditSubject.next(connObj);
  }
  changeDatasetEdit(connObj: any): void {
    this.datasetEditSubject.next(connObj);
  }
  changeHTypeEdit(hTypeObj: any): void {
    this.hierarchyTypeaddeditSubject.next(hTypeObj);
  }
  changeRecHierarchyEdit(recHierarchy: any): void {
    this.recommendHierarchyaddeditSubject.next(recHierarchy);
  }
  toAddImage(obj: any): void {
    this.goToAddImage.next(obj);
  }

  changeMessage(message: any): void {
    this.messageSource.next(message);
  }
  setImageListingData(data: any): void {
    this.imageListingDatSource.next(data);
  }
  setIfFullScreen(isFullScreen: boolean): void {
    this.fullScreenDataSource.next(isFullScreen);
  }
  setIfNavigatedToAddImage(isNavigated: boolean): void {
    this.navigatedToAddImageSource.next(isNavigated);
  }
  setIfPlatformAdmin(isPlatformAdmin: boolean): void {
    this.isPlatformAdminData.next(isPlatformAdmin);
  }
  setUserRole(role: any): void {
    this.userRoleSource.next(role);
  }
  setUserPermission(data: any): void {
    this.userPermissionSource.next(data);
  }
  setCurrentPage(pageName: string): void {
    this.currentPageSource.next(pageName);
  }
  setCurrentPageNumber(pageNumber: number): void {
    this.currentPageNumberSource.next(pageNumber);
  }
  setSelectedUserRole(role: string): void {
    this.selectedUserRoleSource.next(role);
  }
  setUserAllRole(role: any): void {
    this.setUserAllRoleSource.next(role);
  }
  setAllApp(Apps: any): void {
    this.setAllAppSource.next(Apps);
  }
  setUserPrefData(data: any): void {
    this.userPrefDataSource.next(data);
  }
  setGradingStatusDataSource(data: any): void {
    this.gradingStatusSource.next(data);
  }

  setFailReasonDataSource(data: any): void {
    this.failReasonSource.next(data);
  }

  setBreadcrumbData(data: any): void {
    this.selectedBreadcrumbSource.next(data);
  }

  checkAisiApp(): boolean {
    let chkAisiapp = false;
    if (
      localStorage.getItem('selectedAppName').toLowerCase().includes('aisi') ||
      localStorage
        .getItem('selectedAppName')
        .toLowerCase()
        .includes('track_and_trace')
    ) {
      chkAisiapp = true;
    }
    return chkAisiapp;
  }

  setAppConfig(appConfigData: any): void {
    this.appConfigData.next(appConfigData);
  }

  setAppsData(apps: any): void {
    this.appsData = apps;
  }

  getAppsData(): any {
    return this.appsData;
  }
}
