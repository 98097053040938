import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppStorageService {
  readonly SELECTED_ROLE = 'selectedRole';
  readonly SELECTED_APP = 'selectedApp';
  readonly SELECTED_APP_NAME = 'selectedAppName';
  readonly APP_ROLES = 'app-roles';
  readonly SAS_TOKEN = 'sasToken';
  readonly ML_TOKEN = 'mltoken';
  readonly EDIT_SAS_TOKEN = 'editSasToken';
  readonly STORAGE_ACCOUNT = 'storageAccount';
  readonly STORAGE_CONTAINER = 'storageContainer';
  readonly STORAGE_FILE_SHARE = 'storageFileShareName';
  readonly STORAGE_ROOT_DIRECTORY = 'storageRootDirectory';
  readonly DATATYPES = 'dataTypes';
  readonly TRAY_NAME = 'trayName';
  readonly TRAY_ID = 'trayId';

  setRole(role: string): void {
    localStorage.setItem(this.SELECTED_ROLE, role);
  }
  getRole(): any {
    return JSON.parse(localStorage.getItem(this.SELECTED_ROLE));
  }
  getApp(): any {
    return localStorage.getItem(this.SELECTED_APP);
  }
  getAppName(): any {
    return localStorage.getItem(this.SELECTED_APP_NAME);
  }
  setAppRoles(rolse: Array<string>): any {
    localStorage.setItem(this.APP_ROLES, JSON.stringify(rolse));
  }
  getAppRoles(): Array<string> {
    let roleData: Array<string> = [];
    const roles = localStorage.getItem(this.APP_ROLES);
    if (roles) {
      roleData = JSON.parse(roles);
    }
    return roleData;
  }
  setSAS(sasToken: string): void {
    localStorage.setItem(this.SAS_TOKEN, sasToken);
  }
  setDatatypes(dataTypes: any): void {
    localStorage.setItem(this.DATATYPES, JSON.stringify(dataTypes));
  }
  getSAS(): any {
    return localStorage.getItem(this.SAS_TOKEN);
  }
  getDatatypes(): any {
    return localStorage.getItem(this.DATATYPES);
  }
  getMlToken(): any {
    return localStorage.getItem(this.ML_TOKEN);
  }
  setEditSAS(editSasToken: string): void {
    localStorage.setItem(this.EDIT_SAS_TOKEN, editSasToken);
  }
  getEditSAS(): any {
    return localStorage.getItem(this.EDIT_SAS_TOKEN);
  }
  setStorageAccount(storageAccount: string): void {
    localStorage.setItem(this.STORAGE_ACCOUNT, storageAccount);
  }
  getStorageAccount(): any {
    return localStorage.getItem(this.STORAGE_ACCOUNT);
  }
  setStorageContainer(storageContainer: string): void {
    localStorage.setItem(this.STORAGE_CONTAINER, storageContainer);
  }
  getStorageContainer(): any {
    return localStorage.getItem(this.STORAGE_CONTAINER);
  }
  setStorageFileShare(storageFileShare: string): void {
    localStorage.setItem(this.STORAGE_FILE_SHARE, storageFileShare);
  }
  getStorageFileShare(): any {
    return localStorage.getItem(this.STORAGE_FILE_SHARE);
  }
  setFileShareRootDirectory(fileShareRootDirectory: string): void {
    localStorage.setItem(this.STORAGE_ROOT_DIRECTORY, fileShareRootDirectory);
  }
  getFileShareRootdirectory(): any {
    return localStorage.getItem(this.STORAGE_ROOT_DIRECTORY);
  }
  setTrayName(trayName: string): void {
    localStorage.setItem(this.TRAY_NAME, trayName);
  }
  setTrayId(trayId: string): void {
    localStorage.setItem(this.TRAY_ID, trayId);
  }
  getTrayName(): any {
    return localStorage.getItem(this.TRAY_NAME);
  }
  getTrayId(): any {
    return localStorage.getItem(this.TRAY_ID);
  }
  clearTrayName(): void {
    localStorage.removeItem(this.TRAY_NAME);
  }
  clearTrayId(): void {
    localStorage.removeItem(this.TRAY_ID);
  }
}
