import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, pipe, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { url } from 'src/app/app.config';

@Injectable({
  providedIn: 'root',
})
export class PlatformAdminService {
  baseURL: string = environment.entryAPI;
  constructor(private http: HttpClient) {}

  getAllConnections(): any {
    return this.http.get(this.baseURL + url.allConnections);
  }
  getStorages(): any {
    return this.http.get(this.baseURL + url.storages);
  }
  getStorageType(): any {
    return this.http.get(this.baseURL + url.getType);
  }
  getScopeType(): any {
    return this.http.get(this.baseURL + url.scopeType);
  }
  getConnectionDetailsWithId(id): any {
    return this.http.get(this.baseURL + url.connectionDetailsWithId + id);
  }
  getHierarchyDetailsWithId(id): any {
    return this.http.get(this.baseURL + url.hierarchyDetailsWithId + id);
  }
  getHierarchyApplications(): any {
    return this.http.get(this.baseURL + url.apps);
  }
  getDomainDetailsWithId(id): any {
    return this.http.get(this.baseURL + url.domainDetailsWithId + id);
  }
  getConnectionDetailsWithName(name): any {
    return this.http.get(this.baseURL + url.connectionDetailsWithName + name);
  }
  getHierarchyDetailsWithName(name, appId): any {
    return this.http.get(
      this.baseURL +
        url.hierarchyDetailsWithName +
        name +
        '&&' +
        'appId=' +
        appId
    );
  }
  deleteConnection(id): any {
    return this.http.delete(this.baseURL + url.deleteConnection + '?id=' + id);
  }
  deleteApplication(id): any {
    return this.http.delete(this.baseURL + url.apps + '/' + id);
  }
  deleteHierarchyTypes(id): any {
    return this.http.delete(this.baseURL + url.deleteHierarchyType + '/' + id);
  }
  deleteDomain(id): any {
    return this.http.put(this.baseURL + url.deleteDomain + '?id=' + id, '');
  }
  createApplication(data): any {
    return this.http.post(
      environment.entryAPI + url.addUpdateApplication,
      data,
      {
        observe: 'response',
      }
    );
  }
  createHierarchyType(data): any {
    return this.http.post(
      environment.entryAPI + url.createHierarchyTypes,
      data,
      {
        observe: 'response',
      }
    );
  }
  createConnection(data): any {
    return this.http.post(
      environment.entryAPI + url.addUpdateConnection,
      data,
      {
        observe: 'response',
      }
    );
  }
  createDomain(data): any {
    return this.http.post(environment.entryAPI + url.getDomains, data, {
      observe: 'response',
    });
  }

  testConnection(data): any {
    return this.http.post(environment.entryAPI + url.testConnection, data);
  }
  handleError(error): any {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
  fetchSearchTermDetails(apiType, searchKey): any {
    if (apiType === 'apps') {
      return this.http.get(
        this.baseURL + url.appDetails + '%' + searchKey + '%'
      );
    } else if (apiType === 'connections') {
      return this.http.get(
        this.baseURL + url.connectionDetailsWithName + '%' + searchKey + '%'
      );
    } else {
    }
  }
  fetchAutoSuggestions(searchfor: string, searchterm: string): any {
    return this.http.get(
      this.baseURL + url.autoSuggestionOwner + '/' + searchterm
    );
  }
  getAllHierarchyTypes(): any {
    return this.http.get(this.baseURL + url.getHierarchyTypes);
  }
  getDomains(): any {
    return this.http.get(this.baseURL + url.getDomains);
  }
}
