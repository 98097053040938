import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gdx-download-dropdown',
  templateUrl: './download-dropdown.component.html',
  styles: [],
})
export class DownloadDropdownComponent {
  @Output('onDownload') onDownlaod = new EventEmitter();

  downloadClick(event: string): any {
    this.onDownlaod.emit(event);
  }
}
