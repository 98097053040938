import { last, each, merge } from 'lodash';
import * as moment from 'moment';

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { ElementRef } from '@angular/core';

@Component({
  selector: 'gdx-tabulator-table',
  templateUrl: './tabulator-table.component.html',
  styles: [],
})
export class TabulatorTableComponent implements AfterViewInit, OnChanges {
  @Output() newItemEvent = new EventEmitter();
  @Output() onRowSelected = new EventEmitter();
  @Output() onRowValueChanged = new EventEmitter();
  @Input() tableData: any = [];
  @Input() columnHeaders: any = [];
  @Input() tableConfig: any = [];
  @Input() hierarchyFilter = false;
  @ViewChild('tabulator') tabulator!: ElementRef;
  @ViewChild('fieldEl') fieldEl!: ElementRef;
  @ViewChild('typeEl') typeEl!: ElementRef;
  @ViewChild('valueEl') valueEl!: ElementRef;
  tab = document.createElement('div');
  selectedRow: any;
  table: any;
  FilterDropdownArr: any;

  constructor(public elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    window.moment = moment;
    this.drawTable();
  }

  drawTable(): void {
    const obj: Tabulator.Options = {
      data: this.tableData,
      movableRows: false,
      layout: 'fitDataTable',
      columns: this.columnHeaders,
      headerFilterPlaceholder: 'filter',
      // Example Syntax: Pagination :-
      // paginationSize: 6,
      // paginationSizeSelector: [3, 6, 8, 10],
      // pagination: 'local',
      // paginationSize: 20,
      tabEndNewRow: true,
      rowClick: (e: any, row: any) => this.clickedRow(row),
      dataChanged: (data: any) => this.syncTableData(),
      columnResized: (column: any) => {
        const lastCol: any = last(column.getTable().getColumns());
        const otherColumns = column
          .getTable()
          .getColumns()
          .filter((x: any) => x.isVisible() && x !== lastCol);
        let otherWidth = 0;
        each(otherColumns, (col) => {
          otherWidth += col.getWidth();
        });
        let scrollBarWidth =
          column.getTable().element.scrollWidth -
          column.getTable().element.children[1].scrollWidth;
        scrollBarWidth = scrollBarWidth > 0 ? scrollBarWidth : 0;
        const effWidth =
          column.getTable().element.scrollWidth - otherWidth - scrollBarWidth;
        if (
          lastCol.getWidth() < effWidth ||
          lastCol.getWidth() > lastCol._column.maxWidth
        ) {
          if (lastCol._column.minWidth && effWidth < lastCol._column.minWidth) {
            lastCol.setWidth(lastCol._column.minWidth);
          } else {
            lastCol.setWidth(effWidth);
          }
        }
      },
    };

    if (this.tableConfig) {
      merge(obj, this.tableConfig);
    }
    this.table = new Tabulator(this.tab, obj);

    this.tabulator.nativeElement?.appendChild(this.tab);
  }

  ngOnChanges(): void {
    this.table?.replaceData(this.tableData);
    this.syncTableData();
    this.FilterDropdownArr = [];
    this.columnHeaders.forEach((element: any) => {
      this.FilterDropdownArr.push(element.field);
    });
  }

  selectAllRows(): void {
    this.table.selectRow();
  }
  deselectAllRows(): void {
    this.table.deselectRow();
  }

  rowValChange(row: any): void {
    this.selectedRow = row._row.data;
    this.onRowValueChanged.emit(this.selectedRow);
  }

  syncTableData(): void {
    this.newItemEvent.emit(this.tableData);
    if (this.selectedRow) {
      this.onRowValueChanged.emit(this.selectedRow);
    }
  }

  fixMovableRows(): void {
    if (this.table) {
      this.table.getRows().forEach((row: any) => {
        if (
          (row._row.type === 'row' || row._row.type === 'group') &&
          !row._row.modules.moveRow
        ) {
          row._row.modules.moveRow = {};
        }
      });
    }
  }

  clickedRow(row: any): void {
    this.selectedRow = row._row.data;
    this.onRowSelected.emit(this.selectedRow);
  }

  // Custom filter example
  customFilter(data: any): boolean {
    return data.name === 'Oli Bob';
  }

  // Trigger setFilter function with correct parameters
  updateFilter(): void {
    const filterVal = this.fieldEl.nativeElement?.options[
      this.fieldEl.nativeElement?.selectedIndex
    ].value;
    const typeVal = this.typeEl.nativeElement?.options[
      this.typeEl.nativeElement?.selectedIndex
    ].value;

    const filter = filterVal;

    if (filterVal === 'function') {
      this.typeEl.nativeElement.disabled = true;
      this.valueEl.nativeElement.disabled = true;
    } else {
      this.typeEl.nativeElement.disabled = false;
      this.valueEl.nativeElement.disabled = false;
    }

    if (filterVal) {
      const temp = this.flat(this.tableData);
      this.table.replaceData(temp);
      this.table.setFilter(filter, typeVal, this.valueEl.nativeElement?.value);
    } else {
      this.ClearFilter();
    }
  }

  ClearFilter(): void {
    this.fieldEl.nativeElement.value = '';
    this.typeEl.nativeElement.value = '=';
    this.valueEl.nativeElement.value = '';
    this.table.replaceData(this.tableData);
    this.table.clearFilter();
  }

  flat(array: any): any {
    let result: any = [];
    array.forEach((a: any) => {
      result.push(a);
      if (Array.isArray(a.children)) {
        result = result.concat(this.flat(a.children));
      }
    });

    return result;
  }

  syncTableDataParent(data: any): void {
    this.tableData = data;
    this.table.replaceData(data);
  }

  setSearchFilter(colName: string, filter: string, value: string): void {
    this.table.setFilter(colName, filter, value);
  }
  customFilters(data: any, value: any): boolean {
    let flag = 0;
    Object.keys(value).forEach((i: any) => {
      if (data[i]?.toString().toLowerCase().includes(value[i])) {
        flag = 1;
      }
    });
    return flag > 0;
  }
  setCustomSearchFilter(value: object): void {
    this.table.setFilter(this.customFilters, value);
  }
  downloadCSVData(fileName: string, delimiterVal: string = ','): void {
    this.table.download('csv', fileName, { delimiter: delimiterVal });
  }
  downloadXlsxData(fileName: string, sheetName: string = 'Sheet1'): void {
    this.table.download('xlsx', fileName, { sheetName });
  }
  getSelectedRows(): any {
    const selectedData = this.table.getSelectedData();
    return selectedData;
  }
  deselectRow(): void {
    this.table.deselectRow();
  }

  // Validate table using validators
  validateTable(): any {
    const valid = this.table.validate();
    return valid;
  }

  // Sync Column header
  syncTableHeaderParent(data: any): void {
    this.table.setColumns(data);
  }
  hideColumn(col: string): void {
    this.table.hideColumn(col);
  }
  showColumn(col: string): void {
    this.table.showColumn(col);
  }
  addColumn(col: Tabulator.ColumnDefinition, before: boolean): void {
    this.table.addColumn(col, before);
  }
  deleteColumn(col: string): void {
    this.table.deleteColumn(col);
  }
  getRows(): any {
    return this.table.getRows();
  }
  getColumns(): any {
    return this.table.getColumns();
  }

  redrawTable(): void {
    this.table.redraw(true);
  }

  changePlaceholder(plc: string): void {
    this.table.options.placeholder.firstElementChild.textContent = plc;
  }

  updateColumnDefinition(colName: string, colProperty: object): void {
    this.table.updateColumnDefinition(colName, colProperty);
  }
  setHeaderFilterValue(colName: string, colProperty: any): void {
    this.table.setHeaderFilterValue(colName, colProperty);
  }
}
