const pagination_config = {};
const url = {
  update_meta_data_url: 'dashboard/images/',
  dashboard_image_listing_turl: 'dashboard/images/',
  image_metadata_url: 'dashboard/images/',
  send_for_inspection_VI_url: 'email?action=sendmail',
  saveFiler: 'dashboard/saveFilters?appId=',
  deleteFilter: 'dashboard/deleteFilters/',
  uploadImage: 'dashboard/images/',
  allSource_api: 'dashboard/sources/',
  get_saved_filters_url: 'dashboard/getFilters/',
  saveSource: 'sources',
  modelImageUrl: 'model/uploadModel',
  modelUplod: 'model/deployModel',
  allApps: 'apps',
  apps: 'apps',
  appDetails: 'apps?name=',
  storages: 'storages',
  appDetailsWithId: 'apps?id=',
  get_user_pref: 'users/',
  save_user_pref: 'users/',
  getAllModel: 'model/getModel/',
  modelDetails: 'model/deployDetails/',
  deleteSource: 'sources',
  addProperty: 'settings/sourceConfigs',
  deleteProperty: 'settings/sourceConfigs',
  getAllUserList: 'apps/',
  allConnections: 'storages',
  connectionDetailsWithId: 'storages?id=',
  connectionDetailsWithName: 'metadata/connections?name=',
  hierarchyDetailsWithId: 'sourceTypes?id=',
  hierarchyDetailsWithName: 'metadata/sourceTypes?name=',
  deleteConnection: 'storages',
  addUpdateConnection: 'storages',
  deleteApplication: 'metadata/deleteApp',
  addUpdateApplication: 'apps',
  autoSuggestionOwner: 'user/getUserAccount',
  testConnection: 'storages?action=testStorageConnection',
  getHierarchyTypes: 'sourceTypes',
  createHierarchyTypes: 'sourcesTypes',
  deleteHierarchyType: 'sourcesTypes',
  deleteImages: 'dashboard/images/',
  getDomains: 'metadata/domains',
  domainDetailsWithId: 'metadata/domains?id=',
  domainDetailsWithName: 'metadata/domains?name=',
  getDataset: 'getDatasets',
  datasets: 'datasets',
  deleteDomain: 'metadata/deleteDomain',
  getExperiments: 'mflow/experiments',
  getImageCount: 'images/deviceImages',
  createTotalDS: 'datasets',
  updateDataset: 'datasets',
  trainResult: 'mflow/modelTrainResult/',
  addModel: 'mflow/trainModel',
  rolesPermission: 'rolesPermissions',
  permissions: 'permissions',
  allRoles: 'roles',
  addRoles: 'addRoles',
  deleteRole: 'deleteRoles',
  transferImages: 'addImageStatus/',
  getBlobFiles: 'blobs/advanceUploadConfig/',
  getBlobFolders: 'blobs/lists/',
  createNewFolder: 'blobs/create',
  copyToFileFolder: 'images/copy',
  moveToFileFolder: 'images/move',
  getDatasetFolders: 'blobs/lists/',
  getDatasetFiles: 'blobs/files/',
  deleteFileFolder: 'advanceUpload/images',
  uploadFiles: 'fileTransfer/uploadFiles/',
  addImageMetadata: 'advanceUpload/images/metadata',
  settingsConfig: 'settings/config',
  models: 'models',
  algos: 'algos',
  trays: 'trays',
  workflows: 'workflows',
  getSearchData: 'searches',
  getType: 'storages?action=getType',
  scopeType: 'storages?action=getScope',
  externalUsers: 'auth/users',
  catalogs: 'catalogs',
  getUserAssignments: 'users?action=getUserAssignedLocation',
  getFilRecords: 'localFilNationalLoaner?action=getData',
  addFilRecord: 'localFilNationalLoaner?action=setData',
  addAssignment: 'users?action=assignLocation',
  searchUser: 'users?action=searchUser',
  getUsersByType: 'users?external',
  downloadUserAssignments: 'users?action=downloadUserAssignment',
  downloadFilRecords: 'localFilNationalLoaner?action=download',
  uploadUserAssignments: 'users/?action=uploadUserAssignLocation',
};
const labels = {
  TRACK_AND_TRACE: 'TRACK_AND_TRACE',
  JJV_MACHINE_ARCHIVE: 'JJV Machine Vision Archive',
};
const STATUS_CODE_OK = 200;
const sort_order_asc = 'asc';
const sort_order_desc = 'desc';
const getRoleApi = 'metadata/roles';
const default_shortcuts = {
  actions: {
    Next: 'S',
    Previous: 'A',
    Pass: 'G',
    Fail: 'J',
    Borderline: 'H',
    Exclude: 'E',
    Measure: 'C',
  },
};

const softDelete = 'soft';
const grading = 'grading';
const fail_reason = 'failReason';
const sourceTypeLine = 'Line';
const no_line = 'No Line';
const SAS_SCOPES = {
  readOnly: 'readOnly',
  editOnly: 'editOnly',
};
const status = {
  LABELLED: 'labelled',
  GRADED: 'graded',
  CLASSIFIED: 'Classified',
  SENT_INSPECT: 'SentForInspection',
  VERIFIED: 'Verified by Visual Inspector',
};
export {
  pagination_config,
  STATUS_CODE_OK,
  status,
  url,
  labels,
  getRoleApi,
  sort_order_asc,
  sort_order_desc,
  default_shortcuts,
  softDelete,
  grading,
  fail_reason,
  sourceTypeLine,
  no_line,
  SAS_SCOPES,
};
