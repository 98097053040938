import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IAppInformation } from './app.service.types';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private app: any;
  appSelected = new BehaviorSubject<IAppInformation>(this.initAppBehavior());
  constructor(private http: HttpClient) {}
  appId = localStorage.getItem('selectedApp');
  baseURL: string = environment.entryAPI;

  setApp(data: any): void {
    this.app = { ...data };
  }

  getApp(): any {
    return this.app;
  }
  initAppBehavior(): any {
    return {
      appCode: '',
      appIcon: '',
      appId: '',
      appName: '',
      siteUrl: '',
    };
  }
  removeAppHandler(): void {
    this.appSelected = new BehaviorSubject<IAppInformation>(
      this.initAppBehavior()
    );
  }
  selectApp(app: IAppInformation): void {
    this.appSelected.next(app);
  }

  getAppConfig(): any {
    this.appId = localStorage.getItem('selectedApp');
    return this.http.get(this.baseURL + `apps?id=` + this.appId);
  }
}
