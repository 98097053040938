import { Component, Inject, OnInit } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

export class SnackData {
  public message: string;
  public snackType: string;
}

@Component({
  selector: 'app-common-snackbar-ui',
  templateUrl: './common-snackbar-ui.component.html',
  styleUrls: ['./common-snackbar-ui.component.css'],
})
export class CommonSnackbarUiComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<CommonSnackbarUiComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackData
  ) {}

  // Fixed: TS7030: Not all code paths return a value.
  get getIcon(): string {
    switch (this.data.snackType) {
      case 'Success':
        return 'done';
      case 'Error':
        return 'error';
      case 'Warning':
        return 'warning';
      case 'Information':
      default:
        return 'info';
    }
  }
}
