import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { url } from '@app/app.config';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  baseURL: string = environment.entryAPI;
  appid = localStorage.getItem('selectedApp');

  constructor(private http: HttpClient) {}

  getDatasetsData(type, offset, limit): any {
    return this.http.post(
      this.baseURL +
        url.datasets +
        '?type=' +
        type +
        '&offset=' +
        offset +
        '&limit=' +
        limit +
        '&appId=' +
        this.appid,
      ''
    );
  }
  getMasterDataSetsWithId(id): any {
    return this.http.post(this.baseURL + url.datasets + '?id=' + id, '');
  }
  getDataSetsWithFilter(filter): any {
    return this.http.get(this.baseURL + url.datasets + filter);
  }
  getExperiments(id): any {
    return this.http.post(
      this.baseURL + url.getExperiments + '?modelId=' + id,
      { authToken: 'Bearer ' + localStorage.getItem('mltoken') }
    );
  }
  getExperimentsByName(name): any {
    return this.http.post(
      this.baseURL + url.getExperiments + '?name=' + name,
      ''
    );
  }
  getExperimentsResult(id): any {
    return this.http.get(this.baseURL + url.getExperiments + '?id=' + id);
  }
  trainModel(data): any {
    return this.http.post(this.baseURL + url.addModel, data, {
      observe: 'response',
    });
  }
  updateDataset(data): any {
    return this.http.post(this.baseURL + url.updateDataset, data, {
      observe: 'response',
    });
  }
  transferImages(datasetId): any {
    return this.http.put(this.baseURL + url.transferImages, datasetId);
  }
}
