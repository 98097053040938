import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { AppStorageService } from '@core/services/app-storage.service';
import * as moment from 'moment';
import { UtilService } from '@core/services/util.service';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  baseURL: string = environment.entryAPI;
  constructor(
    private http: HttpClient,
    private storageService: AppStorageService,
    private utilService: UtilService
  ) {}
  appId: any = this.storageService.getApp();
  getAuditData(reqObj: any): any {
    const cloneReqObj = Object.assign({}, reqObj);
    if (cloneReqObj.endDate && cloneReqObj.startDate) {
      cloneReqObj.endDate = this.transfromDate(cloneReqObj.endDate);
      cloneReqObj.startDate = this.transfromDate(cloneReqObj.startDate);
    }
    return this.http.get(
      this.baseURL +
        `audits?data=${encodeURIComponent(JSON.stringify(cloneReqObj))}`
    );
  }
  getUserList(): any {
    const reqObj = {
      appId: this.appId,
    };
    return this.http.get(
      this.baseURL +
        `reports?action=user&data=${encodeURIComponent(JSON.stringify(reqObj))}`
    );
  }
  getKitsData(): any {
    const queryParam = {
      appId: this.appId,
      action: 'meta',
    };
    const encodedQuery = encodeURIComponent(JSON.stringify(queryParam));
    return this.http.get(this.baseURL + 'kits?data=' + encodedQuery);
  }
  getAllTrays(): any {
    const queryParam = {
      appId: this.appId,
      action: 'meta',
    };
    const encodedQuery = encodeURIComponent(JSON.stringify(queryParam));
    return this.http.get(this.baseURL + 'trays?data=' + encodedQuery);
  }
  getTraysByKit(kitData: any): any {
    const queryParam = {
      kitId: kitData.id,
    };
    const encodedQuery = encodeURIComponent(JSON.stringify(queryParam));
    return this.http.get(this.baseURL + 'trays?data=' + encodedQuery);
  }
  getAuditConfigData(): any {
    const reqObj = {
      appId: this.appId,
    };
    return this.http.get(
      this.baseURL +
        `audits?action=getCriteriasList&data=${encodeURIComponent(
          JSON.stringify(reqObj)
        )}`
    );
  }
  getAuditfile(reqObj: any): any {
    const timeZoneOffset = this.utilService.getTimeZoneOffset();
    const cloneReqObj = Object.assign({}, reqObj);
    if (cloneReqObj.endDate && cloneReqObj.startDate) {
      cloneReqObj.endDate = this.transfromDate(cloneReqObj.endDate);
      cloneReqObj.startDate = this.transfromDate(cloneReqObj.startDate);
    }
    return this.http.get(
      this.baseURL +
        `audits?action=export&timeZoneOffset=` +
        timeZoneOffset +
        `&data=${encodeURIComponent(JSON.stringify(cloneReqObj))}`,
      {
        responseType: 'blob',
        observe: 'response',
      }
    );
  }
  exportReport(reqObj: any, type: string, format: string): any {
    const timeZoneOffset = this.utilService.getTimeZoneOffset();
    return this.http.post(
      this.baseURL +
        `reports/?action=export&type=` +
        type +
        `&timeZoneOffset=` +
        timeZoneOffset +
        `&format=` +
        format,
      reqObj,
      {
        responseType: 'blob',
        observe: 'response',
      }
    );
  }
  getRolesData(queryParam: string): any {
    return this.http.get(this.baseURL + 'audits?action=' + queryParam);
  }
  getAppList(queryParam: string): any {
    return this.http.get(this.baseURL + 'audits?action=' + queryParam);
  }
  getCategoriesData(queryParam: string): any {
    return this.http.get(this.baseURL + 'audits?action=' + queryParam);
  }
  getEventsData(queryParam: string): any {
    return this.http.get(this.baseURL + 'audits?action=' + queryParam);
  }
  getEsignReasonsData(queryParam: string): any {
    return this.http.get(this.baseURL + 'audits?action=' + queryParam);
  }
  getActionsData(queryParam: string): any {
    return this.http.get(this.baseURL + 'audits/?action==' + queryParam);
  }
  getTablesData(queryParam: string): any {
    return this.http.get(this.baseURL + 'audits/?action==' + queryParam);
  }
  getAuditRowChildData(id: string): any {
    return this.http.get(this.baseURL + `audits/${id}/childTransactions`);
  }

  // Receiving all the inspection reports
  // Need to modify it based on filters(Tray & Kit)
  getInspectionReport(reqObj: any, page: string, limit: string): any {
    const cloneReqObj = Object.assign({}, reqObj);
    cloneReqObj.endDate = this.transfromDate(cloneReqObj.endDate);
    cloneReqObj.startDate = this.transfromDate(cloneReqObj.startDate);
    return this.http.get(
      this.baseURL +
        `reports?action=inspection&offset=` +
        page +
        `&limit=` +
        limit +
        `&data=${encodeURIComponent(JSON.stringify(cloneReqObj))}`
    );
  }
  getPerformanceData(reqObj: any, page: string, limit: string): any {
    reqObj.appId = this.appId;
    const cloneReqObj = Object.assign({}, reqObj);
    cloneReqObj.endDate = this.transfromDate(cloneReqObj.endDate);
    cloneReqObj.startDate = this.transfromDate(cloneReqObj.startDate);
    return this.http.get(
      this.baseURL +
        `reports?action=performance&offset=` +
        page +
        `&limit=` +
        limit +
        `&data=${encodeURIComponent(JSON.stringify(cloneReqObj))}`
    );
  }
  getAppConfig(): any {
    return this.http.get(this.baseURL + `apps?id=` + this.appId);
  }

  transfromDate(dateTime: Date): string {
    return moment(dateTime).format('YYYY-MM-DD');
  }
}
