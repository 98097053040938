import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { url } from '@app/app.config';
import { UtilService } from '@core-services/util.service';

@Injectable({
  providedIn: 'any',
})
export class AppAdminService {
  baseURL: string = environment.entryAPI;
  private appName = '';

  constructor(private http: HttpClient, private util: UtilService) {}

  getAllApps(role = ''): any {
    let query = '?status=' + true;
    if (role) {
      query = query + '&adminGroup=' + role;
    }
    return this.http.get(this.baseURL + url.apps + query);
  }
  setAppName(name): void {
    this.appName = name;
  }
  getAppName(): any {
    return this.appName;
  }
  getAppDetails(appName, status = ''): any {
    const params: any = {};
    if (status) {
      params.status = status;
    }
    return this.http.get(this.baseURL + url.appDetails + appName, {
      params,
    });
  }
  getAppDetailsWithId(id): any {
    return this.http.get(this.baseURL + url.appDetailsWithId + id);
  }
  getAllProperties(appId): any {
    return this.http.get(this.baseURL + url.addProperty, {
      params: {
        appId,
      },
    });
  }
  addProperty(data): any {
    if (!data.id) {
      delete data.id;
    }
    return this.http.post(this.baseURL + url.addProperty, data, {
      observe: 'response',
    });
  }
  deleteProperty(id, appId, reqObj): any {
    const payload = this.util.addReqHeaders(reqObj);
    return this.http.delete(
      this.baseURL + url.deleteProperty + '/' + appId + '?id=' + id,
      payload
    );
  }
  getRetentionSettings(name, appId): any {
    return this.http.get(
      this.baseURL + url.settingsConfig + '?appId=' + appId + '&name=' + name
    );
  }
  setRetentionSettings(data): any {
    return this.http.post(this.baseURL + url.settingsConfig, data, {
      observe: 'response',
    });
  }
  getConnections(): any {
    return this.http.get(this.baseURL + url.storages);
  }
}
