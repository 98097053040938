import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-template',
  templateUrl: './dialog-template.component.html',
  styleUrls: ['./dialog-template.component.css'],
})
export class DialogTemplateComponent {
  modalTitle: string;
  modalMessage: string;
  modalType: string;
  result: any;
  showContinue: boolean;
  showDeleteWarning: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogTemplateComponent>
  ) {
    this.modalTitle = data.title;
    this.modalMessage = data.message;
    this.modalType = data.type;
    this.result = data.yes;
    this.showContinue = data.showContinue;
    this.showDeleteWarning = data.showDeleteWarning;
  }

  oncancel(): void {
    this.result(false);
    this.dialogRef.close();
  }
  onDelete(): void {
    this.result(true);
    this.dialogRef.close();
  }
}
export enum ModalType {
  INFO = 'info',
  WARN = 'warn',
}
