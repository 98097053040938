import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gdx-select-button',
  templateUrl: './select-button.component.html',
  styles: [],
})
export class SelectButtonComponent implements OnInit {
  @Input('options') options: any[] = [];
  @Input('class') class = '';
  @Input('optionLabel') optionLabel = '';
  @Input('optionValue') optionValue = '';
  @Input('style') style = '';
  @Input('selectedbtn') selectedbtn: any;
  @Output('onChange') onChange = new EventEmitter();

  ngOnInit(): void {
    this.class = 'h-full text-sm focus:outline-none focus:border-none';
  }
  selectButtonChange(event: any): void {
    this.onChange.emit(event);
  }
}
