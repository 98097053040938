import { Component, Input, OnInit } from '@angular/core';
import { ILabel } from './label-badge.types';

@Component({
  selector: 'gdx-label-badge',
  templateUrl: './label-badge.component.html',
  styles: [],
})
export class LabelBadgeComponent implements OnInit {
  @Input('labelValue') labelValue: ILabel[] | undefined;
  style = '';
  text = '';
  value = '';
  barShow = false;

  ngOnInit(): void {
    if (this.labelValue) {
      this.text = this.labelValue[0].text;
      this.value = this.labelValue[0].value;
      this.barShow = this.labelValue[0].barVisible;
      if (this.barShow) {
        this.style = 'width:' + this.value;
      }
    }
  }
}
