import { Component, Input } from '@angular/core';

export class WorkflowNode {
  id?: number | string;
  title: string;
  type?: string;
  target?: string;
  nodeId?: string;
  x?: number;
  y?: number;
  svg: string;
  click?: (WorkflowNode: any) => void;

  constructor(title: any, id: any = null, x?: number, y?: number, svg?: any) {
    this.title = title;
    this.svg = svg;
    this.id = id;
    this.x = x;
    this.y = y;
  }
}

export class WorkflowConnection {
  from: any;
  to: any;
  fromSide?: 'top' | 'left' | 'bottom' | 'right' = 'right';
  toSide?: 'top' | 'left' | 'bottom' | 'right' = 'left';
  color?: string;
  click?: (Connection: any) => void;
}

export class XYCoordinates {
  x: number;
  y: number;
  width?: number;
  angle?: number;
  constructor(x?: number, y?: number) {
    this.x = x || 0;
    this.y = y || 0;
  }
}

@Component({
  template: '',
})
export class WorkflowModelComponent {
  nodes: any;
  connections: any;

  @Input() allowCircular = false;
  draggable = true;

  creatingConnection: any;

  startCreatingConnection(
    fromSide: 'top' | 'left' | 'bottom' | 'right' = 'right',
    fromNode: WorkflowNode
  ): void {
    this.creatingConnection = new WorkflowConnection();
    this.creatingConnection.fromSide = fromSide;
    this.creatingConnection.from = fromNode;
    this.draggable = false;
  }

  finishCreatingConnection(
    toSide: 'top' | 'left' | 'bottom' | 'right' = 'right',
    toNode: WorkflowNode
  ): any {
    const newConnection = Object.assign(
      new WorkflowConnection(),
      this.creatingConnection
    );

    newConnection.toSide = toSide;
    newConnection.to = toNode;

    if (newConnection.from.title || newConnection.to.title) {
    }

    if (newConnection.from === newConnection.to) {
      console.warn('Cannot move from same node to same node.');
      return;
    }

    if (!this.allowCircular) {
      // See if a connection exists from newConnection.to & newConnection.from
      const circular = this.connections.find(
        (c: any) => c.from === newConnection.to && c.to === newConnection.from
      );
      if (circular) {
        console.warn(
          'Circular dependency detected. If this was intended, set [allowCircular]="true".'
        );
        this.discardCurrentConnection();
        return;
      }
    }

    this.connections.push(newConnection);
    this.discardCurrentConnection();
  }

  discardCurrentConnection(): void {
    this.creatingConnection = null;
    this.draggable = true;
  }

  deleteNode(node: WorkflowNode): void {
    const index = this.nodes.indexOf(node);
    if (index >= 0) {
      // First delete connections to and from node:
      this.connections
        .filter((c: any) => c.from === node || c.to === node)
        .forEach((c: any) => this.deleteConnection(c));
      this.nodes.splice(index, 1);
    }
  }

  deleteConnection(connection: WorkflowConnection): void {
    const index = this.connections.indexOf(connection);
    if (index >= 0) {
      this.connections.splice(index, 1);
    }
  }
}
