import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { btnTypes } from '@shared/enum/button';
import { Calendar } from 'primeng/calendar';

@Component({
  selector: 'gdx-date-picker',
  templateUrl: './date-picker.component.html',
  styles: [],
})
export class DatePickerComponent {
  @ViewChild('myStartCalendar') startCalendar: Calendar;
  @Input('id') id: any;
  @Input('name') name = '';
  @Input('placeholder') placeholder = 'Select';
  @Input('control') control: any;
  @Input('class') class = '';
  @Input('inputStyleClass') inputStyleClass = '';
  @Input('disabled') disabled = false;
  @Input('required') required = false;
  @Input('minDate') minDate!: Date;
  @Input('maxDate') maxDate!: Date;
  @Input('showIcon') showIcon = false;
  @Input('yearNavigator') yearNavigator = false;
  @Input('showTime') showTime = false;
  @Input('timeOnly') timeOnly = false;
  @Input('monthNavigator') monthNavigator = false;
  @Input('yearRange') yearRange: any;
  @Input('readonlyInput') readonlyInput = false;
  @Input('dateFormat') dateFormat = 'mm/dd/yy';
  @Input('selectionMode') selectionMode = 'single';
  @Input('view') view = 'date';
  @Input('baseZIndex') baseZIndex = 0;
  @Input('isFooter') isFooter = false;
  @Input('hideOnDateTimeSelect') hideOnDateTimeSelect = false;
  @Input('panelStyleClass') panelStyleClass = '';
  @Input('parentForm') parentForm: any;
  @Input('clear') clear = 'Clear';
  @Input('apply') apply = 'Apply';
  @Output('onChange') onChange = new EventEmitter();
  @Output('onApply') onApply = new EventEmitter();
  @Output('onClear') onClear = new EventEmitter();

  btnType = btnTypes;

  selectedDate(event: any): any {
    this.onChange.emit(event);
  }

  applyDateRange(event: any): any {
    this.startCalendar.toggle();
    this.onApply.emit(event);
  }

  clearDateRange(event: any): any {
    this.startCalendar.toggle();
    this.onClear.emit(event);
  }
}
