import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { url } from '@app/app.config';
import { AppStorageService } from '@core/services/app-storage.service';
import { UtilService } from '@core/services/util.service';

@Injectable({
  providedIn: 'root',
})
export class ManageDatasetService {
  baseURL: string = environment.entryAPI;
  selectedExperiment!: BehaviorSubject<any>;
  constructor(
    private http: HttpClient,
    private storageService: AppStorageService,
    private utilService: UtilService
  ) {
    // Used to move the selected experiment data from experiment component to mflow component
    this.selectedExperiment = new BehaviorSubject({});
  }
  getHeaders(): any {
    return new HttpHeaders().set('mltoken', this.storageService.getMlToken());
  }
  getAllModels(appId): any {
    return this.http.get(this.baseURL + url.algos + '?appId=' + appId, {
      headers: this.getHeaders(),
    });
  }
  trainModel(data): any {
    return this.http.post(this.baseURL + url.models + '?action=train', data, {
      observe: 'response',
      headers: this.getHeaders(),
    });
  }
  deployModel(data): any {
    return this.http.post(this.baseURL + url.models + '?action=deploy', data, {
      observe: 'response',
      headers: this.getHeaders(),
    });
  }
  getDeploymentHistory(appId, pageNo, limit): any {
    const reqObj = {
      appId,
    };
    return this.http.get(
      this.baseURL +
        url.models +
        '?action=deploymentHistory' +
        `&data=${encodeURIComponent(JSON.stringify(reqObj))}` +
        '&offset=' +
        pageNo +
        '&limit=' +
        limit
    );
  }
  getDeploymentFile(appId, format): any {
    const reqObj = {
      appId,
    };
    const timeZoneOffset = this.utilService.getTimeZoneOffset();
    return this.http.get(
      this.baseURL +
        url.models +
        '?action=deploymentHistory' +
        '&timeZoneOffset=' +
        timeZoneOffset +
        `&data=${encodeURIComponent(JSON.stringify(reqObj))}` +
        '&format=' +
        format,
      {
        responseType: 'blob',
        observe: 'response',
      }
    );
  }
  getTrainResult(data): any {
    return this.http.post(
      this.baseURL + url.models + '?action=trainResult',
      data,
      {
        observe: 'body',
        headers: this.getHeaders(),
      }
    );
  }
  getDatasetTrays(appId): any {
    const queryParams = {
      appId,
      action: 'getDatasetTrays',
    };
    const encodedParams = encodeURIComponent(JSON.stringify(queryParams));
    return this.http.get(this.baseURL + url.trays + '?data=' + encodedParams);
  }
  getAllTrainingDatasets(appId): any {
    return this.http.get(
      this.baseURL + url.datasets + '?type=train' + '&appId=' + appId
    );
  }
  getTrayDatasets(appId, trayId): any {
    return this.http.get(
      this.baseURL +
        url.datasets +
        '?type=train' +
        '&appId=' +
        appId +
        '&experiments=false' +
        '&trayId=' +
        trayId
    );
  }
  getDatasetsData(type, offset, limit, appId): any {
    return this.http.get(
      this.baseURL +
        url.datasets +
        '?type=' +
        type +
        '&offset=' +
        offset +
        '&limit=' +
        limit +
        '&appId=' +
        appId +
        '&status=active'
    );
  }
  getExperiments(id, appId, limit, offset): any {
    const reqObj = {
      modelId: id,
      appId,
    };
    return this.http.get(
      this.baseURL +
        url.models +
        '?action=getExperiment' +
        `&data=${encodeURIComponent(JSON.stringify(reqObj))}` +
        '&limit=' +
        limit +
        '&offset=' +
        offset,
      {
        headers: this.getHeaders(),
      }
    );
  }
  getExperimentsByName(name): any {
    return this.http.post(
      this.baseURL + url.getExperiments + '?name=' + name,
      ''
    );
  }
  getSearchResults(trm, typ, offst, lmt, appid, modelid = ''): any {
    const queryParams = {
      term: trm,
      type: typ,
      offset: offst,
      limit: lmt,
      appId: appid,
      modelId: '',
    };
    if (modelid) {
      queryParams.modelId = modelid;
    } else {
      delete queryParams.modelId;
    }
    const encodedParams = encodeURIComponent(JSON.stringify(queryParams));
    return this.http.get(
      this.baseURL + url.getSearchData + '?data=' + encodedParams
    );
  }
  exportSearchResults(trm, typ, appid, frmt): any {
    const queryParams = {
      type: typ,
      appId: appid,
      term: trm,
      format: frmt,
    };
    const encodedParams = encodeURIComponent(JSON.stringify(queryParams));
    return this.http.get(
      this.baseURL + url.getSearchData + '?data=' + encodedParams,
      {
        responseType: 'blob',
        observe: 'response',
      }
    );
  }
}
