<div class="relative flex w-full h-full shadow-sm bg-white flex-wrap p-1 px-4">
  <div class="flex w-full flex-col">
    <div class="head pt-0 text-sm h-4">
      <span class="float-right inline-block w-max bg-gray-200 pl-1 pr-1">{{
        head
      }}</span>
    </div>
    <div class="text-gray-500 text-sm h-6 uppercase">{{ title }}</div>
    <div class="text-2xl text-black h-8 font-bold text-right" [id]="id">
      {{ value }}
    </div>
    <div class="text-black text-xs pb-1 h-4">
      <span>{{ percentage }}</span>
      <span class="float-right text-gray-400">{{ footer }}</span>
    </div>
    <div
      class="overflow-hidden h-2 mb-4 text-xs flex rounded w-full"
      [ngClass]="percentage ? 'bg-gray-100' : ''"
      *ngIf="barShow"
    >
      <div
        *ngFor="let item of multiProgress"
        [style]="{
          'background-color': item.progressClass,
          width: item.progressStyle
        }"
        title="{{ item.progressTool }}  {{ item.progressStyle }} {{
          item.progressCount
        }}"
        ngClass="bg-{{ item.progressClass }}"
        class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"
      ></div>
    </div>
  </div>
</div>
