import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IRadioArray } from './radio-button.component.types';

@Component({
  selector: 'gdx-radio-button',
  templateUrl: './radio-button.component.html',
  styles: [],
})
export class RadioButtonComponent implements OnInit {
  @Input('parentForm') parentForm: FormGroup = new FormGroup({});
  @Input('radioGroup') radioGroup: any;
  @Output('onChange') onChange = new EventEmitter();
  radioDirection = '';
  radioAlign = '';
  radioList: IRadioArray[] = [];

  ngOnInit(): void {
    this.radioAlign = this.radioGroup.align;
    this.radioList = this.radioGroup.radioList;
  }

  valueChange(event: any): void {
    this.onChange.emit(event);
  }
}
