import {
  Component,
  OnInit,
  DoCheck,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataService } from 'src/app/core/services/data.service';
import { ReportsService } from '@pages/reports/services/reports-service.service';
import { btnTypes } from '../../enum/button';
import * as moment from 'moment';
import { AppService } from '../../../core/services/app.service';
import { InspectionConfigInfo } from '@shared/components/inspection-filter/inspection-filter.component.types';
import { labels } from '@shared/components/inspection-filter/inspection-filter.message';
import { REPORTS_CONSTANTS } from '@pages/reports/reports-constant';

@Component({
  selector: 'gdx-inspection-filter',
  templateUrl: './inspection-filter.component.html',
})
export class InspectionFilterComponent implements OnInit, DoCheck {
  @Output() expanded = new EventEmitter<boolean>();
  @Output() onApplyFilter = new EventEmitter<object>();
  @Input() inspectionClass = '';
  userList: Array<any> = [];
  categories = [];
  events = [];
  actions = [];
  table = [];
  apps = [];
  esignReasons = [];
  status = [{ name: 'success' }, { name: 'failed' }];
  isAdvanced = false;
  filterOpen = false;
  isShow = true;
  expand = 'assets/images/filter.svg';
  collapse = 'assets/images/cross.svg';
  advanced = '(+)';
  notAdvanced = '(-)';
  public btnType = btnTypes;
  public inspectionFilter: FormGroup = new FormGroup({});
  allSelectPlaceHolder = 'Select';
  maxDate: any = new Date();
  errorMessage = '';
  isAppDisabled = false;
  label = labels;
  constructor(
    public formBuilder: FormBuilder,
    private dataService: DataService,
    private reportsService: ReportsService,
    private appService: AppService
  ) {}

  inspectionForm(): void {
    this.inspectionFilter = this.formBuilder.group({
      selectedPeriod: [
        [
          moment(new Date(new Date().setHours(0, 0, 0)))
            .subtract(1, 'months')
            .toDate(),
          new Date(),
        ],
      ],
      selectedUser: [] as any,
    });
  }

  ngOnInit(): void {
    this.getUserList();
    this.inspectionForm();
  }

  getUserList(): void {
    this.reportsService
      .getUserList()
      .subscribe((result: InspectionConfigInfo) => {
        if (result && result.data) {
          this.userList = result.data;
        }
      });
  }

  getActionData(): void {
    const queryParam = 'getActionList';
  }

  getTableData(): void {
    const queryParam = 'getTableList';
  }

  getEsignReasonsData(): void {
    const queryParam = 'getReasonList';
  }
  getAppList(): void {
    const queryParam = 'getAppList';
  }
  ngDoCheck(): void {
    this.dataService.onHide.subscribe((result) => {
      this.isShow = result;
    });
  }

  expandCollapse = (): void => {
    this.filterOpen = !this.filterOpen;
    this.expanded.emit(this.filterOpen);
  };

  expandCollapseAdvanced = (): void => {
    this.isAdvanced = !this.isAdvanced;
  };

  applyFilter(event: any): void {
    const filterData = this.inspectionFilter.value;
    const filterdValue = {
      startDate: new Date(),
      endDate: new Date(),
      inspectedBy: [] as any,
    };
    this.filterKeys(filterData, filterdValue);
    if (
      this.inspectionFilter.controls.selectedPeriod &&
      this.inspectionFilter.controls.selectedPeriod.value[0] &&
      this.inspectionFilter.controls.selectedPeriod.value[1]
    ) {
      const startDate = moment(
        this.inspectionFilter.controls.selectedPeriod.value[0]
      );
      const endDate = moment(
        this.inspectionFilter.controls.selectedPeriod.value[1]
      );
      const diff = endDate.diff(startDate, 'days');
      if (diff === 0) {
        filterdValue.startDate = moment(
          new Date(filterdValue.startDate).setHours(0, 0, 0)
        ).toDate();
        filterdValue.endDate = moment(
          new Date(filterdValue.startDate).setHours(23, 59, 59)
        ).toDate();
      }
    }
    this.onApplyFilter.emit({ filterdValue, event });
    this.expandCollapse();
  }

  filterKeys(
    filterData: any,
    filterdValue: { startDate: Date; endDate: Date; inspectedBy: any }
  ): void {
    Object.keys(filterData).map((item: string) => {
      const key = item.replace('selected', '');
      if (item.includes(key)) {
        if (key === REPORTS_CONSTANTS.period) {
          filterdValue.startDate = filterData.selectedPeriod[0]
            ? filterData.selectedPeriod[0]
            : '';
          filterdValue.endDate = filterData.selectedPeriod[1]
            ? filterData.selectedPeriod[1]
            : moment(
                new Date(filterdValue.startDate).setHours(11, 59, 59)
              ).toDate();
        } else if (key === REPORTS_CONSTANTS.user) {
          if (filterData.selectedUser && filterData.selectedUser.length > 0) {
            filterdValue.inspectedBy = [];
            filterData.selectedUser.map((obj: any) => {
              filterdValue.inspectedBy.push(obj.inspectedBy);
            });
          }
        } else {
        }
      }
    });
  }

  resetFilter(): void {
    this.inspectionForm();
    const app = this.appService.getApp();
    if (app && app.appId) {
      this.inspectionFilter.patchValue({
        selectedUser: [] as any,
      });
      this.isAppDisabled = true;
    } else {
      this.isAppDisabled = false;
    }
    this.inspectionFilter.patchValue({
      selectedPeriod: [
        moment(new Date(new Date().setHours(0, 0, 0)))
          .subtract(1, 'months')
          .toDate(),
        new Date(),
      ],
    });
  }
}
