import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { AppService } from '../../../core/services/app.service';
import { DataService } from 'src/app/core/services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'gdx-app-launcher',
  templateUrl: './app-launcher.component.html',
})
export class AppLauncherComponent implements OnInit, OnChanges {
  @Input('appList') appList: any = [];
  isShow = false;
  imageUrl: any;
  dataServices: DataService;
  isHide = false;
  isUser = false;
  isHelp = false;
  appCode = '';
  fullAppList: any = [];

  constructor(
    private dataService: DataService,
    private appService: AppService,
    private router: Router
  ) {
    this.dataServices = dataService;
  }

  ngOnInit(): void {
    this.fullAppList = this.appList;
    const getLocalApp: any = localStorage.getItem('selectedApp');
    if (!JSON.parse(getLocalApp)) {
      localStorage.setItem('selectedApp', JSON.stringify(this.appList[0]));
      this.appService.setApp(this.appList[0]);
      this.appCode = this.appList[0].appCode;
      this.appService.selectApp(this.appList[0]);
    } else {
      this.appCode = JSON.parse(getLocalApp).appCode;
      this.appService.setApp(JSON.parse(getLocalApp));
      this.appService.selectApp(JSON.parse(getLocalApp));
    }
    this.dataService.isAppLoaded(true);
    this.imageUrl = './../../../../assets/images/white-app-icon.svg';
    this.dataServices.onToggle.subscribe((result) => {
      this.isHide = result;
      if (this.isHide === true) {
        this.isShow = false;
        this.imageUrl = './../../../../assets/images/white-app-icon.svg';
      }
    });
    this.dataServices.onUserToggle.subscribe((result) => {
      this.isUser = result;
      if (this.isUser === true) {
        this.isShow = false;
        this.imageUrl = './../../../../assets/images/white-app-icon.svg';
      }
    });
    this.dataServices.onHelpToggle.subscribe((result) => {
      this.isHelp = result;
      if (this.isHelp === true) {
        this.isShow = false;
        this.imageUrl = './../../../../assets/images/white-app-icon.svg';
      }
    });
  }

  ngOnChanges(): void {
    const getLocalApp: any = localStorage.getItem('selectedApp');
    if (!JSON.parse(getLocalApp)) {
      localStorage.setItem('selectedApp', JSON.stringify(this.appList[0]));
      this.appService.setApp(this.appList[0]);
      this.appCode = this.appList[0].appCode;
      this.appService.selectApp(this.appList[0]);
      this.fullAppList = [...this.appList];
    } else {
      this.appCode = JSON.parse(getLocalApp).appCode;
      this.appService.setApp(JSON.parse(getLocalApp));
      this.appService.selectApp(JSON.parse(getLocalApp));
    }
    this.dataService.isAppLoaded(true);
  }

  showblock(): void {
    this.isShow = !this.isShow;
    if (this.isShow === true) {
      this.imageUrl = './../../../../assets/images/app-icon.svg';
    } else {
      this.imageUrl = './../../../../assets/images/white-app-icon.svg';
    }
    this.dataServices.IstoggleShow(this.isShow);
  }

  closeblock(): void {
    this.isShow = false;
    this.imageUrl = './../../../../assets/images/white-app-icon.svg';
    this.dataServices.IstoggleShow(this.isShow);
  }
  onAppchange(event: any): void {
    this.appService.setApp(event);
    localStorage.setItem('selectedApp', JSON.stringify(event));
    this.appService.selectApp(event);
    this.appCode = event.appCode;
    this.router.navigate(['/']);
  }

  goToLink(url: string): void {
    window.open(url, '_blank');
  }
}
