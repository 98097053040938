import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'gdx-input-box',
  templateUrl: './input-box.component.html',
})
export class InputBoxComponent implements OnInit, AfterViewInit {
  @ViewChild('inputBox') inputBox: any;
  @Input('type') type = 'text';
  @Input('id') id = '';
  @Input('name') name = '';
  @Input('control') control = '';
  @Input('placeholder') placeholder = '';
  @Input('textClass') textClass = '';
  @Input('icon') icon = '';
  @Input('iconHover') iconHover = '';
  @Input('iconClass') iconClass = '';
  @Input('disabled') disabled = false;
  @Input('readonly') readonly = false;
  @Input('parentForm') parentForm: FormGroup = new FormGroup({});
  @Input('value') value = '';
  @Input('min') min = '';
  @Input('max') max = '';
  @Input('step') step = '';
  @Input('pattern') pattern = '';
  @Input('autoFocus') autoFocus = false;
  @Input('checked') checked = false;
  @Output('onChange') onChange = new EventEmitter();
  @Output('onKeyPress') onKeyPress = new EventEmitter();
  @Output('onIconClick') onIconClick = new EventEmitter();
  @Output('onKeyUp') onKeyUp = new EventEmitter();
  @Output('onKeyDown') onKeyDown = new EventEmitter();
  @Output('onKeyEnter') onKeyEnter = new EventEmitter();
  @Output('onInput') onInput = new EventEmitter();
  @Output('onDoubleClick') onDoubleClick = new EventEmitter();
  @Output('onBlur') onBlur = new EventEmitter();
  @Output('onPaste') onPaste = new EventEmitter();
  public iconSource = '';

  ngOnInit(): void {
    this.iconSource = this.icon;
  }

  ngAfterViewInit(): void {
    if (this.autoFocus) {
      setTimeout(() => {
        this.inputBox.nativeElement.focus();
      }, 100);
    }
  }
  valueChange(event: any): void {
    this.onChange.emit(event);
  }

  keyPress(event: any): void {
    this.onKeyPress.emit(event);
  }

  keyUp(event: any): void {
    this.onKeyUp.emit(event);
  }

  iconClick(event: any): void {
    this.onIconClick.emit(event);
  }

  keyDown(event: any): void {
    this.onKeyDown.emit(event);
  }

  inputChange(event: any): void {
    this.onInput.emit(event);
  }

  iconMouseOver(): void {
    this.iconSource = this.iconHover ? this.iconHover : this.icon;
  }

  iconMouseOut(): void {
    this.iconSource = this.icon;
  }
  keyEnter(event: any): void {
    this.onKeyEnter.emit(event);
  }
  doubleClick(event: any): void {
    this.onDoubleClick.emit(event);
  }

  blur(event: any): void {
    this.onBlur.emit(event);
  }

  paste(event: any): void {
    this.onPaste.emit(event);
  }
}
