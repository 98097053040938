import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { multiSelect } from 'src/app/shared/shared.constant';

@Component({
  selector: 'gdx-multiselect-dropdown',
  templateUrl: './multiselect-dropdown.component.html',
  styles: [],
})
export class MultiselectDropdownComponent {
  @Input('id') id: any;
  @Input('name') name = '';
  @Input('placeholder') placeholder = 'Select';
  @Input('options') options: any[] = [];
  @Input('control') control: any;
  @Input('class') class = '';
  @Input('optionLabel') optionLabel = '';
  @Input('filter') filter = false;
  @Input('showHeader') showHeader = false;
  @Input('virtualScroll') virtualScroll = false;
  @Input('itemSize') itemSize = multiSelect.ITEM_SIZE;
  @Input('displaySelectedLabel') displaySelectedLabel = true;
  @Input('filterBy') filterBy = '';
  @Input('filterValue') filterValue = '';
  @Input('disabled') disabled = false;
  @Input('parentForm') parentForm: FormGroup = new FormGroup({});
  @Input('style') style = '';
  @Input('panelStyle') panelStyle = '';
  @Input('resetFilterOnHide') resetFilterOnHide = false;
  @Output('onChange') onChange = new EventEmitter();

  dropdownChange(event: any): void {
    this.onChange.emit(event);
  }
}
