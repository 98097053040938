import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { fromPromise } from 'rxjs/internal-compatibility';
import { map, concatMap, catchError, finalize } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpResponse,
} from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { LoaderService } from '@core-services/loader.service';
import { BY_PASS_LOADER } from '@core/permission/permissions.type';
@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService {
  config: any;
  constructor(
    private msalService: MsalService,
    private router: Router,
    private loaderService: LoaderService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const config = this.configSet(req);

    return fromPromise(this.msalService.acquireTokenSilent(this.config))
      .pipe(
        map((token) => {
          const selectedRole = localStorage.getItem('selectedRole');
          req = this.setHeader(req, token);
          if (!this.isPassLoader(req.url)) {
            this.loaderService.show();
          }

          return req;
        }),
        catchError((error: HttpErrorResponse) => {
          this.loaderService.hide();
          this.msalService.acquireTokenRedirect(this.config);
          return throwError(error);
        })
      )
      .pipe(
        concatMap((request) => {
          return next.handle(request).pipe(
            finalize(() => {
              if (!this.isPassLoader(request.url)) {
                this.loaderService.hide();
              }
            }),
            map((event: HttpEvent<any>) => {
              if (event instanceof HttpResponse) {
              }
              if (!this.isPassLoader(request.url)) {
                this.loaderService.show();
              }

              return event;
            }),
            catchError((error: HttpErrorResponse) => {
              let data = {};
              this.loaderService.hide();
              data = {
                reason: error && error.message ? error.message : '',
                status: error.status,
              };
              if (error.message === 'Authentication failed') {
                localStorage.clear();
                this.msalService.getAccount();
                this.router.navigate(['/']);
              }
              console.log(error);
              return throwError(error);
            })
          );
        })
      );
  }
  setHeader(req: HttpRequest<any>, token): any {
    if (
      req.url.includes('v1.0/me/photo') ||
      req.url.includes('v1.0/me/memberOf')
    ) {
      req = req.clone({
        setHeaders: {
          Authorization: `${token.accessToken}`,
        },
      });
    } else {
      let appId = localStorage.getItem('selectedApp');
      appId = appId ? appId : '';
      let role = localStorage.getItem('selectedRole');
      role = role ? JSON.parse(role).meta : '';
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token.idToken.rawIdToken}`,
          app: appId,
          role,
        },
      });
    }
    return req;
  }

  configSet(req: HttpRequest<any>): any {
    if (
      req.url.includes('v1.0/me/photo') ||
      req.url.includes('v1.0/me/memberOf')
    ) {
      this.config = {
        scopes: ['User.Read'],
      };
    } else {
      this.config = {
        scopes: [environment.authConfig.config.auth.clientId],
      };
    }
  }

  // This method is used for bypassing loader for configured url.
  // @ param : url is current  URI of current http ruqeust.
  isPassLoader(url: string): boolean {
    let isPass = false;
    BY_PASS_LOADER.forEach((v) => {
      if (url.includes(v)) {
        isPass = true;
        return;
      }
    });
    return isPass;
  }
}
