import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { CommonService } from '@core/services/common.service';
import { NavigationEnd, Router } from '@angular/router';
import { DataService } from './core/services/data.service';
import { UserService } from './core/services/user.service';
import { AuthService } from 'src/app/core/services/adal.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { RELOAD_MESSAGE } from '@shared/shared.constant';
import { SharedService } from '@core-services/shared.service';
@Component({
  selector: 'gdx-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  loggedIn = false;
  roleList: any = [];
  isNavLoad = false;
  isAppLoad = true;
  isNoRoleModal = false;
  isGdxPlatformModal = false;
  timer: any;
  selectedRole = '';
  sasToken = '';
  editSasToken = '';
  footerVisible: boolean;
  sessionExpired = false;
  reload_message = RELOAD_MESSAGE;
  isConfigLoaded = false;
  appConfigSubscriber;
  accessGranted = false;
  accessDenied = false;
  constructor(
    private translate: TranslateService,
    private broadcastService: BroadcastService,
    private authService: MsalService,
    private commonService: CommonService,
    private router: Router,
    private dataService: DataService,
    private userService: UserService,
    private bnIdle: BnNgIdleService,
    public AuthSer: AuthService,
    private sharedService: SharedService
  ) {
    this.startWatching();
    this.updateVisible();
    this.translate.setDefaultLang('en');
    const token: any = this.authService.getAccount();
    if (token) {
      this.loggedIn = true;
    } else {
      this.authService.loginRedirect({ scopes: ['user.read'] });
    }
    if (token?.idToken) {
      this.roleList = token.idToken.roles || [];
      this.roleList = this.roleList.filter(
        (item: any) =>
          !item.toUpperCase().includes('APPACCESS') ||
          !item.toUpperCase().includes('DATAACCESS')
      );
    }
    this.appConfigSubscriber = this.sharedService.appConfigData.subscribe(
      (data) => {
        this.isConfigLoaded = true;
      }
    );
  }

  startWatching(): void {
    const reloadtime = this.AuthSer.calculateExpirySec();
    this.bnIdle.startWatching(reloadtime).subscribe((res) => {
      if (res) {
        this.sessionExpired = true;
        localStorage.clear();
      }
    });
  }

  onActivate(event: any): void {
    window.scroll(0, 0);
  }

  @HostListener('window:mousemove')
  onWindowMousemove(): void {
    this.clearTimer();
  }
  @HostListener('window:mousewheel')
  onWindowMouseWheel(): void {
    this.clearTimer();
  }
  @HostListener('window:keyup')
  onWindowKeyup(): void {
    this.clearTimer();
  }
  @HostListener('window:keydown')
  onWindowKeydown(): void {
    this.clearTimer();
  }
  @HostListener('window:scroll')
  onWindowScroll(): void {
    this.clearTimer();
  }
  @HostListener('window:click')
  onWindowClick(): void {
    this.clearTimer();
  }

  clearTimer(): void {
    clearTimeout(this.timer);
  }

  startTimer(): void {
    this.timer = setTimeout(() => {
      sessionStorage.clear();
      this.authService.logout();
    }, 900000);
  }

  ngOnInit(): void {
    this.broadcastService.subscribe('msal:loginFailure', () => {
      this.loggedIn = false;
    });

    this.broadcastService.subscribe('msal:loginSuccess', () => {
      this.loggedIn = true;
    });

    this.dataService.isNavigationLoad.subscribe((result: boolean) => {
      if (result) {
        this.isNavLoad = true;
      } else {
        this.isNavLoad = false;
      }
      this.accessGranted =
        this.isNavLoad && this.isAppLoad && this.isConfigLoaded ? true : false;
      this.accessDenied = this.accessGranted ? false : true;
    });

    this.dataService.isAppLoad.subscribe((result: boolean) => {
      if (result) {
        this.isAppLoad = true;
      } else {
        this.isAppLoad = false;
      }
      this.selectedRole = this.userService.getCurrentUserRole()?.name;
    });
  }

  ngOnDestroy(): void {
    this.appConfigSubscriber.unsubscribe();
  }

  @HostListener('window:keydown', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    if (event.ctrlKey && event.altKey && event.keyCode === 86) {
      this.isGdxPlatformModal = true;
    }
  }

  close($event: any): void {
    this.isGdxPlatformModal = false;
  }
  updateVisible(): void {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.footerVisible = this.commonService.headerFooterVisibility(
          this.router
        );
      }
    });
  }
}
