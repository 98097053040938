<div [formGroup]="parentForm">
  <div class="flex" [ngClass]="radioAlign">
    <label
      class="inline-flex items-center mt-1 mr-4 cursor-pointer"
      *ngFor="let item of radioList; let i = index"
    >
      <input
        type="radio"
        [id]="item.id"
        [name]="item.name"
        [attr.disabled]="item.disabled ? '' : null"
        (change)="valueChange($event)"
        [class]="item.radioClass"
        [required]="item.required"
        [value]="item.id"
        [checked]="item.checked"
        [formControlName]="item.control"
      />
      <span [class]="item.labelClass">{{ item.label }}</span>
    </label>
  </div>
</div>
