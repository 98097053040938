// This File will be used for checking userid and get authtoken

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Subscription, Observable } from 'rxjs';
import { LoaderService } from '@core-services/loader.service';

import { getRoleApi } from '../../app.config';
import { SharedService } from '@core/services/shared.service';
import { UserAgentApplication } from 'msal';

@Injectable()
export class AuthService {
  loggedIn: boolean;
  graphLoggedIn: boolean;
  graphTokenIdleHandler: any;
  url: string;
  public userInfo: any = null;
  public id: any;
  public isIframe: boolean;
  private GraphAPIBaseUrl = 'https://graph.microsoft.com/v1.0/';
  constructor(
    private broadcastService: BroadcastService,
    private authService: MsalService,
    private http: HttpClient,
    private loaderService: LoaderService,
    private shared: SharedService
  ) {
    this.isIframe = window !== window.parent && !window.opener;
    if (
      this.authService.getAccount() &&
      this.authService.getAccount().userName
    ) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
      this.login();
    }

    this.broadcastService.subscribe('msal:loginFailure', (payload) => {
      this.loggedIn = false;
    });

    this.broadcastService.subscribe('msal:loginSuccess', async (payload) => {
      this.loggedIn = true;
    });
  }

  private checkAndRefreshForNewGraphToken(): void {
    this.checkGraphToken();
    if (!this.graphLoggedIn) {
      this.graphTokenIdleHandler = setInterval(() => {
        if (!this.graphLoggedIn) {
          this.checkGraphToken();
        } else {
          if (
            !localStorage.graphTokenRef &&
            location.href.toLowerCase().indexOf('accessdenied') === -1
          ) {
            localStorage.graphTokenRef = true;
            location.href = location.href;
          } else {
            clearInterval(this.graphTokenIdleHandler);
          }
        }
      }, 500);
    }
  }

  private checkGraphToken(): void {
    const graphToken = this.graphAccessToken(true);
    this.graphLoggedIn = graphToken !== '';
  }

  login(): void {
    this.authService.loginRedirect();
  }

  logout(): void {
    localStorage.removeItem('graphTokenRef');
    this.authService.logout();
  }

  getToken(): string {
    return localStorage['msal.idtoken'];
  }
  getCurrentUserRole(userGroup): Promise<object> {
    const data = { roles: userGroup };
    this.http.post(environment.entryAPI + getRoleApi, data);
    try {
      const response = this.http
        .post(environment.entryAPI + getRoleApi, data)
        .toPromise();
      return response;
    } catch (error) {}
  }
  graphAccessToken(strict = false): any {
    let token: string = !strict ? localStorage['msal.idtoken'] : '';
    for (let index = 0; index < localStorage.length; index++) {
      const element = localStorage.key(index);
      if (element.indexOf('User.Read') > 0) {
        token = JSON.parse(localStorage.getItem(element)).accessToken;
        break;
      }
    }
    return token;
  }
  // check the permission for all the available menu
  checkPermission(role: string, url: string): boolean {
    let userPermission;
    this.shared.userPermission.subscribe((data) => (userPermission = data));
    const flags = [];
    const urlArray = [];
    const l = userPermission.length;
    let i;
    if (url === '/demo') {
      return true;
    }
    for (i = 0; i < l; i++) {
      if (flags[userPermission[i].name]) {
        continue;
      }
      flags[userPermission[i].name] = true;
      if (userPermission[i].route_url.includes(',')) {
        urlArray.push(...userPermission[i].route_url.split(','));
      } else {
        urlArray.push(userPermission[i].route_url);
      }
    }
    if (url.includes('/imagedetails')) {
      url = '/imagedetails';
    }
    if (url === '/') {
      url = '/home';
    }

    if (urlArray && urlArray.length > 0) {
      if (urlArray.includes(url)) {
        return true;
      }
      return false;
    }
  }
  public async getMemberList(): Promise<object> {
    const res = new Promise<object>((resolve, reject) => {
      const headers: HttpHeaders = new HttpHeaders();

      this.http
        .get(
          this.GraphAPIBaseUrl +
            'groups/' +
            'b934fdc4-56b7-4aa8-af47-c9f64a3a73e9' +
            '/members',
          { headers }
        )
        .subscribe((err: any) => {
          console.log('error', err);
          if (
            err.error.error &&
            err.error.error.code === 'InvalidAuthenticationToken'
          ) {
          } else {
          }
        });
    });

    return res;
  }

  public async isTokenValid(): Promise<boolean> {
    const res = new Promise<boolean>((resolve, reject) => {
      const headers: HttpHeaders = new HttpHeaders();

      this.http.get(this.GraphAPIBaseUrl + 'me', { headers }).subscribe(
        (resp: any) => {
          if (resp.error && resp.code === 'InvalidAuthenticationToken') {
            resolve(false);
          } else {
            resolve(true);
          }
        },
        (err: any) => {
          if (
            err.error.error &&
            err.error.error.code === 'InvalidAuthenticationToken'
          ) {
            resolve(false);
          } else {
            resolve(true);
          }
        }
      );
    });

    return res;
  }

  getExpiry(): any {
    this.id = this.authService.getAccount().idToken.exp;
    return this.id * 1000;
  }

  // added menber list api hear for checking.
  getProfile(): any {
    return this.authService.getAccount().idToken;
  }
  getCurrentUserId(): any {
    return this.authService.getAccount().userName;
  }

  getUserId(): number {
    const roleId = 1;
    return roleId;
  }
  async getMemberListfunction(): Promise<any> {
    await this.getMemberList();
  }

  async checkLog(): Promise<void> {
    if (
      this.authService.getAccount().userName != null &&
      !(await this.isTokenValid())
    ) {
      localStorage.removeItem('graphTokenRef');
      this.checkAndRefreshForNewGraphToken();
      this.loaderService.show();
    }
  }

  getMlTokenSilent(): Observable<any> {
    const msalConfig = environment.msalConfiguration;
    const tokenRequest = environment.msalTokenReq;
    const msalInstance = new UserAgentApplication(msalConfig);
    return new Observable((observer: any) => {
      if (this.tokenExpired()) {
        msalInstance
          .acquireTokenSilent(tokenRequest)
          .then((res) => {
            localStorage.setItem('mltoken', res.accessToken);
            localStorage.setItem('tokenExpiryTime', res.expiresOn.toString());
            observer.next(res.accessToken);
          })
          .catch((err) => {
            this.getTokenLogin().subscribe((response: any) => {
              observer.next(response.mlToken);
            });
          });
      } else {
        observer.next(localStorage.getItem('mlToken'));
      }
    });
  }

  getTokenLogin(): Observable<any> {
    const msalConfig = environment.msalConfiguration;
    const loginRequest = environment.msalLoginReq;
    const msalInstance = new UserAgentApplication(msalConfig);
    return new Observable((observer: any) => {
      msalInstance
        .loginPopup(loginRequest)
        .then((response) => {
          const tokenRequest = environment.msalTokenReq;
          msalInstance
            .acquireTokenSilent(tokenRequest)
            .then((res) => {
              localStorage.setItem('mltoken', res.accessToken);
              localStorage.setItem('tokenExpiryTime', res.expiresOn.toString());
              observer.next({ status: true, mlToken: res.accessToken });
            })
            .catch((err) => {
              console.log('RESPONSE IN ERROR TOKEN', err);
              observer.next({ status: false, mlToken: null });
              // could also check if err instance of InteractionRequiredAuthError if you can import the class.
            });
        })
        .catch((err) => {
          console.log('err', err);
          observer.next({ status: false, mlToken: null });
          // handle error
        });
    });
  }

  tokenExpired(): boolean {
    if (!localStorage.getItem('tokenExpiryTime')) {
      return true;
    } else {
      const tokenExpiryTime = new Date(localStorage.getItem('tokenExpiryTime'));
      const timeDiff = new Date().getTime() - tokenExpiryTime.getTime();
      if (timeDiff > 0) {
        return true;
      }
    }
    return false;
  }

  // calculating session expiry time in sec
  calculateExpirySec(): number {
    let expirySecond;
    const expiryValue: any = this.authService.getAccount().idToken.exp;
    if (expiryValue !== null || expiryValue !== 0) {
      const exp = expiryValue * 1000;
      const expTime = new Date(exp).getTime();
      const currTime = new Date().getTime();
      const diff = expTime - currTime;
      expirySecond = diff / 1000;
    } else {
      expirySecond = 720;
    }
    return Math.floor(expirySecond);
  }
}
