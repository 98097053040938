import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AppStorageService } from '../../../core/services/app-storage.service';
import { DataService } from 'src/app/core/services/data.service';
import { AISI_ROLES, accessDenied } from '@shared/shared.constant';
import { Router } from '@angular/router';

@Component({
  selector: 'gdx-user',
  templateUrl: './user.component.html',
})
export class UserComponent implements OnChanges {
  @Input() roleList: any = [];
  imageUrl: any;
  isShow = false;
  role: any;
  dataServices: DataService;
  isApp = false;
  isNotification = false;
  isHelp = false;
  viewRoleModal = false;
  userName = '';
  roleName = '';
  actualRoleList: any = [];
  userImage: any;
  userInitial = '';
  constructor(
    private dataService: DataService,
    private authService: MsalService,
    private appStorage: AppStorageService,
    private router: Router
  ) {
    this.dataServices = dataService;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.roleList && this.roleList.length) {
      this.updateRole();
    } else {
      this.userInitial = accessDenied.NL;
      this.userName = accessDenied.NO_ROLE;
    }
  }

  userInitials(name: string): any {
    const userarray = name.split(' ');
    let user = '';
    if (userarray.length >= 2) {
      user = userarray[0].charAt(0) + userarray[1].charAt(0);
    } else {
      user = userarray[0].charAt(0);
    }
    return user;
  }
  hideSignOut(): void {
    this.isShow = false;
  }

  showblock(): void {
    this.isShow = !this.isShow;
    if (this.isShow === true) {
      this.imageUrl = './../../../../assets/images/user.svg';
    } else {
      this.imageUrl = './../../../../assets/images/user-white.svg';
    }
    this.dataServices.isUserToggle(this.isShow);
  }

  openRolesModal(): void {
    this.viewRoleModal = true;
  }

  logOut(): void {
    localStorage.clear();
    this.authService.logout();
  }

  changeRoleEvent(role: any): void {
    this.viewRoleModal = false;
    if (role && role.initial) {
      this.role = role.initial;
      this.roleName = role.meta;
    }
  }

  updateRole(): void {
    const token: any = this.authService.getAccount();
    if (token?.idToken) {
      this.userName = token.idToken.name.split('[')[0];
      this.userInitial = this.userInitials(this.userName);
      this.actualRoleList = this.roleList;
      if (this.roleList && this.roleList.length > 0) {
        const getRole: any = this.appStorage.getRole();
        if (
          getRole &&
          this.roleList.find((x: any) => x.name === getRole.name)
        ) {
          this.role = this.roleList.find(
            (x: any) => x.name === getRole.name
          ).initial;
          this.roleName = getRole.meta;
        } else {
          const roles = this.roleList.find((x: any) => {
            return this.roleList[0].name.toUpperCase().search(x.name) !== -1;
          });
          this.role = roles.initial;
          this.roleName = roles.meta;
          roles.selected = true;
          this.appStorage.setRole(JSON.stringify(roles));
        }
      }
    }
    if (
      JSON.parse(localStorage.getItem('selectedRole')).meta ===
      AISI_ROLES.PLATFORM_ADMIN
    ) {
      this.router.navigate(['/application/app-admin']);
    }

    this.imageUrl = './../../../../assets/images/user-white.svg';
    this.dataServices.onToggle.subscribe((result) => {
      this.isNotification = result;
      if (this.isNotification === true) {
        this.isShow = false;
        this.imageUrl = './../../../../assets/images/white-app-icon.svg';
      }
    });
    this.dataServices.onToggleShow.subscribe((result) => {
      this.isApp = result;
      if (this.isApp === true) {
        this.isShow = false;
        this.imageUrl = './../../../../assets/images/user-white.svg';
      }
    });
    this.dataServices.onHelpToggle.subscribe((result) => {
      this.isHelp = result;
      if (this.isHelp === true) {
        this.isShow = false;
        this.imageUrl = './../../../../assets/images/white-bell-icon.svg';
      }
    });
  }
}
