import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gdx-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss'],
})
export class CustomDropdownComponent implements OnInit {
  @Input() source;
  @Input() key;
  @Input() updateSource;
  @Output() parentChange = new EventEmitter<object>();
  selectedSource = '';

  ngOnInit(): void {
    this.updateSource.subscribe((data) => {
      if (this.key === data.key) {
        this.source = data.data;
        this.selectedSource = '';
      }
    });
  }
  ngOnChange(): void {
    console.log('source', this.source);
  }
  onParentChange(e, type, name): any {
    const parentObj = { value: e.value, type, name };
    this.parentChange.emit(parentObj);
  }
}
