<div [formGroup]="parentForm">
  <p-multiSelect
    [id]="id"
    [name]="name"
    [options]="options"
    [formControlName]="control"
    [optionLabel]="optionLabel"
    [placeholder]="placeholder"
    [virtualScroll]="virtualScroll"
    [itemSize]="itemSize"
    [showHeader]="showHeader"
    [displaySelectedLabel]="displaySelectedLabel"
    [style]="style"
    [panelStyle]="panelStyle"
    [styleClass]="class"
    [filter]="filter"
    [filterBy]="filterBy"
    [filterValue]="filterValue"
    [disabled]="disabled"
    [resetFilterOnHide]=resetFilterOnHide
    (onChange)="dropdownChange($event)"
  >
  </p-multiSelect>
</div>
