import {
  Component,
  OnInit,
  DoCheck,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/core/services/data.service';
import { btnTypes } from '@shared/enum/button';
import { isEmpty } from 'lodash';
import { ManageDatasetService } from '@pages/manage-dataset/services/manage-dataset.service';
import { ModelConfigInfo } from '@shared/components/experiment-drawer/experiment-drawer.component.types';
import { IRadioList } from '@shared/components/radio-button/radio-button.component.types';
import { toast } from 'tailwind-toast';
import { messages } from '@pages/manage-dataset/manage-dataset.message';
import {
  toastParams,
  hyperParams,
} from '@pages/manage-dataset/manage-dataset.constant';
import { labels } from '@shared/components/experiment-drawer/experiment-drawer.message';
import { UtilService } from '@core-services/util.service';

@Component({
  selector: 'gdx-experiment-drawer',
  templateUrl: './experiment-drawer.component.html',
})
export class ExperimentDrawerComponent implements OnInit, DoCheck {
  constructor(
    public formBuilder: FormBuilder,
    private dataService: DataService,
    private datasetService: ManageDatasetService,
    private ref: ChangeDetectorRef,
    private utilService: UtilService
  ) {
    this.configureExperimentForm();
  }
  @Output() expanded = new EventEmitter<boolean>();
  @Output() onApplyFilter = new EventEmitter<object>();
  @Output() trainSuccess = new EventEmitter<boolean>();
  @Input() experimentClass = '';
  @Input() filterOpen = false;
  @Input() modelData: ModelConfigInfo;
  appId = localStorage.getItem('selectedApp');
  isShow = true;
  expand = 'assets/images/filter.svg';
  collapse = 'assets/images/cross.svg';
  public btnType = btnTypes;
  public experimentFilter: FormGroup = new FormGroup({});
  isExpNameEmpty = false;
  isExpNameExists = false;
  toastConfig = toastParams;
  expName = '';
  label = labels;
  trayData = [];
  trayDataTemp = [];
  allDatasets = [];
  traySelected = false;
  radioButtons: IRadioList = {
    align: 'flex-col',
    radioList: [],
  };
  public experimentForm: FormGroup = new FormGroup({});

  ngOnInit(): void {
    this.getDatasetTrays();
    this.toastConfig.positionX = 'start';
  }
  radioChange($event: any): void {
    this.experimentForm.controls.selectedDataset.patchValue($event.target.id);
  }
  getDatasetData(trayId): void {
    this.datasetService
      .getTrayDatasets(this.appId, trayId)
      .subscribe((data: any) => {
        if (data && data.data) {
          data.data.map((obj: any) => {
            obj.label = obj.name;
            obj.name = 'selectedDataset';
            obj.labelClass = 'ml-2 text-gray-700 text-sm';
            obj.radioClass = 'form-radio h-5 w-5 text-gray-600';
            obj.checked = false;
            obj.disabled = false;
            obj.required = false;
            obj.control = 'selectedDataset';
            obj.parentForm = 'experimentForm';
          });
          this.radioButtons.radioList = data.data;
          this.allDatasets = data.data;
        }
      });
  }
  searchWithkeywords(searchValue): any {
    const data = this.allDatasets.filter((item) => {
      return item.label.toLowerCase().includes(searchValue.toLowerCase());
    });
    if (searchValue.trim() === '') {
      this.radioButtons.radioList = [...this.allDatasets];
    } else {
      if (data && data.length) {
        this.radioButtons.radioList = [...data];
      } else {
        this.radioButtons.radioList = [];
      }
    }
    this.ref.detectChanges();
    this.experimentForm.controls.selectedDataset.patchValue('');
  }
  // Start Radio button configuration
  configureExperimentForm(): void {
    this.experimentForm = this.formBuilder.group({
      selectedDataset: ['', Validators.required],
      experimentName: ['', Validators.required],
      selectedTray: ['', Validators.required],
    });
  }

  validateForm(): boolean {
    const isEmperimentEmpty = isEmpty(
      this.experimentForm.controls.experimentName.value
    );
    const isDatasetEmpty = isEmpty(
      this.experimentForm.controls.selectedDataset.value
    );
    const isTrayEmpty = isEmpty(
      this.experimentForm.controls.selectedTray.value
    );
    return isEmperimentEmpty || isDatasetEmpty || isTrayEmpty;
  }
  trayChanged(event): void {
    this.traySelected = true;
    this.experimentForm.controls.selectedTray.patchValue(event.id);
    this.getDatasetData(event.id);
  }
  searchTrays(event): void {
    this.trayDataTemp = this.trayData.filter((item) => {
      return item.name.toLowerCase().includes(event.query.toLowerCase());
    });
  }
  ifTrayEmpty(): void {
    this.traySelected = false;
    this.experimentForm.controls.selectedTray.patchValue('');
    this.radioButtons.radioList = [];
    this.allDatasets = [];
  }
  getDatasetTrays(): void {
    this.datasetService.getDatasetTrays(this.appId).subscribe((result: any) => {
      if (result && result.data) {
        result.data.map((obj: any) => {
          obj.name = this.utilService.concatTexts([
            obj.barcode,
            obj.name,
            obj.level,
          ]);
        });
        this.trayData = result.data;
        this.trayDataTemp = result.data;
      }
    });
  }
  trainModel(): void {
    const hyperParam = hyperParams;
    hyperParam.tray_id = this.experimentForm.controls.selectedTray.value;
    hyperParam.site_id = this.appId;
    const newEntry = {
      model: {
        id: this.modelData.id,
        name: this.modelData.name,
        appId: this.appId,
      },
      modelExperiment: {
        name: this.experimentForm.controls.experimentName.value,
        modelId: this.modelData.id,
        inputDataset: this.experimentForm.controls.selectedDataset.value,
      },
      trayId: this.experimentForm.controls.selectedTray.value,
    };

    this.datasetService.trainModel(newEntry).subscribe(
      (response) => {
        if (response) {
          this.trainSuccess.emit(true);
          toast()
            .success('', response.body.message)
            .with(this.toastConfig)
            .show();
        }
        this.expandCollapse();
      },
      (err) => {
        toast()
          .danger('Error! ', err.error.message)
          .with(this.toastConfig)
          .show();
      }
    );
  }

  ngDoCheck(): void {
    this.dataService.onHide.subscribe((result) => {
      this.isShow = result;
    });
  }

  expandCollapse = () => {
    this.filterOpen = !this.filterOpen;
    this.expanded.emit(this.filterOpen);
    this.allDatasets = [];
    this.radioButtons.radioList = [];
    this.traySelected = false;
    this.configureExperimentForm();
  };
}
