export const rolePermission = {
  downloadContentManagement: 'core:contentManagement:download',
  deleteContentManagement: 'core:contentManagement:delete',
  uploadContentManagement: 'core:contentManagement:upload',
  createProcessContentManagement: 'core:contentManagement:create',
  updateProcessContentManagement: 'core:contentManagement:update',
  approveProcessContentManagement: 'core:contentManagement:approve',
  readContentManagement: 'core:contentManagement:read',
  cloneContentManagement: 'core:contentManagement:clone',

  downloadSetLibraryManagement: 'core:setLibraryManagement:download',
  deleteSetLibraryManagement: 'core:setLibraryManagement:delete',
  uploadSetLibraryManagement: 'core:setLibraryManagement:upload',
  createSetLibraryManagement: 'core:setLibraryManagement:create',
  updateSetLibraryManagement: 'core:setLibraryManagement:update',
  approveSetLibraryManagement: 'core:setLibraryManagement:approve',
  readSetLibraryManagement: 'core:setLibraryManagement:read',
  cloneSetLibraryManagement: 'core:setLibraryManagement:clone',
  viewTrayProperty: 'core:setLibraryManagement:trayProperty:view',
  editTrayProperty: 'core:setLibraryManagement:trayProperty:edit',
  deleteTrayProperty: 'core:setLibraryManagement:trayProperty:delete',
  createTrayProperty: 'core:setLibraryManagement:trayProperty:create',

  readAuditReport: 'core:reports:audit:read',
  downloadAuditReport: 'core:reports:audit:download',
  readPerformanceReport: 'core:reports:performance:read',
  downloadPerformanceReport: 'core:reports:performance:download',
  readInspectionReport: 'core:reports:inspection:read',
  downloadInspectionReport: 'core:reports:inspection:download',

  viewKeySettings: 'core:settings:keySettings:view',
  createKeyEsignKeySettings: 'core:esign:settings:keySettings:createKey',
  editKeyEsignKeySettings: 'core:esign:settings:keySettings:editKey',
  deleteKeyEsignKeySettings: 'core:esign:settings:keySettings:deleteKey',
  viewRetentionSettings: 'core:settings:retentionSettings:view',
  saveExpiryEsignRetentionSettings:
    'core:esign:settings:retentionSettings:saveExpiry',
  saveNotificationEsignRetentionSettings:
    'core:esign:settings:retentionSettings:saveNotification',
  viewApprovalLevels: 'core:settings:approvalLevels:view',

  viewDatasetManagement: 'core:manageDataset:datasetManagement:view',
  viewExperiment: 'core:manageDataset:experiment:view',
  viewModelManagement: 'core:manageDataset:modelManagement:view',
  createPictureList: 'core:pictureListGenerator:create',
  editPictureList: 'core:pictureListGenerator:edit',
  readPictureList: 'core:pictureListGenerator:read',
  downloadPictureList: 'core:pictureListGenerator:download',
  readAnnotation: 'core:annotation:read',
  readSetLibrary: 'core:setLibrary:read',

  readRoleManagement: 'core:roleManagement:read',
  addRoleManagement: 'core:roleManagement:add',
  editRoleManagement: 'core:roleManagement:edit',
  deleteRoleManagement: 'core:roleManagement:delete',
  savePermEsignRoleManagement: 'core:esign:roleManagement:savePerm',
  addNewRoleEsignRoleManagement: 'core:esign:roleManagement:addNewRole',
  modifyRoleEsignRoleManagement: 'core:esign:roleManagement:modifyRole',
  deleteRoleEsignRoleManagement: 'core:esign:roleManagement:deleteRole',
  removePermissionEsignRoleManagement:
    'core:esign:roleManagement:removePermission',

  viewApplications: 'core:application:applications:view',
  editApplications: 'core:application:applications:edit',
  createApplications: 'core:application:applications:create',
  deleteApplications: 'core:application:applications:delete',
  viewConnections: 'core:application:connections:view',
  editConnections: 'core:application:connections:edit',
  createConnections: 'core:application:connections:create',
  deleteConnections: 'core:application:connections:delete',
  viewHierarchyType: 'core:application:hierarchy-type:view',
  editHierarchyType: 'core:application:hierarchy-type:edit',
  createHierarchyType: 'core:application:hierarchy-type:create',
  deleteHierarchyType: 'core:application:hierarchy-type:delete',
  viewRecommendHierarchy: 'core:application:recommend-hierarchy:view',
  editRecommendHierarchy: 'core:application:recommend-hierarchy:edit',
  createRecommendHierarchy: 'core:application:recommend-hierarchy:create',
  deleteRecommendHierarchy: 'core:application:recommend-hierarchy:delete',

  viewManageDatasetAppAdmin: 'core:manageDatasetAppAdmin:view',
  editManageDatasetAppAdmin: 'core:manageDatasetAppAdmin:edit',
  createManageDatasetAppAdmin: 'core:manageDatasetAppAdmin:create',

  viewManageSource: 'core:manageSource:read',
  addManageSource: 'core:manageSource:add',
  editManageSource: 'core:manageSource:edit',
  deleteManageSource: 'core:manageSource:delete',

  uploadManageData: 'core:manageData:upload',
  downloadManageData: 'core:manageData:download',

  createUserAccount: 'core:manageExternalUsers:create',
  bulkInsertAccounts: 'core:manageExternalUsers:upload',
  editUserAccount: 'core:manageExternalUsers:edit',
  deleteUserAccount: 'core:manageExternalUsers:delete',
  downloadUserAccounts: 'core:manageExternalUsers:download',

  addUserAssignments: 'core:manageUserAssignments:add',
  editUserAssignment: 'core:manageUserAssignments:edit',
  deleteUserAssignment: 'core:manageUserAssignments:delete',
  uploadUserAssignments: 'core:manageUserAssignments:upload',
  downloadUserAssignments: 'core:manageUserAssignments:download',

  addFilRecord: 'core:maintainLocalFil:add',
  editFilRecord: 'core:maintainLocalFil:edit',
  downloadFilRecord: 'core:maintainLocalFil:download',
  deleteFilRecords: 'core:maintainLocalFil:delete',

  downloadTntReports: 'core:tntReports:download',

  dataManagement: 'core:dataManagement:advUpload',
  uploadEsignDataManagement: 'core:esign:dataManagement:upload',
  updateEsignDataManagement: 'core:esign:dataManagement:updateMetadata',
  copyEsignDataManagement: 'core:esign:dataManagement:copy',
  deleteEsignDataManagement: 'core:esign:dataManagement:delete',
  createFolderEsignDataManagement: 'core:esign:dataManagement:createFolder',
  downloadSIM: 'core:dataManagement:downloadSIM',

  pages: {
    ContentManagement: 'core:contentManagement:?',
    SetLibraryManagement: 'core:setLibraryManagement:?',
    DeviceReports: 'core:deviceReports:?',
    Reports: 'core:reports:?',
    Settings: 'core:settings:?',
    JJVCSettings: 'core:JJVCSettings:?',
    ManageDataset: 'core:manageDataset:?',
    AuditReport: 'core:reports:audit:?',
    JJVCAuditReport: 'core:JJVCreports:audit:?',
    InspectionReport: 'core:reports:inspection:?',
    PerformanceReport: 'core:reports:performance:?',
    ModelManagement: 'core:manageDataset:modelManagement:?',
    Experiment: 'core:manageDataset:experiment:?',
    DatasetManagement: 'core:manageDataset:datasetManagement:?',
    PictureListGenerator: 'core:pictureListGenerator:?',
    Annotation: 'core:annotation:?',
    setLibrary: 'core:setLibrary:?',
    RoleManagement: 'core:roleManagementPage:?',
    JJVCRoleManagement: 'core:JJVCroleManagement:?',
    Application: 'core:application:applications:?',
    Connection: 'core:application:connections:?',
    HierarchyType: 'core:application:hierarchy-type:?',
    ManageDatasetAppAdmin: 'core:manageDatasetAppAdmin:?',
    ManageSource: 'core:manageSource:?',
    KeySettings: 'core:settings:keySettings:?',
    RetentionSettings: 'core:settings:retentionSettings:?',
    JJVCKeySettings: 'core:JJVCSettings:keySettings:?',
    JJVCRetentionSettings: 'core:JJVCSettings:retentionSettings:?',
    ApprovalLevels: 'core:settings:approvalLevels:?',
    ManageExternalUsers: 'core:manageExternalUsers:?',
    ManageData: 'core:manageData:?',
    ManageUserAssignments: 'core:manageUserAssignments:?',
    MaintainLocalFil: 'core:maintainLocalFil:?',
    tntReports: 'core:tntReports:?',
    DataManagement: 'core:dataManagement:?',
  },
};

export const navList = [
  {
    label: 'Manage Sets',
    path: '/set-management-page',
    icon: '',
    key: 'SetLibraryManagement',
    children: [
      {
        label: 'KIT CATALOG',
        path: '/set-management-page/kit-catalog',
        icon: './../../../assets/images/catalog-gray.svg',
        key: 'SetLibraryManagement',
      },
      {
        label: 'TRAY CATALOG',
        path: '/set-management-page/tray-catalog',
        icon: './../../../assets/images/catalog-statistics.svg',
        key: 'SetLibraryManagement',
      },
      {
        label: 'COMPONENT CATALOG',
        path: '/set-management-page/component-catalog',
        icon: '../../../../assets/images/book-gray.svg',
        key: 'SetLibraryManagement',
      },
    ],
  },
  {
    label: 'Manage Contents',
    path: '/content-management',
    icon: '',
    key: 'ContentManagement',
    children: [],
  },
  {
    label: 'Data Management',
    path: 'jjvc/upload/advanced-upload',
    icon: '',
    key: 'DataManagement',
    children: [],
  },
  {
    label: 'Reports',
    path: '/reports',
    icon: '',
    key: 'Reports',
    children: [
      {
        label: 'AUDIT',
        path: '/reports/audit',
        icon: '../../assets/images/reports-audit.svg',
        key: 'AuditReport',
      },
      {
        label: 'INSPECTION',
        path: '/reports/inspection',
        icon: '../../assets/images/reports-data-quality.svg',
        key: 'InspectionReport',
      },
      {
        label: 'PERFORMANCE',
        path: '/reports/performance',
        icon: '../../assets/images/reports-traceability.svg',
        key: 'PerformanceReport',
      },
    ],
  },
  {
    label: 'Reports',
    path: 'jjvc/reports',
    icon: '',
    key: 'JJVCAuditReport',
    children: [
      {
        label: 'AUDIT',
        path: 'jjvc/reports/audit',
        icon: '../../assets/images/reports-audit.svg',
        key: 'JJVCAuditReport',
      },
    ],
  },
  {
    label: 'Manage Training',
    path: '/manage-dataset',
    icon: '',
    key: 'ManageDataset',
    children: [
      {
        label: 'MANAGE DATASETS',
        path: '/manage-dataset/manage',
        icon: '../../assets/images/reports-traceability.svg',
        key: 'DatasetManagement',
      },
      {
        label: 'EXPERIMENT',
        path: '/manage-dataset/experiment',
        icon: '../../assets/images/reports-data-quality.svg',
        key: 'Experiment',
      },
      {
        label: 'MANAGE MODELS',
        path: '/manage-dataset/model-management',
        icon: '../../assets/images/reports-audit.svg',
        key: 'ModelManagement',
      },
    ],
  },
  {
    label: 'Annotation',
    path: '/annotation',
    icon: '',
    key: 'Annotation',
    children: [],
  },
  {
    label: 'Manage Source',
    path: '/manage-source',
    icon: '',
    key: 'ManageSource',
    children: [],
  },
  {
    label: 'Manage Roles',
    path: '/role-management',
    icon: '',
    key: 'RoleManagement',
    children: [],
  },
  {
    label: 'Manage Roles',
    path: 'jjvc/role-management',
    icon: '',
    key: 'JJVCRoleManagement',
    children: [],
  },
  {
    label: 'Application',
    path: '/application/app-admin',
    icon: '',
    key: 'Application',
    children: [],
  },
  {
    label: 'Connection',
    path: '/application/connection',
    icon: '',
    key: 'Connection',
    children: [],
  },
  {
    label: 'Hierarchy Type',
    path: '/application/hierarchy-type',
    icon: '',
    key: 'HierarchyType',
    children: [],
  },
  {
    label: 'Manage External Users',
    path: '/manage-external-users',
    icon: '',
    key: 'ManageExternalUsers',
    children: [],
  },
  {
    label: 'Manage Data',
    path: '/track-n-trace/upload-master-data',
    icon: '',
    key: 'ManageData',
    children: [
      {
        label: 'Upload',
        path: '/track-n-trace/upload-master-data',
        icon: '../../assets/images/uploadTNT.svg',
        key: 'ManageData',
      },
    ],
  },
  {
    label: 'Manage User Assignment',
    path: '/track-n-trace/manage-user-assignment-dashboard',
    icon: '',
    key: 'ManageUserAssignments',
    children: [],
  },
  {
    label: 'Maintain Local FIL and National Loaner',
    path: '/track-n-trace/maintain-local-fil-dashboard',
    icon: '',
    key: 'MaintainLocalFil',
    children: [],
  },
  {
    label: 'Reports',
    path: '/track-n-trace/reports',
    icon: '',
    key: 'tntReports',
    children: [],
  },
];

export const navListSettings = [
  {
    label: ' ',
    path: '/settings',
    icon: '../../assets/images/navsettings.svg',
    key: 'Settings',
    children: [
      {
        label: 'KEY SETTINGS',
        path: '/settings/key-settings',
        icon: '../../assets/images/repositories_publish.svg',
        key: 'KeySettings',
      },
      {
        label: 'RETENTION SETTINGS',
        path: '/settings/retention-settings',
        icon: '../../assets/images/system-settings.svg',
        key: 'RetentionSettings',
      },
      {
        label: 'APPROVAL LEVELS',
        path: '/settings/approval',
        icon: '../../assets/images/user-menu.svg',
        key: 'ApprovalLevels',
      },
    ],
  },
  {
    label: ' ',
    path: 'jjvc/settings',
    icon: '../../assets/images/navsettings.svg',
    key: 'JJVCSettings',
    children: [
      {
        label: 'KEY SETTINGS',
        path: 'jjvc/settings/key-settings',
        icon: '../../assets/images/repositories_publish.svg',
        key: 'JJVCKeySettings',
      },
      {
        label: 'RETENTION SETTINGS',
        path: 'jjvc/settings/retention-settings',
        icon: '../../assets/images/system-settings.svg',
        key: 'JJVCRetentionSettings',
      },
    ],
  },
];

export const BY_PASS_LOADER = [
  '.js',
  'index.json',
  '.css',
  '.svg',
  'v1/apps?id',
  'v1/searches',
  'v1/pictureLists?action=generatePdf',
  '/v1/datasets?type=train&name=',
  '/v1/datasets?type=master&name=',
];

export const HIDE_HEADER_FOOTER_URLS = ['annotation', 'image'];
