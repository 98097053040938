<div
  *ngIf="node && workflow"
  #nodeView
  [id]="node.id"
  [inBounds]="true"
  [style.top.px]=""
  [style.left.px]=""
  [bounds]="container"
  (movingOffset)="onMove($event)"
  (stopped)="onRelease($event)"
  [position]="{ x: xOffset, y: yOffset }"
  class="node"
  [handle]="dragHandle"
  ngDraggable
>
  <span class="node-header">
    <div class="node-header-text text-sm w-full text-gray-500">
      {{ node.title }}
    </div>
    <div class="node-header-text text-gray-500 w-full text-xs">
      {{ node.target }}
    </div>
  </span>
  <span
    class="connector connector-left"
    (mousedown)="connectorSelected($event)"
    (mouseup)="connectorReleased($event)"
  ></span>
  <span
    class="connector connector-right"
    (mousedown)="connectorSelected($event)"
    (mouseup)="connectorReleased($event)"
  ></span>
  <div class="node-content" #dragHandle>
    <span class="node-header"> </span>
    <span
      *ngIf="node && node.svg && node.svg.indexOf('.svg') != -1"
      [inlineSVG]="node.svg"
      class="inline inlineSVG node-svg text-gray-500"
    ></span>
  </div>

  <div
    class="workflow-cross"
    (click)="workflow.deleteNode(node); nodeDeleted()"
  >
    ✕
  </div>
</div>
