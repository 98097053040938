<div [formGroup]="parentForm" class="w-full">
  <textarea
    [id]="id"
    [name]="name"
    class="text-area"
    [rows]="rows"
    [cols]="cols"
    [class]="class"
    [ngClass]="{
      'border-red-500 focus:border-red-500':
        parentForm.controls[control].invalid &&
        parentForm.controls[control].dirty
    }"
    [placeholder]="placeholder"
    [attr.disabled]="disabled ? '' : null"
    [readonly]="readonly"
    (change)="valueChange($event)"
    (keypress)="keyPress($event)"
    (keyup)="keyUp($event)"
    (keydown)="keyDown($event)"
    [formControlName]="control"
    (input)="inputChange($event)"
    (blur)="blur($event)"
    #content
  ></textarea>
</div>
