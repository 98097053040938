import { Component, OnInit, Input } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { btnTypes } from '../../enum/button';

@Component({
  selector: 'gdx-no-role-tag',
  templateUrl: './no-role-tag.component.html',
  styles: [],
})
export class NoRoleTagComponent {
  @Input() showModal = false;
  public btnType = btnTypes;
  constructor(private readonly msalService: MsalService) {}

  logout(): void {
    this.showModal = false;
    this.msalService.logout();
  }
}
