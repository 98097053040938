<!-- Header z-index: 99999999, because of ECharts Tooltip = z-index=9999999,  -->
<div *ngIf="visible" class="sticky top-0 z-40">
  <nav
    class="sticky left-0 right-0 z-40 bg-primary-500"
    style="backdrop-filter: blur(20px)"
    [ngClass]="isHide ? '' : 'moveTop'"
  >
    <div class="pl-2 pr-1">
      <div class="relative flex items-center justify-between h-11">
        <div
          class="flex items-center justify-center flex-1 sm:items-stretch sm:justify-start"
        >
          <div
            class="flex items-center flex-shrink-0 outline-none cursor-pointer"
            [routerLink]="
              selectedAppName === labels.JJV_MACHINE_ARCHIVE ? null : '/'
            "
            (click)="
              selectedAppName === labels.JJV_MACHINE_ARCHIVE ? null : homePage()
            "
          >
            <h1
              class="text-2xl text-white"
              [ngClass]="
                selectedAppName === labels.JJV_MACHINE_ARCHIVE
                  ? 'cursor-default'
                  : 'cursor-pointer'
              "
            >
              {{ logoName }}
            </h1>
          </div>
          <div class="flex w-full pl-2">
            <gdx-navigation
              class="w-1/2"
              *ngIf="navList.navList.length > 0"
              [navListDetails]="navList"
              [navListSettings]="navListSettings"
              (selectedItem)="changeSelectedNav($event)"
            >
            </gdx-navigation>
          </div>
        </div>
        <div
          class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:pr-0"
        >
          <!-- Profile dropdown -->
          <div class="relative">
            <ul class="flex h-11">
              <gdx-settings-nav
                *ngIf="navListSettings && navListSettings.navList.length > 0"
                [navListDetails]="navListSettings"
                (selectedItem)="changeSelectedNav($event)"
              ></gdx-settings-nav>
              <li
                class=""
                style="
                  background-color: white;
                  border: 1px solid #bd0000;
                  height: 2.9 rem;
                "
              >
                <p-dropdown
                  [id]="'app-list'"
                  [name]="'app-list'"
                  class="w-40"
                  [styleClass]="
                    ' text-white w-40 pt-1.5 mr-1.5 bg-transparent focus:outline-none focus:shadow-none border-none border-b'
                  "
                  [options]="appList"
                  [optionLabel]="'name'"
                  [optionValue]="'id'"
                  [placeholder]="'Select a App'"
                  [showClear]="false"
                  [filter]="false"
                  (onChange)="selectApp($event)"
                  [(ngModel)]="selectedApp"
                >
                </p-dropdown>
              </li>

              <li><gdx-user [roleList]="actualRoleList"></gdx-user></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div
    *ngIf="childNavList.navList && childNavList.navList.length > 0"
    class="relative left-0 right-0 flex py-1 pl-3 bg-gray-100"
  >
    <div class="absolute left-0 top-0.5 bg-gray-100 h-7">
      <gdx-sub-nav [subNavListDetails]="childNavList"> </gdx-sub-nav>
    </div>
  </div>
</div>
<ng-container *ngIf="showRole == true">
  <gdx-changeroles
    [roleList]="roleList"
    [closeIcon]="true"
    (roleSelect)="roleSelect($event)"
  ></gdx-changeroles>
</ng-container>
