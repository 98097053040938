export const labels = {
  esign: 'e-Signature',
  userName: 'Email/Username',
  userNameInfo: 'Enter Email/Username of logged-in user.',
  pwd: 'Password',
  reason: 'Reason',
  comment: 'Comment',
  disclaimer: `DISCLAIMER: I acknowledge that my electronic signature is the legally
    binding equivalent of my handwritten signature`,
  success: 'Success',
  failed: 'Failed',
};
