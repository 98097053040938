<div class="flex">
  <div class="mr-2 relative" *ngFor="let data of datalist">
    <div
      class="relative border rounded border-solid border-gray-300 flex px-2 py-1"
      [ngClass]="{ 'rounded-b-none': data.drpdownOpen }"
    >
      <span
        class="flex justify-center items-center font-semibold mr-2"
        [title]="data.toolTip"
        >{{ data.key }}:
      </span>
      <div
        *ngFor="let val of data.displayValue"
        class="flex justify-center items-center"
      >
        <span
          [ngClass]="{
            'block overflow-hidden overflow-ellipsis whitespace-nowrap max-w-xs':
              data.displayValue && data.displayValue.length > 1
          }"
          [title]="val"
          >{{ val }}</span
        >
        <img
          class="float-right mt-1 ml-1 mr-1"
          src="assets/images/cross.svg"
          *ngIf="data.value && data.value.length > 1"
          (click)="onDeleteValue(data, val)"
        />
      </div>
      <span
        *ngIf="data.otherValues && data.otherValues.length > 0"
        (click)="toggleDropdown(data)"
        class="flex items-center"
      >
        <img
          *ngIf="!data.drpdownOpen"
          class="h-2"
          src="assets/images/expand-blue.svg"
        />
        <img
          *ngIf="data.drpdownOpen"
          class="h-2"
          src="assets/images/collapse-blue.svg"
        />
      </span>
    </div>
    <div
      *ngIf="data.drpdownOpen"
      class="absolute grid grid-cols-3 border border-t-0 border-gray-300 py-0 px-2 z-10 opacity-100 bg-white overflow-y-auto max-h-24 w-full"
    >
      <div *ngFor="let val of data.otherValues">
        <span
          class="w-4/6 overflow-hidden overflow-ellipsis inline-block whitespace-nowrap"
          [title]="val"
          >{{ val }}</span
        >
        <img
          class="float-right mt-1 mr-1"
          src="assets/images/cross.svg"
          *ngIf="data.value && data.value.length > 1"
          (click)="onDeleteValue(data, val)"
        />
      </div>
    </div>
  </div>
</div>
