<div class="custom-dialog {{ modalType }}">
  <mat-dialog-content>
    <div
      class="confirm-header"
      [ngClass]="
        modalType === 'success'
          ? 'confirm-header-success'
          : modalType === 'warn'
          ? 'confirm-header-warn'
          : ''
      "
    >
      <p
        class="icon"
        [ngClass]="
          modalType === 'success'
            ? 'icon-success'
            : modalType === 'warn'
            ? 'icon-warn'
            : ''
        "
      ></p>
      <p>
        <b>{{
          modalType === 'success'
            ? 'CONFIRMATION'
            : modalType === 'warn'
            ? 'WARNING'
            : ''
        }}</b>
      </p>
    </div>
  </mat-dialog-content>
  <div class="dialog-body">
    <div class="message" [innerHtml]="modalMessage"></div>
    <mat-dialog-actions align="center" class="ok" *ngIf="showContinue">
      <button
        mat-raised-button
        (click)="oncancel()"
        class="okButton float-left"
      >
        Cancel
      </button>
      <button
        mat-raised-button
        [mat-dialog-close]="true"
        class="okButton float-left"
      >
        Save Changes
      </button>
    </mat-dialog-actions>
    <mat-dialog-actions align="center" class="ok" *ngIf="showDeleteWarning">
      <button mat-raised-button [mat-dialog-close]="true" class="okButton">
        Cancel
      </button>
      <button mat-raised-button (click)="onDelete()" class="okButton">
        Delete
      </button>
    </mat-dialog-actions>
    <mat-dialog-actions
      align="end"
      class="ok"
      *ngIf="!showContinue && !showDeleteWarning"
    >
      <button mat-raised-button [mat-dialog-close]="true" class="okButton">
        Close
      </button>
    </mat-dialog-actions>
  </div>
</div>
