<div [formGroup]="parentForm">
  <p-autoComplete
    [id]="id"
    [name]="name"
    class="w-full"
    [formControlName]="control"
    [placeholder]="placeholder"
    panelStyleClass="panelStyleClass"
    [styleClass]="class"
    [disabled]="disabled"
    [minLength]="minLength"
    [suggestions]="filteredEntities"
    (completeMethod)="filterEntity($event)"
    (onSelect)="selectEntity($event)"
    (onUnselect)="unselectEntity($event)"
    (onBlur)="blurEntity($event)"
    [multiple]="multiple"
    [field]="filterField"
    [inputStyleClass]="inputStyleClass"
    (onKeyUp)="keyUpEntity($event)"
    [emptyMessage]="emptyMessage"
  >
  </p-autoComplete>
</div>
