import { Injectable } from '@angular/core';
import { utc } from 'moment';
import {
  DATE_TIME_FORMAT,
  fileTypes,
  AISI_ROLES,
  APPNAME_KEYS,
  SELECTED_APP_NAME,
} from '@shared/shared.constant';
import { isEmpty } from 'lodash';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { HttpHeaders } from '@angular/common/http';
import { REPORTS_CONSTANTS } from '@pages/track-n-trace/track-n-trace.constant';
import * as moment from 'moment';
import { fieldNames } from '@pages/app-settings/app-settings.constant';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  selectProps(...props: any): any {
    return (obj: any): any => {
      const newObj = {};
      props.forEach((name: any) => {
        newObj[name] = obj[name];
      });
      return newObj;
    };
  }

  isAisiApp(isUnitTest = false): boolean {
    let appName = '';
    if (!isUnitTest) {
      appName = localStorage.getItem(SELECTED_APP_NAME);
    }
    if (appName && appName.startsWith(APPNAME_KEYS.AISI + '-')) {
      return true;
    }
    return false;
  }

  getTimeZoneOffset(): number {
    const timeZoneOffset = new Date().getTimezoneOffset();
    if (this.isAisiApp()) {
      return timeZoneOffset;
    }
    return 0;
  }

  formatDate(dateString: any): any {
    if (isEmpty(dateString)) {
      return '';
    }
    return utc(dateString).format(DATE_TIME_FORMAT);
  }

  formatDateUTCToLocal(dateString: any, isUnitTest = false): any {
    if (isEmpty(dateString)) {
      return '';
    }
    if (this.isAisiApp(isUnitTest)) {
      return utc(dateString).local().format(DATE_TIME_FORMAT);
    }
    return this.formatDate(dateString);
  }

  dateLocalToUTC(dateString: any): any {
    if (isEmpty(dateString.toString())) {
      return '';
    }
    const newDate = new Date(dateString.toString());
    const isoStr = newDate.toISOString();
    return isoStr;
  }

  checkTimeDisplayFormat(date: any): any {
    const lastLetter = date.slice(-1);
    const excludeLastLetter = date.slice(0, -1);
    let timeConstant = '';
    if (lastLetter === REPORTS_CONSTANTS.H) {
      timeConstant = REPORTS_CONSTANTS.HRs;
    } else if (lastLetter === REPORTS_CONSTANTS.D) {
      timeConstant = REPORTS_CONSTANTS.Days;
    } else if (lastLetter === REPORTS_CONSTANTS.W) {
      timeConstant = REPORTS_CONSTANTS.Weeks;
    }
    return { excludeLastLetter, timeConstant };
  }

  parseISOString(s): string {
    if (!isEmpty(s)) {
      const b = s.split(/\D+/);
      const d = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
      return d.toDateString();
    } else {
      return s;
    }
  }

  getFileNamefromHeaders(headers): string {
    let fileName = '';
    const disposition = headers.get('Content-disposition');
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '');
    }
    return fileName;
  }

  convertDatesToISOString(start, end): any {
    const startDate = start
      ? new Date(
          moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss')
        ).toISOString()
      : '';
    const endDate = end
      ? new Date(
          moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        ).toISOString()
      : '';
    return { startDate, endDate };
  }

  fileDownload(fileType, fileName, fileBody): void {
    switch (fileType) {
      case fileTypes.xlsx: // excel
        saveAs(fileBody, fileName);
        break;
      case fileTypes.csv: // csv
        const excelData: Blob = new Blob([fileBody], {
          type: 'text/csv;charset=utf-8',
        });
        saveAs(excelData, fileName);
        break;
      default:
        break;
    }
  }

  checkSpecialCharacters(event): boolean {
    let k;
    k = event.keyCode;
    return (k > 64 && k < 91) || (k > 96 && k < 123) || k === 8;
  }

  isRolePlatformAdmin(role: any): boolean {
    if (role.toLowerCase() === AISI_ROLES.PLATFORM_ADMIN.toLowerCase()) {
      return true;
    } else {
      return false;
    }
  }

  safe_decode_range(range: any): any {
    const o = { s: { c: 0, r: 0 }, e: { c: 0, r: 0 } };
    let idx = 0;
    let i = 0;
    let cc = 0;
    const len = range.length;
    for (idx = 0; i < len; ++i) {
      cc = range.charCodeAt(i) - 64;
      if (cc < 1 || cc > 26) {
        break;
      }
      idx = 26 * idx + cc;
    }
    o.s.c = --idx;
    for (idx = 0; i < len; ++i) {
      cc = range.charCodeAt(i) - 48;
      if (cc < 0 || cc > 9) {
        break;
      }
      idx = 10 * idx + cc;
    }
    o.s.r = --idx;

    if (i === len || range.charCodeAt(++i) === 58) {
      o.e.c = o.s.c;
      o.e.r = o.s.r;
      return o;
    }

    for (idx = 0; i !== len; ++i) {
      cc = range.charCodeAt(i) - 64;
      if (cc < 1 || cc > 26) {
        break;
      }
      idx = 26 * idx + cc;
    }
    o.e.c = --idx;
    for (idx = 0; i !== len; ++i) {
      cc = range.charCodeAt(i) - 48;
      if (cc < 0 || cc > 9) {
        break;
      }
      idx = 10 * idx + cc;
    }
    o.e.r = --idx;
    return o;
  }

  getHeaders(sheet: any): any {
    let header = 0;
    let offset = 1;
    const hdr = [];
    const o: any = {};
    if (sheet == null || sheet['!ref'] == null) {
      return [];
    }
    const range = o.range !== undefined ? o.range : sheet['!ref'];
    let r;
    if (o.header === 1) {
      header = 1;
    } else if (o.header === 'A') {
      header = 2;
    } else if (Array.isArray(o.header)) {
      header = 3;
    } else {
    }
    switch (typeof range) {
      case 'string':
        r = this.safe_decode_range(range);
        break;
      case 'number':
        r = this.safe_decode_range(sheet['!ref']);
        r.s.r = range;
        break;
      default:
        r = range;
    }
    if (header > 0) {
      offset = 0;
    }
    const rr = XLSX.utils.encode_row(r.s.r);
    const cols = new Array(r.e.c - r.s.c + 1);
    for (let C = r.s.c; C <= r.e.c; ++C) {
      cols[C] = XLSX.utils.encode_col(C);
      const val = sheet[cols[C] + rr];
      switch (header) {
        case 1:
          hdr.push(C);
          break;
        case 2:
          hdr.push(cols[C]);
          break;
        case 3:
          hdr.push(o.header[C - r.s.c]);
          break;
        default:
          if (val === undefined) {
            continue;
          }
          hdr.push(XLSX.utils.format_cell(val));
      }
    }
    return hdr;
  }

  concatTexts(values: any[]): string {
    return values
      .filter((x) => typeof x === 'string' && x.length > 0)
      .join('-');
  }

  getDaysBefore(days: number): any {
    const date = new Date();
    const todayDate =
      date.getFullYear() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2);
    date.setDate(date.getDate() - days);
    const finalDate =
      date.getFullYear() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2);
    const daysBefore = [finalDate, todayDate];
    return daysBefore;
  }

  addReqHeaders(reqObj): any {
    const newObj = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: reqObj,
    };
    return newObj;
  }

  isImgURLValid = (path) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = path;
      img.onload = resolve;
      img.onerror = reject;
      img.src = path;
    });
  };

  fileNameWithTimeStamp(fileName: string, delimiter: string): string {
    let name = fileName;
    if (fileName) {
      const ext: string = fileName.split('.').pop();
      const date = new Date();
      const time =
        date.toISOString().slice(0, 10) +
        ' GMT-' +
        date.toISOString().slice(11, 16);
      const fileNameWithLocalDt = `${time}.${ext}`;
      const splitName = fileName.split(delimiter);
      splitName[splitName.length - 1] = fileNameWithLocalDt;
      name = splitName.join(delimiter);
    }
    return name;
  }
}
