<ng-container *ngIf="!sessionExpired">
  <div class="w-full">
    <gdx-header
      [visible]="loggedIn && roleList.length > 0 && isAppLoad"
    ></gdx-header>

    <div *ngIf="accessGranted">
      <router-outlet
        (activate)="onActivate($event)"
        class="w-screen justify-between"
        [ngClass]="!footerVisible ? '' : 'p-9'"
      ></router-outlet>
    </div>
    <ng-container *ngIf="accessDenied">
      <div>
        <router-outlet></router-outlet>
      </div>
    </ng-container>
    <div *ngIf="loggedIn && roleList.length > 0 && isAppLoad">
      <ng-container *ngIf="footerVisible">
        <gdx-footer></gdx-footer>
      </ng-container>
    </div>
  </div>
  <div *ngIf="isNoRoleModal">
    <gdx-no-role-tag [showModal]="isNoRoleModal"></gdx-no-role-tag>
  </div>

  <div *ngIf="!isAppLoad">
    <gdx-no-app
      [showModal]="!isAppLoad"
      [selectedRole]="selectedRole"
    ></gdx-no-app>
  </div>
</ng-container>
<div *ngIf="sessionExpired">
  <div
    class="fixed z-999 w-1/2 overflow-hidden bg-white border-solid border-4 border-blue-300 top-1/3 left-1/4 text-zinc-700 p-8"
  >
    <h3 class="font-bold text-4xl mb-4">{{ reload_message.WARN_MESSAGE }}</h3>
    <p class="text-xl">{{ reload_message.MAIN_MESSAGE }}</p>
  </div>
</div>
<app-loader></app-loader>
