import {
  Component,
  OnInit,
  Input,
  OnChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IDesign, INavigation, INavList } from './navigation.component.types';

@Component({
  selector: 'gdx-navigation',
  templateUrl: './navigation.component.html',
})
export class NavigationComponent implements OnInit, OnChanges {
  @Input('navListDetails') navListDetails: INavigation | undefined;
  @Input('navListSettings') navListSettings: INavigation | undefined;
  @Output()
  selectedItem = new EventEmitter<INavList>();
  navDesign: IDesign = {
    styleElmentList: '',
    styleElementAnchor: '',
    styleActiveLink: '',
  };
  navList: INavList[] = [
    {
      label: '',
      path: '',
      icon: '',
      key: '',
    },
  ];
  navListSetting: INavList[] = [
    {
      label: '',
      path: '',
      icon: '',
      key: '',
    },
  ];
  activeLink = 'bg-red-800 text-white';
  navListStyle = '';
  navAnchorStyle = '';
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.selectMenuOnRefresh();
      }
    });
    this.selectMenuOnRefresh();
    if (this.navListDetails && Object.keys(this.navListDetails).length) {
      this.navDesign = this.navListDetails.design
        ? this.navListDetails.design
        : this.navDesign;
      this.navList = this.navListDetails.navList
        ? this.navListDetails.navList
        : this.navList;
      this.navListStyle = this.navDesign.styleElmentList
        ? this.navDesign.styleElmentList
        : this.navAnchorStyle;
      this.navAnchorStyle = this.navDesign.styleElementAnchor
        ? this.navDesign.styleElementAnchor
        : this.navAnchorStyle;
      this.activeLink = this.navDesign.styleActiveLink
        ? this.navDesign.styleActiveLink
        : this.activeLink;
    }
  }

  ngOnChanges(): void {
    if (this.navListDetails && Object.keys(this.navListDetails).length) {
      this.navDesign = this.navListDetails.design
        ? this.navListDetails.design
        : this.navDesign;
      this.navListSetting =
        this.navListSettings && this.navListSettings.navList
          ? this.navListSettings.navList
          : this.navListSetting;
      this.navList = this.navListDetails.navList
        ? this.navListDetails.navList
        : this.navList;
      this.navListStyle = this.navDesign.styleElmentList
        ? this.navDesign.styleElmentList
        : this.navAnchorStyle;
      this.navAnchorStyle = this.navDesign.styleElementAnchor
        ? this.navDesign.styleElementAnchor
        : this.navAnchorStyle;
      this.activeLink = this.navDesign.styleActiveLink
        ? this.navDesign.styleActiveLink
        : this.activeLink;
    }
  }

  getActiveClass(event: any): void {
    if (this.navDesign && this.navDesign.styleActiveLink) {
      this.activeLink = this.navDesign.styleActiveLink;
    }
    this.selectedItem.emit(event);
  }

  selectMenuOnRefresh(): void {
    this.navList.forEach((item) => {
      if (this.router.url.includes(item.path)) {
        this.getActiveClass(item);
      }
    });
    this.navListSetting.forEach((item) => {
      if (this.router.url.includes(item.path)) {
        this.getActiveClass(item);
      }
    });
  }
}
