<div
  class="-mt-8 bg-white fixed h-auto z-50 shadow-md"
  [ngClass]="inspectionClass"
>
  <div *ngIf="expand" class="fixed left-0 border-gray-400 text-gray-400 w-6">
    <span
      (click)="expandCollapse()"
      class="cursor-pointer"
      [inlineSVG]="expand"
    >
    </span>
  </div>
  <div
    *ngIf="filterOpen"
    class="border-r pb-5 border-b-0 border-gray-40 min-h-screen shadow-md"
  >
    <form [formGroup]="inspectionFilter">
      <div class="flex flex-row border-b border-gray-400 justify-between">
        <div class="ml-6 mt-1 mb-1 text-sm font-bold">{{ label.filterBy }}</div>
        <div
          class="cursor-pointer flex items-center pr-0.5"
          (click)="expandCollapse()"
        >
          <span [inlineSVG]="collapse"> </span>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-1 mr-4">
        <div class="mt-1.5 mb-1 flex flex-col">
          <div class="ml-6 mb-1 text-sm font-medium">
            {{ label.inspectedBy }}
          </div>
          <div class="flex md:flex-row sm:flex-col ml-4 pr-4 w-full">
            <gdx-multiselect-dropdown
              [id]="'txtUserId'"
              [name]="'txtUserId'"
              class="w-full"
              [class]="
                'ml-1 w-full border border-gray-300 hover:border-gray-300 focus:outline-none focus:shadow-none focus:border-gray-500 small-font-pdropdown'
              "
              [control]="'selectedUser'"
              [options]="userList"
              [optionLabel]="'inspectedBy'"
              [placeholder]="allSelectPlaceHolder"
              [showHeader]="true"
              [filter]="true"
              [parentForm]="inspectionFilter"
            >
            </gdx-multiselect-dropdown>
          </div>
        </div>
        <div class="mt-1.5 mb-1 flex flex-col">
          <div class="ml-6 mb-1 text-sm font-medium">
            {{ label.inspectedOn }}
            <span class="text-red">*</span>
            <span
              ><img
                src="../../../assets/images/info.svg"
                title="Selected period will be converted to UTC timezone"
                alt="info"
                class="inline cursor-pointer ml-1 -mt-1"
                width="16"
                height="16"
            /></span>
          </div>
          <div class="flex md:flex-col sm:flex-col ml-4 pr-4 w-full">
            <div>
              <gdx-date-picker
                [id]="'selectedperiod'"
                [name]="'period'"
                class="w-full"
                [class]="'w-full ml-1 z-100'"
                [inputStyleClass]="'border border-gray-300'"
                [placeholder]="'Select Date Range'"
                [showIcon]="true"
                [required]="false"
                [showTime]="false"
                [control]="'selectedPeriod'"
                [parentForm]="inspectionFilter"
                [selectionMode]="'range'"
                [maxDate]="maxDate"
                [baseZIndex]="999999999"
                [readonlyInput]="true"
              >
              </gdx-date-picker>
            </div>
            <div class="text-xs text-red-600 ml-1">
              {{ errorMessage }}
            </div>
          </div>
        </div>
      </div>

      <div class="h-7 pr-1 flex justify-end w-full mt-4 mr-3">
        <div class="1/4 mr-2">
          <gdx-button
            [id]="'btn_filter-apply'"
            [type]="btnType.test"
            [text]="'Reset'"
            (clicked)="resetFilter()"
          >
          </gdx-button>
        </div>
        <div class="1/4 mr-3">
          <gdx-button
            [id]="'btn_filter-apply'"
            [type]="btnType.primary"
            [text]="'Apply'"
            (clickEvent)="applyFilter($event)"
          >
          </gdx-button>
        </div>
      </div>
    </form>
  </div>
</div>
