<p-selectButton
  [options]="options"
  [(ngModel)]="selectedbtn"
  [optionLabel]="optionLabel"
  [style]="style"
  [styleClass]="class"
  [optionValue]="optionValue"
  (onChange)="selectButtonChange($event)"
>
</p-selectButton>
