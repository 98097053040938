<div
  class="overflow-hidden fixed inset-0 z-50 outline-none h-screen focus:outline-none justify-center items-center flex"
>
  <div class="relative my-6 w-full mx-auto max-w-xl pt-1">
    <!--content-->
    <div
      class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
    >
      <!--header-->
      <div
        class="flex items-start h-14 justify-center border-b border-solid border-blueGray-200 rounded-t bg-red-700"
      >
        <h4
          class="text-2xl text-center text-white flex items-center w-full justify-center h-full"
        >
          SWITCH ROLES
        </h4>
        <button
          *ngIf="closeIcon"
          (click)="close()"
          class="p-1 ml-auto text-white float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
        >
          <span
            class="h-6 w-6 float-right top-0 right-0 absolute text-2xl block outline-none focus:outline-none pt-1.5 mr-1"
          >
            <span [inlineSVG]="collapse"> </span>
          </span>
        </button>
      </div>
      <!--body-->
      <div class="relative">
        <div class="my-0.5 text-blueGray-500 text-lg leading-relaxed font-bold">
          <div class="text-center text-xl">Please select role to change</div>

          <div class="container my-0.5 mx-auto px-6">
            <div *ngFor="let item of roleList" class="flex-shrink float-left">
              <!-- Column -->
              <div class="my-3 px-1 flex-shrink">
                <!-- Article -->
                <article class="overflow-hidden rounded-lg shadow-lg mr-2">
                  <div
                    (click)="selected(item)"
                    class="h-16 w-40 text-center text-white text-3xl cursor-pointer hover:bg-blue-700 flex items-center justify-center"
                    [ngClass]="item.selected ? 'bg-blue-700' : 'bg-gray-300'"
                  >
                    <span>{{ item.initial }}</span>
                  </div>
                  <footer class="flex items-center w-40 justify-center">
                    <div class="h-8">
                      <span class="text-xs text-gray-500 whitespace-nowrap">{{
                        item.meta
                      }}</span>
                    </div>
                  </footer>
                </article>
                <!-- END Article -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center p-2 justify-center rounded-b">
        <gdx-button
          [id]="'btnSwitchRole'"
          [text]="LABELS.GO"
          [type]="btnType.submit"
          (clicked)="submitRole()"
        >
        </gdx-button>
      </div>
    </div>
  </div>
</div>
<div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
