import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { AppRoleReq } from '@shared/interfaces/app.role.req';
import { PLATFORM_ADMIN } from '@shared/components/header/header.component.types';
import { MsalService } from '@azure/msal-angular';
import { AppStorageService } from './app-storage.service';
import { PermissionsService } from '@core/permission/permissions.service';
import { filter, map, find } from 'lodash';
import { Observable } from 'rxjs';
import { AppService } from './app.service';
import { SAS_SCOPES } from './../../app.config';
import { CommonService } from './common.service';
import { isEmpty } from 'lodash';
import { Router } from '@angular/router';
import { SharedService } from '@core-services/shared.service';
import { AppConfigService } from '@core-services/app-config.service';
import { DataService } from 'src/app/core/services/data.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  entryAPI = environment.entryAPI;
  public appList;
  public roles;
  public permissionData;
  constructor(
    private http: HttpClient,
    private authService: MsalService,
    private appStorage: AppStorageService,
    private permissionServ: PermissionsService,
    private appService: AppService,
    private commonService: CommonService,
    private router: Router,
    private sharedService: SharedService,
    private appConfigService: AppConfigService,
    private dataService: DataService
  ) {}

  getUserRolePermissions(data: string): any {
    return this.http
      .get(this.entryAPI + 'roles' + `/permissions/` + data)
      .toPromise();
  }

  getRoleAppList(data: any): any {
    const query = '?status=' + true;
    return this.http.get(this.entryAPI + `apps/` + query);
  }

  getCurrentUserRole(): any {
    const currentRole: any = localStorage.getItem('selectedRole');
    const roles = currentRole ? JSON.parse(currentRole) : '';
    return roles;
  }

  userUnauthorized(): any {
    return this.http.post(this.entryAPI + `users?action=unauthorized`, {});
  }

  getHeaderAlert(): any {
    return this.http.get(this.entryAPI + 'sites');
  }
  getRolesByApplication(req: AppRoleReq): any {
    return this.http.post(this.entryAPI + `roles/${req.applicationId}`, {
      roles: req.roles,
    });
  }
  getRoles(req: AppRoleReq): any {
    return this.http.post(this.entryAPI + `roles/`, { roles: req.roles });
  }
  getRolesappOnly(req: AppRoleReq): any {
    return this.http.post(this.entryAPI + `roles/`, { roles: req.roles });
  }
  public updateData(): Observable<any> {
    return new Observable((process) => {
      if (!this.authService.getAccount()) {
        process.next(false);
      } else {
        this.appList = this.getRoleAppList({}).subscribe(
          (appList: any) => {
            try {
              this.appList = appList;
              const token: any = this.authService.getAccount();
              const roleList = token.idToken.roles;
              const reqData: AppRoleReq = {
                applicationId: null,
                roles: roleList,
              };
              this.getRoles(reqData).subscribe(
                (roleData: any) => {
                  try {
                    this.roles = roleData;
                    const appsData = this.updatePermission();
                    process.next(appsData);
                  } catch (error) {
                    process.next(false);
                  }
                },
                (err) => {
                  process.next(false);
                }
              );
            } catch (err) {
              this.router.navigate(['/access-denied']);
            }
          },
          (err) => {
            this.dataService.isNavigationLoad.next(true);
            this.router.navigate(['/access-denied']);
          }
        );
      }
    });
  }

  updatePermission(): any {
    try {
      if (this.appList && this.appList.data && this.appList.data.length) {
        let appListLc = this.appList.data;
        const res = this.roles;
        let availableRole = [];
        let listOfRoleName = [];
        let selectedApp;
        let selectedAppId: string;
        appListLc = filter(appListLc, (item) => {
          if (res.data.filter((role) => role.appId === item.id)[0]) {
            return true;
          }
        });
        if (isEmpty(appListLc)) {
          this.dataService.isNavigationLoad.next(true);
          this.router.navigate(['/access-denied']);
        } else {
          if (!localStorage.getItem('selectedApp')) {
            selectedApp = appListLc[0];
            selectedAppId = selectedApp.id;
            localStorage.setItem('selectedApp', selectedApp.id);
            localStorage.setItem('selectedAppName', selectedApp.name);
          } else {
            selectedAppId = localStorage.getItem('selectedApp');
          }
          this.sharedService.selectedAppObjr.next(selectedApp);
          if (selectedAppId) {
            availableRole = filter(res.data, (item) => {
              return (
                item.appId === selectedAppId || item.name === PLATFORM_ADMIN
              );
            });
          }
          listOfRoleName = map(availableRole, 'name');
          let selectedRole = this.appStorage.getRole();
          if (isEmpty(availableRole)) {
            this.router.navigate(['/access-denied']);
          }
          selectedRole = selectedRole ? selectedRole : availableRole[0];
          this.appStorage.setAppRoles(listOfRoleName);
          this.appStorage.setRole(JSON.stringify(selectedRole));

          const singleRole: any = find(availableRole, {
            name: selectedRole.name,
          });
          const permission = map(singleRole.permissions, 'perm');
          this.permissionData = { data: permission };
          this.permissionServ.setPermission(permission);
          this.permissionServ.notifyPermission.next(permission);
          return {
            roles: availableRole,
            apps: appListLc,
            permissions: permission,
          };
        }
      } else {
        this.router.navigate(['/access-denied']);
      }
    } catch (err) {
      this.router.navigate(['/access-denied']);
    }
  }

  getAppConfig(): any {
    return new Promise((resol, reject) => {
      this.appService.getAppConfig().subscribe((res: any) => {
        if (res.constants) {
          let extensions = {};
          if (res.data[0].extensions) {
            extensions = res.data[0].extensions;
          }
          res.constants.extensions = extensions;
          this.appConfigService.setAppConfigData(res.constants);
          this.sharedService.setAppConfig(res.constants);
        }
        let readStorageConfig = res.data[0].storages.filter((element) => {
          return element.scope === SAS_SCOPES.readOnly;
        });

        const editStorageConfig = res.data[0].storages.filter((element) => {
          return element.scope === SAS_SCOPES.editOnly;
        });

        readStorageConfig =
          readStorageConfig && readStorageConfig.length
            ? readStorageConfig
            : res.data[0].storages;

        let sasToken = '';
        let editSasToken = '';
        let storageAccount = '';
        let containerName = '';

        if (readStorageConfig && readStorageConfig[0]) {
          sasToken = this.commonService.decrypt(
            readStorageConfig[0].params.sas
          );
          storageAccount = readStorageConfig[0].params.storageAccount;
        }
        this.appStorage.setSAS(sasToken);
        this.appStorage.setDatatypes(res.constants.dataType);
        if (editStorageConfig && editStorageConfig[0]) {
          editSasToken = this.commonService.decrypt(
            editStorageConfig[0].params.sas
          );
          containerName = editStorageConfig[0].params.containerName;
        }
        this.appStorage.setEditSAS(editSasToken);
        this.appStorage.setStorageAccount(storageAccount);
        this.appStorage.setStorageContainer(containerName);
        this.appStorage.setStorageFileShare(editStorageConfig[0].params.shareName);
        this.appStorage.setFileShareRootDirectory(editStorageConfig[0].params.shareRootDirectory);
        resol(res);
      });
    });
  }
}
