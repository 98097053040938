import {
  Component,
  OnInit,
  DoCheck,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataService } from 'src/app/core/services/data.service';
import { ReportsService } from '@pages/reports/services/reports-service.service';
import { btnTypes } from '../../enum/button';
import * as moment from 'moment';
import { AuditConfigInfo } from '@shared/components/audit-filter/audit-filter.component.types';
import {
  AUDIT_FILTER_LABELS,
  REPORTS_CONSTANTS,
} from '@pages/reports/reports-constant';
import { DAYS } from '@shared/shared.constant';

@Component({
  selector: 'gdx-audit-filter',
  templateUrl: './audit-filter.component.html',
})
export class AuditFilterComponent implements OnInit, DoCheck {
  @Output() expanded = new EventEmitter<boolean>();
  @Output() onApplyFilter = new EventEmitter<object>();
  @Input() auditClass = '';
  rolesData: Array<any> = [];
  userList: Array<any> = [];
  categories: Array<any> = [];
  events: Array<any> = [];
  actions: Array<any> = [];
  table = [];
  apps = [];
  labels = AUDIT_FILTER_LABELS;
  status = [{ name: 'success' }, { name: 'failed' }];
  isAdvanced = false;
  filterOpen = false;
  isShow = true;
  expand = 'assets/images/filter.svg';
  collapse = 'assets/images/cross.svg';
  advanced = '(+)';
  notAdvanced = '(-)';
  public btnType = btnTypes;
  public auditFilter: FormGroup = new FormGroup({});
  allSelectPlaceHolder = 'Select';
  maxDate: any = new Date();
  errorMessage = '';
  isAppDisabled = false;

  constructor(
    public formBuilder: FormBuilder,
    private dataService: DataService,
    private reportsService: ReportsService
  ) {}

  auditForm(): void {
    this.auditFilter = this.formBuilder.group({
      selectedPeriod: [
        [
          moment(new Date(new Date().setHours(0, 0, 0)))
            .subtract(REPORTS_CONSTANTS.default_period, DAYS)
            .toDate(),
          new Date(),
        ],
      ],
      selectedUser: '',
      selectedRole: '',
      selectedEvent: '',
      selectedCategory: '',
      selectedStatus: '',
      selectedAction: '',
      selectedTable: '',
    });
  }

  ngOnInit(): void {
    this.getAuditConfigData();
    this.auditForm();
  }

  getAuditConfigData(): void {
    this.reportsService
      .getAuditConfigData()
      .subscribe((result: AuditConfigInfo) => {
        if (result && result.data) {
          this.rolesData = result.data.roleList;
          this.categories = result.data.categoryList;
          this.events = result.data.eventList;
          this.actions = result.data.actionList;
          this.userList = result.data.userList;
        }
      });
  }

  ngDoCheck(): void {
    this.dataService.onHide.subscribe((result) => {
      this.isShow = result;
    });
  }

  expandCollapse = (): any => {
    this.filterOpen = !this.filterOpen;
    this.expanded.emit(this.filterOpen);
  };

  expandCollapseAdvanced = (): void => {
    this.isAdvanced = !this.isAdvanced;
  };
  applyFilter(event: any): void {
    const filterData = this.auditFilter.value;
    const filterdValue = {
      startDate: new Date(),
      endDate: new Date(),
      appId: [] as any,
      user: [] as any,
      role: [] as any,
      event: [] as any,
      category: [] as any,
      status: '',
      action: [] as any,
      table: [] as any,
      reportType: 0,
    };
    Object.keys(filterData).map((item: string) => {
      const key = item.replace('selected', '');
      this.check(item, key, filterdValue, filterData);
    });
    this.dateCheck(filterdValue);
    this.onApplyFilter.emit({ filterdValue, event });
    this.expandCollapse();
  }

  dateCheck(filterdValue: {
    startDate: Date;
    endDate: Date;
    appId: any;
    user: any;
    role: any;
    event: any;
    category: any;
    status: string;
    action: any;
    table: any;
    reportType: number;
  }): void {
    if (this.auditFilter.controls.selectedPeriod.value !== null) {
      if (
        this.auditFilter.controls.selectedPeriod &&
        this.auditFilter.controls.selectedPeriod.value[0] &&
        this.auditFilter.controls.selectedPeriod.value[1]
      ) {
        const startDate = moment(
          this.auditFilter.controls.selectedPeriod.value[0]
        );
        const endDate = moment(
          this.auditFilter.controls.selectedPeriod.value[1]
        );
        const diff = endDate.diff(startDate, DAYS);
        if (diff === 0) {
          filterdValue.startDate = moment(
            new Date(filterdValue.startDate).setHours(0, 0, 0)
          ).toDate();
          filterdValue.endDate = moment(
            new Date(filterdValue.startDate).setHours(23, 59, 59)
          ).toDate();
        }
      }
    } else {
      delete filterdValue[REPORTS_CONSTANTS.start_date];
      delete filterdValue[REPORTS_CONSTANTS.end_date];
    }
  }

  check(
    item: string,
    key: string,
    filterdValue: {
      startDate: Date;
      endDate: Date;
      appId: any;
      user: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    },
    filterData: any
  ): void {
    if (item.includes(key)) {
      if (key === REPORTS_CONSTANTS.period) {
        this.periodCheck(filterdValue, filterData);
      } else if (key === REPORTS_CONSTANTS.user) {
        this.usercheck(filterData, filterdValue);
      } else if (key === REPORTS_CONSTANTS.status) {
        this.statusCheck(filterData, item, filterdValue);
      } else if (key === REPORTS_CONSTANTS.category) {
        this.categoryCheck(filterData, item, filterdValue);
      } else if (key === REPORTS_CONSTANTS.role) {
        this.roleCheck(filterData, item, filterdValue);
      } else if (key === REPORTS_CONSTANTS.event) {
        this.eventCheck(filterData, item, filterdValue);
      } else if (key === REPORTS_CONSTANTS.action) {
        this.actionCheck(filterData, item, filterdValue);
      } else if (key === REPORTS_CONSTANTS.table) {
        this.tableCheck(filterData, item, filterdValue);
      } else {
      }
    }
  }

  tableCheck(
    filterData: any,
    item: string,
    filterdValue: {
      startDate: Date;
      endDate: Date;
      appId: any;
      user: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    }
  ): void {
    if (filterData[item] && filterData[item].length) {
      filterdValue.table = filterData[item].length
        ? filterData[item].map((item5: any) => item5.name)
        : [];
    }
  }

  actionCheck(
    filterData: any,
    item: string,
    filterdValue: {
      startDate: Date;
      endDate: Date;
      appId: any;
      user: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    }
  ): void {
    if (filterData[item] && filterData[item].length) {
      filterdValue.action = filterData[item].length
        ? filterData[item].map((item4: any) => item4.name)
        : [];
    }
  }

  eventCheck(
    filterData: any,
    item: string,
    filterdValue: {
      startDate: Date;
      endDate: Date;
      appId: any;
      user: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    }
  ): void {
    if (filterData[item] && filterData[item].length) {
      filterdValue.event = filterData[item].length
        ? filterData[item].map((item3: any) => item3.name)
        : [];
    }
  }

  roleCheck(
    filterData: any,
    item: string,
    filterdValue: {
      startDate: Date;
      endDate: Date;
      appId: any;
      user: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    }
  ): void {
    if (filterData[item] && filterData[item].length) {
      filterdValue.role = filterData[item].length
        ? filterData[item].map((item2: any) => item2.name)
        : [];
    }
  }

  categoryCheck(
    filterData: any,
    item: string,
    filterdValue: {
      startDate: Date;
      endDate: Date;
      appId: any;
      user: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    }
  ): void {
    if (filterData[item] && filterData[item].length) {
      filterdValue.category = filterData[item].length
        ? filterData[item].map((item1: any) => item1.name)
        : [];
    }
  }

  statusCheck(
    filterData: any,
    item: string,
    filterdValue: {
      startDate: Date;
      endDate: Date;
      appId: any;
      user: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    }
  ): void {
    if (filterData[item] && filterData[item].name) {
      filterdValue.status = filterData[item].name ? filterData[item].name : '';
    }
  }

  usercheck(
    filterData: any,
    filterdValue: {
      startDate: Date;
      endDate: Date;
      appId: any;
      user: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    }
  ): void {
    if (filterData.selectedUser.length > 0) {
      filterdValue.user = filterData.selectedUser.map(
        (element) => element.name
      );
    }
  }

  periodCheck(
    filterdValue: {
      startDate: Date;
      endDate: Date;
      appId: any;
      user: any;
      role: any;
      event: any;
      category: any;
      status: string;
      action: any;
      table: any;
      reportType: number;
    },
    filterData: any
  ): void {
    if (this.auditFilter.controls.selectedPeriod.value !== null) {
      filterdValue.startDate = filterData.selectedPeriod[0]
        ? filterData.selectedPeriod[0]
        : '';
      filterdValue.endDate = filterData.selectedPeriod[1]
        ? filterData.selectedPeriod[1]
        : moment(
            new Date(filterdValue.startDate).setHours(11, 59, 59)
          ).toDate();
    } else {
      delete filterdValue[REPORTS_CONSTANTS.start_date];
      delete filterdValue[REPORTS_CONSTANTS.end_date];
    }
  }

  resetFilter(): void {
    this.auditForm();
    this.auditFilter.get('selectedPeriod').setValue(null);
  }
}
