import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { newTrie, ShiroTrie } from 'shiro-trie';
@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  rolePermission: ShiroTrie = newTrie();
  public notifyPermission = new Subject();
  setPermission(data: any): void {
    this.rolePermission = newTrie();
    this.rolePermission.add(data);
  }

  getPermission(): any {
    return this.rolePermission.get();
  }

  checkPermission(key: string): any {
    return this.rolePermission.check(key);
  }

  getPermissionOnKey(key: string): any {
    if (key) {
      if (key.includes('||')) {
        const keys = key.split('||');
        let resultKeys: any = [];
        keys.forEach((item) => {
          resultKeys = [
            ...resultKeys,
            ...this.rolePermission.permissions(item?.trim()),
          ];
        });
        return resultKeys;
      } else {
        return this.rolePermission.permissions(key);
      }
    } else {
      return [];
    }
  }
}
