<div class="snackui-bar-wrapper" [ngClass]="data.snackType">
  <div class="header">
    <div class="type-text">
      {{ data.snackType }}
    </div>
  </div>
  <div class="data-message">
    <span class="txt-message">{{ data.message }}</span>
  </div>
  <div class="dismiss">
    <button
      gdxIconButton
      variant="flat"
      icon="x"
      (click)="snackBarRef.dismiss()"
    ></button>
  </div>
</div>
