import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { url } from '../../../app.config';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class SourceService {
  baseURL: string = environment.entryAPI;
  appId: string = localStorage.getItem('selectedApp');
  saveSourceurl = url.saveSource;
  deleteFilterUrl = url.deleteFilter;
  userId;
  constructor(private http: HttpClient, private authService: MsalService) {
    const token: any = this.authService.getAccount();
    this.userId = token.userName;
  }
  private filterItem = {};
  private sortandfilter = {};
  // get all sources for filter
  getAllfilter(): any {
    return this.http.get(this.baseURL + url.allSource_api + this.appId);
  }

  getImageCount(data): any {
    return this.http.get(this.baseURL + url.getImageCount + '/' + this.appId, {
      params: { id: data.id },
    });
  }
  deleteSource(id): any {
    return this.http.delete(this.baseURL + url.deleteSource + '/' + id);
  }
  getSavedFilterDataByID(id): any {
    return this.http.get(
      environment.entryAPI + url.get_saved_filters_url + this.appId,
      {
        params: {
          createdBy: this.userId,
          filterId: id,
        },
      }
    );
  }
  saveSource(Sourcedata): any {
    Sourcedata.appId = localStorage.getItem('selectedApp');
    return this.http.post(
      environment.entryAPI + this.saveSourceurl,
      Sourcedata,
      {
        observe: 'response',
      }
    );
  }
  saveSourceForRecHierarchy(Sourcedata): any {
    return this.http.post(
      environment.entryAPI + this.saveSourceurl,
      Sourcedata
    );
  }

  getAllHierarchyTypes(): any {
    return this.http.get(this.baseURL + url.getHierarchyTypes);
  }
}
