import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'gdx-text-area',
  templateUrl: './text-area.component.html',
  styles: [],
})
export class TextAreaComponent {
  @Input('type') type = 'text';
  @Input('id') id: any;
  @Input('name') name = '';
  @Input('control') control = '';
  @Input('placeholder') placeholder = '';
  @Input('class') class = '';
  @Input('rows') rows: any;
  @Input('cols') cols: any;
  @Input('disabled') disabled = false;
  @Input('readonly') readonly = false;
  @Input('parentForm') parentForm: any;
  @Output('onChange') onChange = new EventEmitter();
  @Output('onKeyPress') onKeyPress = new EventEmitter();
  @Output('onKeyUp') onKeyUp = new EventEmitter();
  @Output('onKeyDown') onKeyDown = new EventEmitter();
  @Output('onInput') onInput = new EventEmitter();
  @Output('onBlur') onBlur = new EventEmitter();
  @ViewChild('content')
  content!: ElementRef;

  valueChange(event: any): void {
    this.onChange.emit(event);
  }

  keyPress(event: any): void {
    this.onKeyPress.emit(event);
  }

  keyUp(event: any): void {
    this.onKeyUp.emit(event);
  }

  inputChange(event: any): void {
    this.onInput.emit(event);
  }

  keyDown(event: any): void {
    this.onKeyDown.emit(event);
  }
  blur(event: any): void {
    this.onBlur.emit(event);
  }
}
