import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { ICard } from './card-display.types';

@Component({
  selector: 'gdx-card-display',
  templateUrl: './card-display.component.html',
  styles: [],
})
export class CardDisplayComponent implements OnInit, OnChanges {
  @Input('cardValue') cardValue: ICard[] | undefined;
  multiProgress: any = [];
  constructor(private ref: ChangeDetectorRef) {}
  class1 = '';
  head = '';
  title = '';
  value = 0;
  percentage = '';
  barShow = false;
  style1 = '';
  style2 = '';
  style3 = '';
  footer = '';
  toolTip = '';
  @Input('id') id = '';
  oldValue = 0;
  public currentVal = 0;
  ngOnInit(): void {
    if (this.cardValue) {
      this.head = this.cardValue[0].head || '';
      this.title = this.cardValue[0].title;
      this.value = this.cardValue[0].value;
      this.oldValue = this.cardValue[0].oldValue || 0;
      this.percentage = this.cardValue[0].percentage || '';
      this.barShow = false;
      this.barShow = this.cardValue[0].barVisible;
      this.footer = this.cardValue[0].footer || '';
      if (this.cardValue[0].progressDetail) {
        this.multiProgress = this.cardValue[0].progressDetail;
      }
    }

    setTimeout(() => {
      this.animateValue(
        document.getElementById(this.id),
        this.oldValue,
        this.value,
        1000
      );
    }, 0);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cardValue?.currentValue) {
      this.value = changes.cardValue.currentValue[0].value;
      this.oldValue = changes.cardValue.currentValue[0].oldValue;
      this.percentage = changes.cardValue.currentValue[0].percentage;
      this.multiProgress = changes.cardValue.currentValue[0].progressDetail;
      this.ref.detectChanges();
      setTimeout(() => {
        this.animateValue(
          document.getElementById(this.id),
          this.oldValue,
          this.value,
          1000
        );
      }, 0);
    }
  }

  animateValue(obj: any, start: number, end: number, duration: number): void {
    if (obj) {
      let startTimestamp: any = null;
      const step = (timestamp: any) => {
        if (!startTimestamp) {
          startTimestamp = timestamp;
        }
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        if (obj.innerHTML || obj.innerHTML === 0) {
          obj.innerHTML = Math.floor(progress * (end - start) + start);
        }
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    }
  }
}
