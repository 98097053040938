<div class="notification-wrapper overflow-hidden">
  <span class="inline-block p-0.5 text-white font-bold text-sm mt-3 mr-2">{{
    appCode
  }}</span>
  <div
    class="bell-img float-right inline-block text-center cursor-pointer w-8 pt-3 pl-2 pr-2"
    (click)="showblock()"
    [ngClass]="isShow ? 'close' : ''"
    (gdxClickOutside)="closeblock()"
  >
    <img class="bell-icon mt-1" [src]="imageUrl" alt="bellIcon" />
    <div
      class="notification-content absolute z-50 h-screen border-2 border-borderColor-50 w-96 -right-1 top-11 bg-white"
      *ngIf="isShow"
    >
      <div
        class="pt-5 pl-5 pr-3 overflow-hidden border-b-2 border-borderColor-50"
      >
        <ul class="w-full">
          <li
            class="float-left font-bold text-red-500 tracking-wider border-solid border-b-4 border-red-500 text-sm pb-1"
          >
            Applications
          </li>
        </ul>
      </div>
      <div class="pt-5 truncate">
        <table class="table-auto w-full">
          <thead>
            <tr class="text-sm">
              <th class="p-3 pl-5 w-20">Apps</th>
              <th class="p-3 pl-5 flex justify-center w-64">Partner Site</th>
            </tr>
          </thead>
          <tbody *ngIf="fullAppList">
            <tr
              class="bg-emerald-200 text-sm font-bold text-primary-920 hover:bg-primary-930 hover:text-primary-940 cursor-pointer focus:outline-none"
              *ngFor="let listdata of fullAppList; let i = index"
              [ngClass]="{
                'text-primary-940 bg-primary-930': listdata.appCode === appCode
              }"
              (click)="onAppchange(listdata)"
            >
              <td class="p-3 pl-5">
                <div [title]="listdata.appCode" class="w-20 truncate">
                  {{ listdata.appCode }}
                </div>
              </td>
              <td (click)="goToLink(listdata.siteUrl)">
                <div class="cursor-pointer p-3 pl-5 w-64 truncate">
                  {{ listdata.siteUrl }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
