import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gdx-checkbox',
  templateUrl: './checkbox.component.html',
  styles: [],
})
export class CheckboxComponent {
  @Input('parentForm') parentForm: any;
  @Input('name') name: any = '';
  @Input('id') id: any = '';
  @Input('control') control: any = '';
  @Input('checked') checked: any = false;
  @Input('label') label: any;
  @Output('onChange') onChange = new EventEmitter();

  valueChange(event: any): any {
    this.onChange.emit(event);
  }
}
