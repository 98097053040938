<div
  class="flex_item_right mb-24"
  [ngClass]="isUploadScreen ? 'border-upload-screen' : ''"
>
  <div class="header" [ngClass]="isUploadScreen ? 'classify-center' : ''">
    <span *ngIf="isClassify || isPassFail">Classify </span>
    <span *ngIf="isUpdateLineNo">Update Line no </span>
    <span *ngIf="isCreateTotalDataSet">Create Dataset</span>
    <div class="icon-close" style="text-align: end" (click)="onClose()">
      <mat-icon class="close-icon" *ngIf="!isUploadScreen">close</mat-icon>
    </div>
  </div>
  <div *ngIf="isClassify">
    <div class="tab-header">
      <button
        class="btn grading-label"
        [ngClass]="isGradingSelected ? 'selectedPanelHeader' : ''"
        (click)="onGradingSelected()"
        *ngIf="isClassify"
      >
        Grading
      </button>
      <button
        class="btn fm-label"
        [ngClass]="!isGradingSelected ? 'selectedPanelHeader' : ''"
        (click)="onFmSelected()"
        *ngIf="!isPassFail"
        style="display: none"
      >
        FM Measure
      </button>
    </div>
    <div
      *ngIf="isClassify"
      class="grading-panel"
      [ngClass]="!isGradingSelected ? 'grading-panel-hide' : ''"
    >
      <mat-radio-group
        [(ngModel)]="selectedGradeValue"
        (change)="radioChange($event)"
      >
        <div *ngIf="isClassify">
          <div *ngFor="let value of gradingList">
            <mat-radio-button [value]="value.value"
              >{{ value.value }}
              <mat-icon style="display: none">check</mat-icon>
              <span style="display: none" class="grade-span">{{
                value.value
              }}</span>
            </mat-radio-button>
            <div *ngIf="value.value === 'Fail'">
              <mat-form-field
                [ngClass]="isFail ? 'fail-reason-show' : 'fail-reason-hide'"
                [floatLabel]="'never'"
              >
                <mat-placeholder class="mat-placeholder"
                  >Failed Reason</mat-placeholder
                >
                <mat-select [(ngModel)]="selectedFailedReason">
                  <mat-option
                    *ngFor="let reason of failedReason; let i = index"
                    [value]="reason.value"
                  >
                    <mat-checkbox
                      [checked]="selected === i"
                      (change)="getFailedReason(reason.value); selected = i"
                      >{{ reason.value }}</mat-checkbox
                    >&nbsp; &nbsp; &nbsp;
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-radio-group>
    </div>
  </div>
  <div *ngIf="isPassFail">
    <div
      *ngIf="isPassFail"
      class="grading-panel"
      [ngClass]="!isGradingSelected ? 'grading-panel-hide' : ''"
    >
      <mat-radio-group
        [(ngModel)]="selectedGradeValue"
        (change)="radioChange($event)"
      >
        <div *ngIf="isPassFail">
          <mat-radio-button value="Pass"
            >Pass
            <mat-icon style="display: none">check</mat-icon>
            <span style="display: none" class="grade-span">Pass</span>
          </mat-radio-button>

          <mat-radio-button value="Fail" class="fail-radio-button">
            Fail
            <mat-icon style="display: none">close</mat-icon>
            <span style="display: none" class="grade-span">Fail</span>
          </mat-radio-button>
          <mat-form-field
            [ngClass]="isFail ? 'fail-reason-show' : 'fail-reason-hide'"
            [floatLabel]="'never'"
          >
            <mat-placeholder class="mat-placeholder"
              >Failed Reason</mat-placeholder
            >
            <mat-select [(ngModel)]="selectedFailedReason">
              <mat-option
                *ngFor="let reason of failedReason; let i = index"
                [value]="reason.value"
              >
                <mat-checkbox
                  [checked]="selected === i"
                  (change)="getFailedReason(reason.value); selected = i"
                  >{{ reason.value }}</mat-checkbox
                >&nbsp; &nbsp; &nbsp;
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-radio-group>
    </div>
  </div>
  <div *ngIf="isUpdateLineNo">
    <div class="radio-group-wrapper">
      <mat-radio-group>
        <mat-radio-button
          *ngFor="let line of lineArray; let i = index"
          [value]="line.id"
          (change)="onLineChange($event, line.id)"
        >
          {{ line.name }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div *ngIf="isCreateTotalDataSet">
    <div class="form-grp">
      <label class="important"><b> Name </b></label>
      <gdx-input-box
        class="form-control"
        (onKeyPress)="omit_special_char($event)"
        [type]="'text'"
        [id]="'dataset-name'"
        [name]="'dataset-name'"
        [placeholder]="'Name..'"
        [control]="'datasetName'"
        [parentForm]="gradingFormGroup"
        (onPaste)="onPaste($event)"
        [autoFocus]="true"
      >
      </gdx-input-box>
      <span class="name-loader float-right" *ngIf="nameLoading"></span>
      <label *ngIf="isPresent" class="error" for="name"
        >* Master Dataset name already exists</label
      >
      <label class="purpose"><b> Purpose </b></label>
      <gdx-input-box
        class="form-control"
        [type]="'text'"
        [id]="'dataset-purpose'"
        [name]="'dataset-purpose'"
        [placeholder]="'Type Purpose..'"
        [control]="'datasetPurpose'"
        [parentForm]="gradingFormGroup"
      >
      </gdx-input-box>

      <div class="breadcrumbbar-btn" *ngIf="isExternalStorageRequired">
        <mat-form-field class="connection-dropdown" [floatLabel]="'never'">
          <mat-placeholder class="mat-placeholder">Connection</mat-placeholder>
          <mat-select
            panelClass="custom-ht"
            (selectionChange)="getConnectionSelected($event)"
            [(ngModel)]="selectedConectionValue"
          >
            <mat-option
              *ngFor="let connectionObj of allConnections"
              [value]="connectionObj"
            >
              {{ connectionObj.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="selected-done">
    <div *ngIf="isClassify">
      <div class="comment-wrapper">
        <mat-icon class="comments-icon">comment</mat-icon>
        <span class="comments-label">Make a comment</span>
      </div>
      <textarea
        class="comments-txt-area"
        (change)="onTextAreaChange($event.target.value)"
        [(ngModel)]="comments"
      >
      </textarea>
      <p *ngIf="selectedGradeValue" style="font-size: 11px; margin-bottom: 2px">
        <b>Selected Items:</b>
      </p>
      <div style="display: flex">
        <p
          class="selected-grade-label"
          *ngIf="selectedGradeValue"
          [ngClass]="
            selectedGradeValue == 'Borderline'
              ? 'darkorange'
              : selectedGradeValue == 'Fail'
              ? 'red'
              : selectedGradeValue == 'Pass'
              ? 'green'
              : 'grey'
          "
        >
          {{ selectedGradeValue }}
        </p>
        <p class="selected-fm-label" *ngIf="selectedFMMeasure">
          {{ selectedFMMeasure }}
        </p>
      </div>
    </div>
    <div class="done-btn-wrapper">
      <gdx-button
        class="button button-success done-btn px-5 py-2 rounded-lg text-white"
        [id]="'submit-button'"
        [text]="'Submit'"
        [tooltip]="'Submit'"
        [disabled]="isCreateTotalDataSet && gradingFormGroup.invalid"
        [icon]="''"
        [iconHover]="''"
        (clicked)="updateMetada()"
      >
      </gdx-button>
    </div>
  </div>
</div>
