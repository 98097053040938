import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { AES, enc } from 'crypto-js';
import { SharedService } from './shared.service';
import { HIDE_HEADER_FOOTER_URLS } from '@core/permission/permissions.type';
import { filter } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { AppConfigService } from '@core-services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  role!: BehaviorSubject<any>;
  encrKey = '';
  constructor(
    private shared: SharedService,
    private appConfigService: AppConfigService
  ) {
    this.role = new BehaviorSubject({
      noRole: false,
    });
  }

  encrypt(msg: any): string {
    this.encrKey = this.appConfigService.getEncryptionKey();
    const encrypted = AES.encrypt(msg, this.encrKey);
    const transitmessage = encrypted.toString();

    return transitmessage;
  }
  decrypt(msg: any): string {
    this.encrKey = this.appConfigService.getEncryptionKey();
    const encrypted = AES.decrypt(msg, this.encrKey);
    const transitmessage = encrypted.toString(enc.Utf8);
    return transitmessage;
  }
  checkPermission(action: any, permission: any): boolean {
    let userpermission;
    let hasPermission;
    this.shared.userPermission.subscribe((data) => (userpermission = data));
    hasPermission = filter(userpermission, (item: any) => {
      return item.name.includes('Manage' + action);
    })[0];
    if (hasPermission) {
      return true;
    } else {
      hasPermission = filter(userpermission, (item: any) => {
        return item.name.includes(permission + action);
      })[0];
      if (hasPermission) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  headerFooterVisibility(router: { url }): boolean {
    const url = router.url;
    const splitArr = url.split('/');
    const arrUrl = HIDE_HEADER_FOOTER_URLS;
    let isHide = true;
    for (const i of arrUrl) {
      if (i === splitArr[1]) {
        isHide = false;
        break;
      }
    }
    return isHide;
  }
}
