// THis file will be used to check the route is access by user or not

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionsService } from '@core/permission/permissions.service';
import { rolePermission } from '@core/permission/permissions.type';
import { AISI_ROLES } from '@shared/shared.constant';
import { AppStorageService } from '@core-services/app-storage.service';
@Injectable()
export class AuthGuardService {
  currentUserRole: string;
  public pages = JSON.parse(JSON.stringify(rolePermission.pages));
  private currentParamData: any;
  constructor(
    private router: Router,
    private permissionServ: PermissionsService,
    private appStorageService: AppStorageService
  ) {
    this.permissionServ.notifyPermission.subscribe((paremData) => {
      const permission = this.checkPermission(this.currentParamData.data.key);
      if (!this.currentParamData.data.key || permission) {
        return true;
      } else {
        if (this.appStorageService.getAppRoles().length === 0) {
          this.router.navigate(['/access-denied']);
          return false;
        } else {
          if (
            JSON.parse(localStorage.getItem('selectedRole')).meta ===
            AISI_ROLES.PLATFORM_ADMIN
          ) {
            this.router.navigate(['/application/app-admin']);
          } else {
            this.router.navigate(['/']);
          }
        }
      }
    });
  }
  // check for route permission
  async canActivate(params, state): Promise<any> {
    this.currentParamData = params;
    return true;
  }
  checkPermission(key: string): boolean {
    return this.permissionServ.getPermissionOnKey(this.pages[key]).length > 0;
  }
}
