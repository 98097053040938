import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { url } from '@app/app.config';
import { AppStorageService } from '@core-services/app-storage.service';
import { AppConfigService } from '@core-services/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class ImagemetadataService {
  image_metadata_url = url.image_metadata_url;
  update_meta_data_url = url.update_meta_data_url;
  send_for_inspection_VI_url = url.send_for_inspection_VI_url;
  get_user_pref = url.get_user_pref;
  save_user_pref = url.save_user_pref;
  appid = localStorage.getItem('selectedApp');

  constructor(
    private http: HttpClient,
    private appStorageService: AppStorageService,
    private appConfigService: AppConfigService
  ) {}

  getImageMetadaData(imageId: string): any {
    return this.http.get(
      environment.entryAPI +
        this.image_metadata_url +
        this.appid +
        '/' +
        imageId
    );
  }
  updateMetadata(data): any {
    this.appid = this.appStorageService.getApp();
    return this.http.put(
      environment.entryAPI + this.update_meta_data_url + this.appid,
      data,
      { observe: 'response' }
    );
  }
  sendForInspection(data): any {
    this.appid = this.appStorageService.getApp();
    return this.http.put(
      environment.entryAPI + this.update_meta_data_url + this.appid,
      data,
      { observe: 'response' }
    );
  }
  sendEmailToVI(): any {
    const sendForInspectionViType = this.appConfigService.getInspectionMailType();
    const sendForInspectionViMailId = this.appConfigService.getInspectionMailId();
    return this.http.get(
      environment.entryAPI +
        this.send_for_inspection_VI_url +
        '&type=' +
        sendForInspectionViType +
        '&adGroup=' +
        sendForInspectionViMailId
    );
  }

  getUserPreference(): any {
    this.appid = this.appStorageService.getApp();
    return this.http.get(
      environment.entryAPI + this.get_user_pref + this.appid
    );
  }
  saveUserPreference(data, appid): any {
    return this.http.post(
      environment.entryAPI + this.save_user_pref + appid,
      data,
      {
        observe: 'response',
      }
    );
  }
  getFilteredVideoData(data: any = {}): any {
    return this.http.get(
      environment.entryAPI +
        'videos/' +
        this.appid +
        '?data=' +
        encodeURIComponent(JSON.stringify(data))
    );
  }

  createMasterDataset(data: any): any {
    return this.http.post(environment.entryAPI + 'datasets/', data);
  }
}
