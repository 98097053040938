import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, sortBy, map, isEmpty } from 'lodash';
import { Observable, of, Subscription } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import {
  INavigation,
  INavList,
} from '../navigation/navigation.component.types';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  rolePermission,
  navList,
  navListSettings,
} from '@core/permission/permissions.type';
import { PermissionsService } from '@core/permission/permissions.service';
import { UserService } from '@core/services/user.service';
import { MsalService } from '@azure/msal-angular';
import { PLATFORM_ADMIN } from './header.component.types';
import { labels } from '../../../../app/app.config.js';
import { AppService } from '@core/services/app.service';
import { CommonService } from '@core/services/common.service';
import { ISearchResultEntity } from '@shared/interfaces/catalog';
import { AppRoleReq } from '@shared/interfaces/app.role.req';
import { AppStorageService } from '@core/services/app-storage.service';
import { DialogService } from 'primeng/dynamicdialog';
import { mainTitle } from '@shared/shared.message';
import { PLATFORM_LOGO_NAMES, APPNAME_KEYS } from '@shared/shared.constant';
import { SharedService } from '@core/services/shared.service';

@Component({
  selector: 'gdx-header',
  templateUrl: './header.component.html',
  providers: [DialogService],
})
export class HeaderComponent implements OnInit, OnDestroy {
  constructor(
    private dataService: DataService,
    private router: Router,
    public formBuilder: FormBuilder,
    private permissionServ: PermissionsService,
    private userService: UserService,
    private authService: MsalService,
    private appService: AppService,
    private appStorage: AppStorageService,
    public dialogService: DialogService,
    private commonService: CommonService,
    private sharedService: SharedService
  ) {
    this.dataServices = dataService;

    // Settings Navigation Component
    of(this.getNavSettingsList()).subscribe((nav) => {
      this.navListSettings = nav;
    });

    this.dataServices.onHide.subscribe((result) => {
      this.isHide = result;
    });
    // highlight subnav
    router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.visible = this.commonService.headerFooterVisibility(this.router);
      }
    });
  }
  @Input() visible = true;
  selectedAppName: any;
  public userMenu = {
    isOpen: false,
  };
  public headerGrp: FormGroup = new FormGroup({});
  title = mainTitle;
  roleList: any = [];
  actualRoleList: any = [];
  public pages = JSON.parse(JSON.stringify(rolePermission.pages));
  public appList: any = [];
  public labels = labels;
  filterAppAccess = '';
  navList: INavigation = {
    design: {
      styleElmentList: '',
      styleElementAnchor: '',
      styleActiveLink: '',
    },
    navList: [],
  };

  navListSettings: INavigation = {
    design: {
      styleElmentList: '',
      styleElementAnchor: '',
      styleActiveLink: '',
    },
    navList: [],
  };

  childNavList: INavigation = {
    design: {
      styleElmentList: '',
      styleElementAnchor: '',
      styleActiveLink: '',
    },
    navList: [],
  };

  dataServices: DataService;
  isHide = true;
  headerSubscription!: Subscription;
  selectedApp: any;
  role!: string;
  name = 'Demo';
  userLogout = false;
  showRole = false;
  logoName: string = PLATFORM_LOGO_NAMES.SIP;

  ngOnInit(): void {
    this.headerGrpForm();
    this.userService.updateData().subscribe((data) => {
      this.userService.getAppConfig().then((res) => {
        this.getAppList();
      });
    });
    this.sharedService.tntReload.subscribe((data) => {
      this.childNavList.navList = [];
      this.childNavList = Object.assign({}, this.childNavList);
    });
  }

  checkAppRole(): void {
    this.filterAppAccess = this.roleList.filter(
      (item: any) =>
        item.toUpperCase().includes('APPACCESS') ||
        item.toUpperCase().includes('DATAACCESS')
    );
  }
  selectApp(e: any): void {
    const appNameValue = this.appList.filter(
      (element) => element.id === e.value
    );
    localStorage.setItem('selectedAppName', appNameValue[0].name);
    localStorage.setItem('selectedApp', e.value);
    localStorage.removeItem('app-roles');
    localStorage.removeItem('selectedRole');

    location.reload();
  }
  async getAppList(): Promise<any> {
    try {
      const req = {};
      const appList = this.userService.appList;
      if (appList && appList.data && appList.data.length) {
        this.dataService.isAppLoaded(true);
        this.appList = await appList.data;
        const res = this.userService.roles;
        let availableRole = [];
        let listOfRoleName = [];
        this.appList = filter(this.appList, (item) => {
          if (res.data.filter((role) => role.appId === item.id)[0]) {
            return true;
          }
        });
        if (isEmpty(this.appList)) {
          this.router.navigate(['/access-denied']);
        } else {
          if (!localStorage.getItem('selectedApp')) {
            const selectedApp: any = this.appList[0];
            this.selectedApp = selectedApp.id;
            localStorage.setItem('selectedApp', selectedApp.id);
            localStorage.setItem('selectedAppName', selectedApp.name);
          } else {
            this.selectedApp = localStorage.getItem('selectedApp');
          }
          if (this.selectedApp) {
            availableRole = filter(res.data, (item) => {
              return (
                item.appId === this.selectedApp || item.name === PLATFORM_ADMIN
              );
            });
          }

          this.appList = sortBy(this.appList, [
            (o): any => {
              return o.name;
            },
          ]);
          this.sharedService.setAppsData(this.appList);

          const appName = localStorage.getItem('selectedAppName');
          this.checkApplicationName(appName);

          listOfRoleName = map(availableRole, 'name');

          let selectedRole = this.appStorage.getRole();
          selectedRole = selectedRole ? selectedRole : availableRole[0];
          this.role = selectedRole;
          this.actualRoleList = availableRole;
          this.actualRoleList.forEach((element: any, index: number) => {
            const firstLetters = element.meta
              .split(' ')
              .map((word) => word[0])
              .join('');
            element.initial = firstLetters;
          });
          this.roleList = availableRole;
          //  this.appStorage.setAppRoles(listOfRoleName);
          this.prepareNavList(selectedRole);
        }
      } else {
        this.appService.setApp({});
        this.dataService.isAppLoaded(false);
      }
    } catch (err) {
      this.router.navigate(['/access-denied']);
    }
  }

  checkApplicationName(appName: string): void {
    if (appName && appName.startsWith(APPNAME_KEYS.AISI)) {
      this.logoName = PLATFORM_LOGO_NAMES.AISI;
    } else if (appName && appName.startsWith(APPNAME_KEYS.TNT)) {
      this.logoName = PLATFORM_LOGO_NAMES.TNT;
    } else {
      this.logoName = PLATFORM_LOGO_NAMES.SIP;
    }
  }

  async prepareNavList(role: any): Promise<any> {
    this.navList.navList = [];
    this.navListSettings.navList = [];
    const res: any = this.userService.permissionData; // await this.userService.getUserRolePermissions(role.name);
    if (res && res.data && res.data.length) {
      this.navList.navList = [];
      this.navListSettings.navList = [];
      this.dataService.homedashboard(res.data);

      this.getPermission();
      this.checkPermission();
      this.dataService.isNavigationLoad.next(true);
    } else {
      this.dataService.isNavigationLoad.next(false);
    }
    this.checkAppName();
    if (this.selectedAppName === labels.TRACK_AND_TRACE) {
      this.router.navigate(['/track-n-trace/upload-master-data']);
    } else if (this.selectedAppName === labels.JJV_MACHINE_ARCHIVE) {
      if (this.navList && this.navList.navList && this.navList.navList.length) {
        const prevUrl = this.router.url;
        if (
          this.navList.navList[0].children &&
          this.navList.navList[0].children.length &&
          (prevUrl !== this.navList.navList[0].children[0].path ||
            !prevUrl.includes(this.navList.navList[0].children[0].path))
        ) {
          this.router.navigate([this.navList.navList[0].children[0].path]);
        } else if (
          !this.navList.navList[0].children ||
          (this.navList.navList[0].children &&
            !this.navList.navList[0].children.length &&
            (prevUrl !== this.navList.navList[0].path ||
              !prevUrl.includes(this.navList.navList[0].path)))
        ) {
          this.router.navigate([this.navList.navList[0].path]);
        }
      } else {
        this.router.navigate(['/access-denied']);
      }
    }
    if (this.roleList.length === 0) {
      this.router.navigate(['/access-denied']);
    }
  }

  getPermission(): void {
    navList.forEach((nav) => {
      if (
        this.permissionServ.getPermissionOnKey(this.pages[nav.key]).length > 0
      ) {
        if (nav.children) {
          const navListChildren = [];
          nav.children.forEach((child) => {
            if (
              this.permissionServ.getPermissionOnKey(this.pages[child.key])
                .length > 0
            ) {
              navListChildren.push(child);
            }
          });
          nav.children = navListChildren;
        }
        this.navList.navList.push(nav);
      }
    });
  }

  checkPermission(): void {
    navListSettings.forEach((nav) => {
      if (
        this.permissionServ.getPermissionOnKey(this.pages[nav.key]).length > 0
      ) {
        if (nav.children) {
          const navListChildren = [];
          nav.children.forEach((child) => {
            if (
              this.permissionServ.getPermissionOnKey(this.pages[child.key])
                .length > 0
            ) {
              navListChildren.push(child);
            }
          });
          nav.children = navListChildren;
        }
        this.navListSettings.navList.push(nav);
      }
    });
  }

  findRouteFromUrl(url: string): void {
    for (const index of this.navList.navList) {
      const navItem = index;
      if (navItem.path.toLowerCase() === location.pathname.toLowerCase()) {
        return this.changeSelectNav(navItem);
      } else if (navItem.children !== null) {
        for (const childIdx of navItem.children) {
          const childNavItem = childIdx;
          if (
            location.pathname
              .toLowerCase()
              .includes(childNavItem.path.toLowerCase())
          ) {
            return this.changeSelectNav(navItem);
          }
        }
      } else {
      }
    }
    for (const index of this.navListSettings.navList) {
      const navItem = index;
      if (navItem.path.toLowerCase() === location.pathname.toLowerCase()) {
        return this.changeSelectNav(navItem);
      } else if (navItem.children != null) {
        for (const childIdx of navItem.children) {
          const childNavItem = childIdx;
          if (
            location.pathname
              .toLowerCase()
              .includes(childNavItem.path.toLowerCase())
          ) {
            return this.changeSelectNav(navItem);
          }
        }
      } else {
      }
    }
    this.changeSelectedNav(undefined);
  }

  changeSelectNav(navItem: INavList): any {
    this.changeSelectedNav(navItem);
    return;
  }

  headerGrpForm(): void {
    this.headerGrp = this.formBuilder.group({
      search: [''],
      app: '',
    });
  }

  // Navigation Component
  getNavList(): any {
    return this.navList;
  }

  // Navigation for settings
  getNavSettingsList(): any {
    return this.navListSettings;
  }

  changeSelectedNav(item: any): void {
    this.childNavList.navList = [];
    if (item) {
      if (
        item.key === 'Settings' &&
        !localStorage.getItem('selectedAppName').toUpperCase().includes('AISI')
      ) {
        item.children.splice(2, 1);
      }
      const subNavList = item.children ? (item.children as INavList[]) : [];
      subNavList.forEach((subNav) => {
        this.childNavList.navList.push(subNav);
      });
    } else {
      this.childNavList.navList = [];
    }

    this.childNavList = Object.assign({}, this.childNavList);
  }

  onSearchResultClick(evt: ISearchResultEntity): void {
    // inform catalog component about search item
    // open catalog browse
    this.router.navigateByUrl('catalog/browse');
  }

  ngOnDestroy(): void {
    this.headerSubscription?.unsubscribe();
  }
  getRolesDataByApp(): Observable<any> {
    const token: any = this.authService.getAccount();
    const roleList = token.idToken.roles;
    const reqData: AppRoleReq = {
      applicationId: this.selectedApp,
      roles: roleList,
    };

    return new Observable((obj) => {
      this.userService.getRoles(reqData).subscribe(
        (resData) => {
          obj.next(resData);
        },
        (err) => {
          obj.next({ data: [roleList[0]] });
        }
      );
    });
  }
  switchRole(): void {
    this.showRole = true;
    this.roleList = this.appStorage.getAppRoles();
  }
  onRoleNameClick(): void {
    this.userLogout = true;
  }
  onRoleNameClickHide(): void {
    this.userLogout = false;
  }
  roleSelect(event: any): void {
    this.showRole = false;
    if (!event) {
    }
  }

  checkAppName(): void {
    this.appList.forEach((element) => {
      if (localStorage.getItem('selectedApp') === element.id) {
        this.selectedAppName = element.name;
      }
    });
  }

  homePage(): void {
    this.childNavList.navList = [];
    this.childNavList = Object.assign({}, this.childNavList);
    if (this.logoName.toLowerCase() === 'tnt') {
      this.router.navigate(['/track-n-trace/upload-master-data']);
    }
  }
}
