import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { btnTypes } from '../../enum/button';
import { AppService } from 'src/app/core/services/app.service';
import { LABELS } from '@shared/shared.message';
@Component({
  selector: 'gdx-changeroles',
  templateUrl: './changeroles.component.html',
  styleUrls: [],
})
export class ChangerolesComponent implements OnInit {
  @Input() roleList: any = [];
  @Input() closeIcon = true;
  @Output() roleSelect = new EventEmitter();
  public btnType = btnTypes;
  selectedRoleObj: any = {};
  dynamicRoleList: any = [];
  collapse = 'assets/images/cross.svg';
  LABELS = LABELS;
  constructor(private router: Router, private appService: AppService) {}

  ngOnInit(): void {
    this.prepareDynamicRoleList();
  }

  prepareDynamicRoleList(): any {
    this.dynamicRoleList = [];
    const getRole: any = localStorage.getItem('selectedRole');
    this.roleList.forEach((element: any, index: number) => {
      const firstLetters = element.meta
        .split(' ')
        .map((word) => word[0])
        .join('');
      element.initial = firstLetters;
      if (getRole && JSON.parse(getRole).name === element.name) {
        element.selected = true;
        this.selectedRoleObj = element;
      } else {
        element.selected = false;
      }
    });
  }

  close(): any {
    this.roleSelect.emit(false);
  }
  selected(item: any): any {
    this.roleList.forEach((element: { selected: boolean }) => {
      element.selected = false;
    });
    item.selected = true;
    this.selectedRoleObj = item;
  }

  submitRole(): any {
    this.appService.removeAppHandler();
    localStorage.setItem('selectedRole', JSON.stringify(this.selectedRoleObj));
    this.roleSelect.emit(this.selectedRoleObj);
    this.router.navigate(['/']);
    location.reload();
  }
}
