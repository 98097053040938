const TNT_UPLOAD = {
  SHEETNAME: 'TNTMasterData',
  HEADERS: [
    'batch_no',
    'RFID',
    'material',
    'material_desc',
    'Set Type',
    'Set Brand',
    'division',
    'ship_to_ucn',
    'indv_cust_nm',
    'terr_id',
    'terr_nm',
    'reg_id',
    'reg_nm',
    'area_id',
    'area_nm',
    'BLE Tag associated',
    'In SAP?',
  ],
  AUDITOR_FILES_SHEETNAME: 'AuditorData',
  AUDITOR_FILES_HEADER: ['FE Code', 'Set Number', 'Set Description', 'Division', 'Region', 'Territory', 'Home location(UCN)', 'Home Location Name'],
  AUDITOR_RECONCILIATION_TEMPLATE_FILE_LOCATION: './assets/templateFiles/AuditorFileData.xlsx',
  AUDITOR_RECONCILIATION_TEMPLATE_FILENAME: 'AuditorFileDataTemplate',
  EXCEL_COLUMN_DIVISION: 'Division',
  EXCEL_COLUMN_FE_CODE: 'FE Code',
  EXCEL_COLUMNE_HOME_LOCATION_UCN: 'Home location(UCN)',
  EXCEL_COLUMNE_REGION: 'Region',
  EXCEL_COLUMNE_SET_NUMBER: 'Set Number',
  EXCEL_COLUMNE_TERRITORY: 'Territory'
};
const USER_ASSIGNMENT_UPLOAD = {
  SHEETNAME: 'usersData',
  SHEETPATH: './assets/templateFiles/userData.xlsx',
  EXTENSIONS: ['xlsx', 'csv', 'xls'],
  HEADERS: [
    'Name',
    'wwid',
    'Email Id',
    'Role',
    'Area div',
    'Area id',
    'Region div',
    'Region id',
    'Territory div',
    'Territory id',
  ],
};

const usersType = {
  Internal: 'Internal',
  External: 'External',
};

const actionIconNames = {
  EDIT: 'Edit',
  DELETE: 'Delete',
};

const calendarValues = ['fromDate', 'toDate'];
const calendarKeys = ['startDate', 'endDate'];
const inSAPValues = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
  { label: 'Unknown', value: null }
];
const assignmentTypes = {
  region: 'regions',
  territory: 'territories',
  area: 'areas',
  ucn: 'ucn',
};

const RUN_AUDIT_CONSTANTS = {
  LastSeenTime: 'Last Seen Time'
};

const REPORTS_CONSTANTS = {
  IS_AVAILABLE_IN_SAP: 'isAvailableInSAP',
  IS_WRONG_DESCRIPTION: 'isWrongDescription',
  BATTERY_LIFE: 'batteryLife',
  LAST_SEEN_LOCATION_DATE_TIME: 'lastSeenLocationDateTime',
  LAST_UPDATED_DATE_TIME: 'lastUpdatedDateTime',
  LAST_SEEN_DATE: 'lastSeenDate',
  DATE: 'date',
  NO_REPORT_TYPE_SELECTED:
    'No Rows to display with current filter, Please select any Report Type to get last 1 week data.',
  SELECT_FILTERS: 'Select Filters:',
  DOWNLOAD_TOOLTIP: 'Please Select atleast 1 column to Download Reports.',
  DOWNLOAD_AUDITOR_FILES_TOOLTIP: 'Download selected auditor reconciliation reports',
  add_Custom_Columns: 'Add Custom Columns:',
  DEFAULT_TRAY_IMG: 'assets/images/warn.png',
  NO_CAPTURED_IMAGES: 'No Captured Images to display.',
  CAPTURED_IMAGES: 'Captured Images',
  DOWNLOAD: 'Download : ',
  IMAGES: ' Image(s)',
  SOME_OF_THE_SELECTED_IMAGES_DONT_EXIST: `Some of the selected images don't exist`,
  APPLIED_FILTERS: 'Applied Filters :',
  CAPTURED_IMAGE: 'capturedImage',
  IMAGE_COUNT: 'imageCount',
  NO_CAPTURED_IMAGE: 'No Captured Images',
  H: 'H',
  D: 'D',
  W: 'W',
  Over49Weeks: 'over49Weeks',
  HRs: 'hour(s)',
  Days: 'day(s)',
  Weeks: 'week(s)',
  SPACE: ' ',
  UPDATED_AT: 'updatedAt',
  STAY_OUT_TIME: 'stayOutTime',
  QTY: 'qty',
  DATE_FOUND: 'dateFound',
  LAST_STERILIZATION_TIME: 'lastSterilizationTime',
  LAST_SEEN_LOCATION_TIME: 'lastSeenLocationTime',
  TAGGED_ON_DATE_TIME: 'taggedOnDateTime',
  LAST_SEEN_LOCATION: 'lastSeenLocation',
  STERILIZATION_COUNT: 'sterilizationCount',
  BATTERY_LEVEL: 'batteryLevel',
  TAGGED_BY: `taggedBy`,
  LAST_SEEN_BY_REP: 'lastSeenByRep',
  LAST_SEEN_BY_TERR: 'lastSeenByTerr',
  LAST_SEEN_BY_REGION: 'lastSeenByRegion',
  TERRITORY_DEVIATED: 'territoryDeviated',
  HOME_LOCATION_DEVIATED: 'homeLocationDeviated',
  FE_TAG: 'FETag',
  STATUS: 'status',
  RED_BATTERY: 'assets/img/power_red.png',
  GREEN_BATTERY: 'assets/img/power_green.png',
  GREY_BATTERY: 'assets/img/power_gray.png',
  MAX_BATTERY: 30,
  MIN_BATTERY: 3.0,
  STATUS_CLOSED: 'Closed',
  STATUS_PENDING: 'Pending',
  APPROVE_ERROR_SAVE: 'Only saved values can be approved.',
  APPROVE_ERROR_EMPTY_FETAG: 'FE Number is mandatory before approving Free Forms',
  APPROVE_IN_SAP_UNKNOWN: `You can't approve a record with in SAP status as 'Unknown'`,
  FILE_SEPERATOR: '_',
  REPORT_TYPE_FREEFORM: 'freeFormReport',
  REPORT_TYPE_AUDIT: 'auditReport',
  REPORT_TYPE_AUDITOR_RECONCILIATION_REPORT: 'auditorReconciliationReport',
  REPORT_TYPE_SET_NOT_IN_HOME_LOCATION: 'setNotInHomeLocationReport',
  UPLOAD: 'Upload',
  FILE_NAME: 'fileName',
  UPLOADED_ON: 'uploadedOn',
  UPLOADED_BY: 'uploadedBy',
  FILE_PATH: 'filePath',
  ID: 'id',
  DOWNLOAD_AUDIT_RECONCILIATION_REPORT: 'Download Audit Reconciliation Report',
  SELECTION_COLUMN_FIELD: 'Selection',
  END_TIME: 'T23:59:59.000Z',
  UTC_FORMAT: 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
};

const FILTER_VALUES = {
  null: 'null',
  yes: 'yes',
  no: 'no',
  divisions: 'divisions',
  area: 'area',
  region: 'region',
  regions: 'regions',
  territories: 'territories',
  customers: 'customers',
  brands: 'brands',
  types: 'types',
  invalid_date: 'Invalid date',
  fromDate: 'fromDate',
  toDate: 'toDate',
  dateFormat: 'YYYY-MM-DD',
  limit: 'limit',
  offset: 'offset',
  projection: 'projection',
  format: 'format',
  default_period: 7,
  default_period_for_audit_report: 30,
  default_period_for_auditor_reconciliation_report: 365,
  one: '1',
  zero: '0',
  true: 'true',
  false: 'false',
  isAvailableInSAP: 'isAvailableInSAP',
  isWrongDescription: 'isWrongDescription',
  minDefaultDateFilter: 0,
  maxDefaultDateFilter: null
};

const TOAST_VALUES = {
  filters: 'Filters',
  please_Select: 'Please Select!',
  end_Date: 'End Date',
  cleared_successfully: 'Cleared Successfully!',
  applied_Successfully: 'Applied Successfully!',
};

const assignmentIds = {
  area: 'Area id',
  region: 'Region id',
  territory: 'Territory id',
  valid: 'VALID',
};

const ADD_USER = {
  has_been_assigned: ' has been assigned ',
  do_you_want_to_proceed: '. Do you want to proceed?',
};

const STORAGE_DETAILS = {
  fileShareServiceClientURL: (accountName: string, sas: string) => `https://${accountName}.file.core.windows.net?${sas}`
};

const PAGINATION_CONFIG = {
  auditorReconciliationReportRowOptions: [10, 20, 40, 100, true],
};

const excludeRoles = ['app admin'];

const ROLES = {
  ROLE_META: {
    SALES_CONSULTANT: 'Sales Consultant',
    REGIONAL_MANAGER: 'Regional Manager',
    AREA_VP: 'Area Vice President'
  }
};
export {
  TNT_UPLOAD,
  usersType,
  actionIconNames,
  USER_ASSIGNMENT_UPLOAD,
  calendarValues,
  inSAPValues,
  calendarKeys,
  assignmentTypes,
  assignmentIds,
  TOAST_VALUES,
  FILTER_VALUES,
  REPORTS_CONSTANTS,
  ADD_USER,
  excludeRoles,
  STORAGE_DETAILS,
  RUN_AUDIT_CONSTANTS,
  PAGINATION_CONFIG,
  ROLES
};

export const fileTypes = {
  xlsx: 'xlsx',
  csv: 'csv',
};
