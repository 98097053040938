import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { Router } from '@angular/router';
import { filter } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { AppAdminService } from '@core-services/app-admin.service';
import { CommonService } from '@core-services/common.service';
import { DialogService } from '@core-services/dialog.service';
import { SourceService } from '@pages/manage-source/services/source.service';
import { rolePermission } from '@core/permission/permissions.type';
import { PermissionsService } from '@core/permission/permissions.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { messages } from '@pages/manage-source/manage-source.message';
import { ToastComponent } from '@shared/components/toast/toast.component';
import { toast } from 'tailwind-toast';
import * as constant from '@shared/shared.constant';
import * as sourceConstants from '@shared/components/source-hierarchy/source-hierarchy.constant';

/**
 * Node for to-do item
 */
export class TodoItemNode {
  children: TodoItemNode[];
  item: string;
  sourceType: string;
  id: string;
  element: object;
  ok: boolean;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: string;
  id: string;
  level: number;
  expandable: boolean;
  sourceType: string;
  element: object;
  ok?: boolean;
}

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] {
    return this.dataChange.value;
  }

  constructor(public dialogService: DialogService) {}

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildTreeView(obj: { [key: string]: any }, level: number): TodoItemNode[] {
    return obj.reduce((accumulator, value) => {
      if (value.status) {
        const node = new TodoItemNode();
        node.item = value.name ? value.name : value.item ? value.item : '';
        node.id = value.id;
        node.sourceType = value.sourceType;
        node.element = value;
        node.ok = true;

        if (value != null) {
          if (
            typeof value === 'object' &&
            value.child &&
            value.child.length > 0
          ) {
            node.children = this.buildTreeView(value.child, level + 1);
          }
        }
        return accumulator.concat(node);
      } else {
        return accumulator;
      }
    }, []);
  }

  /** Add an item to to-do list */
  insertItem(parent: TodoItemNode, name: string): void {
    if (parent && parent.children) {
      parent.children.push({ item: name, id: '' } as TodoItemNode);
      this.dataChange.next(this.data);
    } else {
      parent.children = this.buildTreeView(
        [{ item: name }] as TodoItemNode[],
        1
      );
      parent.children.push({ item: name, id: '' } as TodoItemNode);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: TodoItemNode, value): void {
    node.item = value.name;
    node.id = value.id;
    node.sourceType = value.sourceType;
    node.element = value;
    node.ok = true;
    this.dataChange.next(this.data);
  }
  deleteItem(parent: TodoItemNode, name: string): void {
    if (parent && parent.children) {
      parent.children = parent.children.filter((c) => c.item !== name);
      this.dataChange.next(this.data);
    }
  }
}

@Component({
  selector: 'gdx-source-hierarchy',
  templateUrl: './source-hierarchy.component.html',
  styleUrls: ['./source-hierarchy.component.scss'],
  providers: [ChecklistDatabase],
})
export class SourceHierarchyComponent implements OnInit, AfterViewInit {
  public MESSAGES = messages;
  public sourceHierarchyFormGroup: FormGroup = new FormGroup({});
  public rolePermission = JSON.parse(JSON.stringify(rolePermission));
  @Output() onSelectNode: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleEdit: EventEmitter<any> = new EventEmitter<any>();
  @Input() isAnyChanges;
  @Input() setUpdateNotify;
  @ViewChild('toaster') toaster!: ToastComponent;
  selecedAppName = '';
  sourceType = [];
  selectedType;
  selectedElement;
  selectedElementId;
  selectedSource;
  selectedKeyValue;
  selectedSourceType;
  selectedNode: TodoItemFlatNode;
  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
  treeControl: FlatTreeControl<TodoItemFlatNode>;
  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(false, []);

  isEditActive = false;
  showEdit = false;
  showView = false;
  search: any;
  editManageSource = false;
  addManageSource = false;
  deleteManageSource = false;
  allSourceTypes: any = [];
  showNewNode = false;
  constructor(
    private treeView: ChecklistDatabase,
    private sourceService: SourceService,
    private dialogService: DialogService,
    private appAdminService: AppAdminService,
    private router: Router,
    private commonService: CommonService,
    private permissionServ: PermissionsService,
    public formBuilder: FormBuilder
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
  }
  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, nodeData: TodoItemFlatNode) => nodeData.expandable;

  hasNoContent = (_: number, nodeData: TodoItemFlatNode) =>
    nodeData.item === '';
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item
        ? existingNode
        : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.id = node.id;
    flatNode.level = level;
    flatNode.sourceType = node.sourceType;
    flatNode.element = node.element;
    flatNode.ok = node.ok;
    flatNode.expandable = !!(node.children && node.children.length);
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };
  ngOnInit(): void {
    this.sourceHierarchyFormGroup = this.formBuilder.group({
      itemValue: ['', Validators.required],
    });
    this.addManageSource = this.permissionServ.checkPermission(
      this.rolePermission.addManageSource
    );
    this.editManageSource = this.permissionServ.checkPermission(
      this.rolePermission.editManageSource
    );
    this.deleteManageSource = this.permissionServ.checkPermission(
      this.rolePermission.deleteManageSource
    );
    this.treeView.dataChange.subscribe((data) => {
      this.dataSource.data = data;
    });
    this.setUpdateNotify.subscribe((data) => {
      this.getElements();
      if (this.isEditActive) {
        this.toggleManage();
      }
    });
    this.getElements();
    this.selecedAppName = localStorage.getItem('selectedAppName');
  }
  ngAfterViewInit(): void {
    this.treeControl.expandAll();
  }
  getElements(): void {
    let appName = '';
    if (localStorage.getItem('selectedApp')) {
      appName = localStorage.getItem('selectedAppName');
    } else {
      this.router.navigate(['/home']);
    }

    this.sourceService.getAllHierarchyTypes().subscribe((result: any) => {
      this.sourceType = result.data;
    });
    this.appAdminService.getAppDetails(appName).subscribe((data: any) => {
      this.dataSource.data = this.treeView.buildTreeView(
        data.data[0].sourceHierarchy,
        0
      );
      if (data.data[0].sourceTypes && data.data[0].sourceTypes.length) {
        const parent = data.data[0].sourceHierarchy[0].sourceType;
        if (parent !== null && parent !== undefined) {
          this.allSourceTypes = data.data[0].sourceTypes;
        }
      }
      Object.keys(this.dataSource.data).forEach((x) => {
        this.setParent(this.dataSource.data[x], null);
      });
      this.treeView.dataChange.next(this.dataSource.data);
      this.showEdit = false;
    });
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed  */
  todoLeafItemSelectionToggle(e, node: TodoItemFlatNode): void {
    if (this.showNewNode) {
      this.removeNewNode();
    }
    e.stopPropagation();
    if (this.isAnyChanges) {
      this.dialogService.openModal(
        'Warning',
        sourceConstants.MESSAGES.UNSAVED_CHANGES,
        'warn',
        (result) => {
          if (result) {
            this.toggleNode(node);
            this.isAnyChanges = false;
          }
        },
        true
      );
    } else {
      this.toggleNode(node);
    }
    if (!this.selectedElement && this.isEditActive) {
      this.toggleManage();
    }
  }
  toggleNode(node, show = false): void {
    this.checklistSelection.toggle(node);
    this.checkShow(show, node);
    this.selectedSourceType = this.selectedNode.sourceType;
    const selectdSourceType = filter(this.sourceType, (item) => {
      if (item.name === this.selectedSourceType) {
        return item.properties;
      }
    })[0];
    if (this.selectedElement.length > 0) {
      this.onSelectNode.emit({ node, sourceType: this.allSourceTypes });
    } else {
      this.onSelectNode.emit({ node: {}, sourceType: this.allSourceTypes });
    }

    if (selectdSourceType) {
      this.selectedKeyValue = selectdSourceType.properties;
    }
    if (this.isEditActive && this.selectedSource.name) {
      this.showEdit = true;
    } else {
      this.showEdit = false;
    }
    if (!this.isEditActive && this.selectedSource.name) {
      this.showView = true;
    } else {
      this.showView = false;
    }
    this.treeControl.expand(this.selectedNode);
  }
  checkShow(show: boolean, node: any): void {
    if (show) {
      this.selectedElement = '';
      this.selectedElementId = '';
      this.selectedSource = {};
      this.selectedNode = node;
    } else {
      this.selectedElement =
        this.checklistSelection.selected.length > 0 &&
        this.checklistSelection.selected[0]
          ? this.checklistSelection.selected[0].item
          : '';
      this.selectedElementId =
        this.checklistSelection.selected.length > 0 &&
        this.checklistSelection.selected[0]
          ? this.checklistSelection.selected[0].id
          : '';
      this.selectedSource =
        this.checklistSelection.selected.length > 0 &&
        this.checklistSelection.selected[0]
          ? this.checklistSelection.selected[0].element
          : {};
      this.selectedNode = node;
    }
  }

  addNewItem(e): void {
    const parentNode = this.flatNodeMap.get(this.selectedNode);
    if (parentNode && parentNode.children && parentNode.children.length) {
      const isAnyNodeEmpty = parentNode.children.find((obj) => {
        return obj.item === '';
      });
      if (!isAnyNodeEmpty) {
        this.treeView.insertItem(parentNode, '');
        Object.keys(this.dataSource.data).forEach((x) => {
          this.setParent(this.dataSource.data[x], null);
        });
        this.treeControl.expand(this.selectedNode);
        this.showNewNode = true;
      }
    } else {
      this.treeView.insertItem(parentNode, '');
      Object.keys(this.dataSource.data).forEach((x) => {
        this.setParent(this.dataSource.data[x], null);
      });
      this.treeControl.expand(this.selectedNode);
      this.showNewNode = true;
    }
  }
  removeNode(): void {
    const parentNode: any = this.flatNodeMap.get(this.selectedNode);
    this.treeView.deleteItem(parentNode.parent, this.selectedNode.item);
    this.toggleNode(parentNode.parent, true);
    this.selectedType = '';
    this.treeControl.expand(this.selectedNode);
  }
  removeNewNode(): void {
    const parentNode: any = this.flatNodeMap.get(this.selectedNode);
    this.sourceHierarchyFormGroup.controls.itemValue.patchValue('');
    this.treeView.deleteItem(
      parentNode,
      this.sourceHierarchyFormGroup.value.itemValue
    );
    this.selectedType = '';
    this.showNewNode = false;
  }

  /** Delete Selected Node */
  deleteNode(node: TodoItemFlatNode): void {
    if (this.isAnyChanges) {
      this.dialogService.openModal(
        'Warning',
        sourceConstants.MESSAGES.UNSAVED_CHANGES,
        'warn',
        (result) => {
          if (result) {
            this.toggleNode(node);
            this.isAnyChanges = false;
          }
        },
        true
      );
    } else {
      this.toggleNode(node);
      this.dialogService.openModal(
        'Warning',
        sourceConstants.MESSAGES.DELETE_NODE + node.item + ' ?',
        'warn',
        (result) => {
          if (result) {
            const id = node.id;
            this.sourceService.deleteSource(id).subscribe(
              (data: any) => {
                this.removeNode();
                toast()
                  .success(
                    sourceConstants.MESSAGES.SUCCESS,
                    sourceConstants.MESSAGES.NODE_REMOVE
                  )
                  .with(constant.toastParams)
                  .show();
              },
              (error: any) => {
                toast()
                  .danger('Warning! ', error.error.message)
                  .with(constant.toastParams)
                  .show();
              }
            );
          }
        },
        false,
        true
      );
    }
  }

  /** Save the node to database */
  saveNode(node: TodoItemFlatNode): void {
    const data: any = {};
    data.name = this.sourceHierarchyFormGroup.value.itemValue;
    data.type = this.selectedType;
    data.status = '1';
    data.parentId = this.selectedElementId;
    this.sourceService.saveSource(data).subscribe(
      (data1: any) => {
        toast()
          .success(
            sourceConstants.MESSAGES.SUCCESS,
            sourceConstants.MESSAGES.SAVE_DATA
          )
          .with(constant.toastParams)
          .show();
        const nestedNode = this.flatNodeMap.get(node);
        const nodeData = data1.body.data[0];
        const selectedSource = filter(
          this.sourceType,
          (item) => item.id === this.selectedType
        )[0];
        nodeData.sourceType = selectedSource.name;
        // tslint:disable-next-line: no-non-null-assertion
        this.treeView.updateItem(nestedNode!, nodeData);
        this.selectedType = '';
        this.sourceHierarchyFormGroup.controls.itemValue.patchValue('');
        this.treeView.dataChange.next(this.dataSource.data);
      },
      (error: any) => {
        toast()
          .danger('Warning! ', error.error.message)
          .with(constant.toastParams)
          .show();
      }
    );
  }

  toggleManage(): void {
    this.isEditActive = !this.isEditActive;
    if (this.showNewNode) {
      this.removeNewNode();
    }
    if (
      this.isEditActive &&
      this.selectedElement &&
      this.selectedElement.length > 0
    ) {
      this.showView = false;
      this.showEdit = true;
    } else {
      this.showEdit = false;
      this.showView = false;
    }
    if (
      !this.isEditActive &&
      this.selectedElement &&
      this.selectedElement.length > 0
    ) {
      this.showView = true;
    } else {
      this.showView = false;
    }
    this.selectedType = '';
    this.toggleEdit.emit();
  }
  refreshSource(): void {
    this.getElements();
  }
  changeTrigger(e): void {
    this.isAnyChanges = e;
  }
  checkPermission(action, permission): any {
    return this.commonService.checkPermission(action, permission);
  }
  setChildOk(text: string, node: any): void {
    text = text.toLocaleLowerCase();
    node.forEach((x) => {
      x.ok = x.item.toLocaleLowerCase().indexOf(text) >= 0;
      if (x.parent) {
        this.setParentOk(text, x.parent, x.ok);
      }
      if (x.children) {
        this.setChildOk(text, x.children);
      }
    });
    this.treeView.dataChange.next(node);
  }
  setParentOk(text, node, ok): void {
    node.ok = ok || node.ok || node.item.toLocaleLowerCase().indexOf(text) >= 0;
    if (node.parent) {
      this.setParentOk(text, node.parent, node.ok);
    }
  }
  setParent(data, parent): void {
    data.parent = parent;
    if (data.children) {
      data.children.forEach((x) => {
        this.setParent(x, data);
      });
    }
  }
}
