<div
  *ngIf="showEsign"
  class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
>
  <div class="relative w-auto mx-auto max-w-6xl">
    <div #esignModal class="max-w-xl ml-24">
      <div class="modal-header">
        <div
          class="text-white bg-red mt-1 rounded-t-lg text-center p-4 text-xl"
        >
          {{ labels.esign }}
        </div>
        <div class="absolute top-5 right-4 cursor-pointer">
          <img
            class="float-right"
            src="assets/images/cross.svg"
            alt="alertIcon"
            (click)="closeEsign()"
          />
        </div>
      </div>
      <div class="bg-white shadow-md rounded-b-lg px-8 pt-4 pb-14 mb-4">
        <div class="mb-3">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="username"
          >
            {{ labels.userName }} <span class="text-red">*</span
            ><span
              ><img
                src="../../../assets/images/info.svg"
                title="Email/Username of the logged-in user"
                alt="info"
                class="inline cursor-pointer ml-1"
                width="16"
                height="16"
            /></span>
          </label>
          <gdx-input-box
            [type]="'text'"
            [id]="'esignUsername'"
            [name]="'esignUsername'"
            [control]="'esignUsername'"
            [placeholder]="'Email/Username'"
            [disabled]="false"
            [textClass]="'w-full'"
            [parentForm]="eSignFormGrp"
            (onChange)="
              checkUserName(eSignFormGrp.controls.esignUsername?.value)
            "
            (input)="userFull = false"
          >
          </gdx-input-box>
          <span class="text-red text-xs" *ngIf="userLogged">
            {{ labels.userNameInfo }}
          </span>
          <span class="text-xs" *ngIf="userFull">
            {{ fullUserName }}({{ userName }})</span
          >
        </div>
        <div class="mb-3">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="password"
          >
            {{ labels.pwd }} <span class="text-red">*</span
            ><span
              ><img
                src="../../../assets/images/info.svg"
                title="Password of logged-in user"
                alt="info"
                class="inline cursor-pointer ml-1"
                width="16"
                height="16"
            /></span>
          </label>
          <gdx-input-box
            [type]="'password'"
            [id]="'esignPassword'"
            [name]="'esignPassword'"
            [control]="'esignPassword'"
            [placeholder]="'Password'"
            [disabled]="false"
            [textClass]="'w-full'"
            [parentForm]="eSignFormGrp"
          >
          </gdx-input-box>
        </div>

        <div class="mb-3">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="reason"
          >
            {{ labels.reason }} <span class="text-red">*</span
            ><span
              ><img
                src="../../../assets/images/info.svg"
                title="Reason for the current action"
                alt="info"
                class="inline cursor-pointer ml-1"
                width="16"
                height="16"
            /></span>
          </label>
          <gdx-dropdown
            [id]="'esignReason'"
            [name]="'esignReason'"
            class="w-full"
            [class]="
              ' w-60 border border-gray-300 hover:border-gray-300 focus:outline-none focus:shadow-none focus:border-gray-500 '
            "
            [control]="'esignReason'"
            [options]="eSignList"
            [optionLabel]="'name'"
            [optionValue]="''"
            [placeholder]="'Select a Reason'"
            [parentForm]="eSignFormGrp"
          >
          </gdx-dropdown>
        </div>

        <div class="mb-1">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="comment"
          >
            {{ labels.comment }}
            <span
              ><img
                src="../../../assets/images/info.svg"
                title="Comments for the current action"
                alt="info"
                class="inline cursor-pointer ml-1"
                width="16"
                height="16"
            /></span>
          </label>
          <gdx-text-area
            [type]="'text'"
            [id]="'esignComment'"
            [name]="'esignComment'"
            [control]="'esignComment'"
            [placeholder]="'Comment'"
            [disabled]="false"
            [class]="'w-full'"
            [parentForm]="eSignFormGrp"
          >
          </gdx-text-area>
        </div>
        <p class="text-center text-red-500 text-xs mb-2">
          {{ labels.disclaimer }}
        </p>
        <div class="float-right block mt-2 pb-5">
          <div class="ml-2 w-28 h-9 float-right">
            <gdx-button
              [id]="'eSignBtn'"
              text="Sign"
              [type]="btnType.submit"
              [disabled]="!eSignFormGrp.valid || userLogged || saveSigned"
              (clicked)="verifyEsign()"
            >
            </gdx-button>
            <div *ngIf="saveSigned" class="absolute -mt-7 ml-3">
              <svg
                height="16px"
                id="Layer_1"
                class="animate-spin h-5 w-5"
                style="enable-background: new 0 0 16 16"
                version="1.1"
                fill="#fff"
                viewBox="0 0 16 16"
                width="16px"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  d="M8,0C7.448,0,7,0.448,7,1v2c0,0.552,0.448,1,1,1s1-0.448,1-1V1C9,0.448,8.552,0,8,0z M8,12c-0.552,0-1,0.447-1,1v2  c0,0.553,0.448,1,1,1s1-0.447,1-1v-2C9,12.447,8.552,12,8,12z M12.242,5.172l1.414-1.415c0.391-0.39,0.391-1.024,0-1.414  c-0.39-0.391-1.023-0.391-1.414,0l-1.414,1.414c-0.391,0.391-0.391,1.024,0,1.415C11.219,5.562,11.852,5.562,12.242,5.172z   M3.757,10.828l-1.414,1.414c-0.391,0.391-0.391,1.024,0,1.414c0.39,0.391,1.023,0.391,1.414,0l1.414-1.414  c0.391-0.391,0.391-1.023,0-1.414C4.781,10.438,4.148,10.438,3.757,10.828z M3.757,2.343c-0.391-0.391-1.024-0.391-1.414,0  c-0.391,0.39-0.391,1.024,0,1.414l1.414,1.415c0.391,0.39,1.024,0.39,1.414,0c0.391-0.391,0.391-1.024,0-1.415L3.757,2.343z   M12.242,10.828c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l1.414,1.414c0.391,0.391,1.024,0.391,1.414,0  c0.391-0.39,0.391-1.023,0-1.414L12.242,10.828z M15,7h-2c-0.553,0-1,0.448-1,1s0.447,1,1,1h2c0.553,0,1-0.448,1-1S15.553,7,15,7z   M4,8c0-0.552-0.448-1-1-1H1C0.448,7,0,7.448,0,8s0.448,1,1,1h2C3.552,9,4,8.552,4,8z"
                />
              </svg>
            </div>
          </div>
          <div class="ml-2 w-28 h-9 float-right">
            <gdx-button
              [id]="'eSignCancelBtn'"
              text="Cancel"
              [type]="btnType.test"
              [disabled]="false"
              (clicked)="closeEsign()"
            >
            </gdx-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showEsign" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
