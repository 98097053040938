<div class="user-wrapper overflow-hidden">
  <div
    class="bell-img float-right inline-block text-center cursor-pointer w-10 pt-2.5 pl-2 pr-2"
    (click)="showblock()"
    [ngClass]="isShow ? 'close' : ''"
    (gdxClickOutside)="hideSignOut()"
  >
    <span
      class="user-text inline-block p-0.5 text-white font-bold text-xs rounded-sm border-solid border border-white"
    >
      {{ role }}
    </span>
  </div>
  <div
    class="notification-content absolute -right-0 min-h-100 border border-gray-200 shadow-md border-borderColor-50 w-60 top-12 bg-white z-50"
    *ngIf="isShow"
  >
    <div class="">
      <ul>
        <li
          class="bg-emerald-200 text-gray-600 hover:text-black focus:outline-none p-4"
        >
          <div class="flex space-x-2">
            <div
              class="rounded-full w-10 h-10 text-gray-700 bg-gray-200 flex items-center justify-center"
            >
              <span>
                {{ userInitial }}
              </span>
            </div>
            <div class="flex flex-col">
              <div class="text-md font-bold">
                {{ userName }}
              </div>
              <div class="text-xs">
                {{ roleName }}
              </div>
            </div>
          </div>
        </li>
        <li
          *ngIf="actualRoleList.length > 1"
          (click)="openRolesModal()"
          class="bg-emerald-200 text-sm font-medium text-gray-600 border border-gray-200 border-b-0 hover:bg-gray-300 hover:text-black cursor-pointer focus:outline-none p-2"
        >
          <div class="flex space-x-4 items-center">
            <div>
              <span
                [inlineSVG]="'assets/images/switch.svg'"
                alt="switch"
              ></span>
            </div>
            <div>Switch Role</div>
          </div>
        </li>
        <li
          class="bg-emerald-200 text-sm font-medium text-gray-600 hover:bg-primary-930 border border-gray-200 hover:text-black cursor-pointer focus:outline-none p-2"
          (click)="logOut()"
        >
          <div class="flex space-x-4 items-center">
            <div>
              <span
                [inlineSVG]="'assets/images/logout.svg'"
                alt="switch"
              ></span>
            </div>
            <div>Sign Out</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<gdx-changeroles
  (roleSelect)="changeRoleEvent($event)"
  [roleList]="roleList"
  *ngIf="viewRoleModal"
></gdx-changeroles>
