export const SOURCE_HIERARCHY_LABELS = {
  SOURCE_HIERARCHY: 'Source Hierarchy',
  EDIT_NODE: 'Edit Node',
  SELECT_HIERARCHY: 'Select Hierarchy Type',
  NEW_ITEM: 'New Item...',
};

export const EDIT_SOURCE_LABELS = {
  CONNECTION_STATUS: 'connectionStatus',
  NOT_CONNECTED: 'NOT-CONNECTED',
  CONNECTION__STATUS: 'Connection Status',
  STATUS: 'status',
  DEVICE_STATUS: 'Status',
  ONBOARDING_SUCCESSFUL: 'ONBOARDING-SUCCESSFULL',
  ONBOARDING_FAILURE: 'ONBOARDING-FAILURE',
  UPDATE: 'Update',
  REPROVISION: 'Re-Provision',
  PROVISION: 'Provision',
  DUPLICATE_KEY_ERROR: 'Duplicate key is selected',
  SELECTED_KEY_ERROR:
    'Please provide valid value(True or False) for selected key',
  SELECTED_KEY_DECIMAL_ERROR:
    'Please provide valid value(Decimal) for selected key',
  SELECTED_KEY_STRING_ERROR:
    'Please provide valid value(String) for selected key',
  SELECTED_KEY_INTEGER_ERROR:
    'Please provide valid value(Integer) for selected key',
  SELECTED_KEY_LONG_ERROR: 'Please provide valid value(Long) for selected key',
  SELECTED_KEY_BINARY_ERROR:
    'Please provide valid value(Binary) for selected key',
  SELECTED_KEY_UUID_ERROR: 'Please provide valid value(UUID) for selected key',
  DEFECT_SIZE: 'defectSize',
  NAME: 'Name',
  DESCRIPTION: 'Description',
  ENABLE_DISABLE_DEVICE: 'Enable/Disable Device :',
  SELECTED_ELEMENT_ON: 'On',
  SELECTED_ELEMENT_OFF: 'Off',
  KEY: 'Key:',
  VALUE: 'Value:',
  SAVE: 'Save',
  DATA_UPDATED: 'Data Updated successfully',
};

export const VIEW_SOURCE_LABELS = {
  CONNECTION_STATUS: 'connection Status',
  NOT_CONNECTED: 'NOTCONNECTED',
  ENABLE_DISABLE_DEVICE: 'Enable/Disable Device :',
  NAME: 'Name :',
  VIEW: 'View',
  SELECTED_ELEMENT_ON: 'On',
  SELECTED_ELEMENT_OFF: 'Off',
  DESCRIPTION: 'Description :',
  NOT__CONNECTED: 'NOT-CONNECTED',
  DEVICE_ON_SUCCESS: 'Device Switched on successfully',
  DEVICE_OFF_SUCCESS: 'Device Switched off successfully',
};
export const DATA_TYPES = {
  INT: 'int',
  FLOAT: 'float',
  LONG: 'long',
  BINARY: 'binary',
  UNIQUE_IDENTIFIER: 'uniqueIdentifier',
  STRING: 'string',
  BOOLEAN: 'boolean',
};
export const messages = {
  VIEW_SOURCE_LABELS,
  EDIT_SOURCE_LABELS,
  SOURCE_HIERARCHY_LABELS,
  DATA_TYPES,
};
