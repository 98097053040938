<div class="pl-0">
  <div>
    <div class="title flex flex-row">
      <div>
        {{ MESSAGES.SOURCE_HIERARCHY_LABELS.SOURCE_HIERARCHY }}
      </div>
      <div *ngIf="editManageSource">
        <button
          class="manage-button disabled:opacity-50"
          [disabled]="!selectedElement"
          (click)="toggleManage()"
          [ngClass]="isEditActive ? 'manage-button-active' : ''"
        >
          {{ MESSAGES.SOURCE_HIERARCHY_LABELS.EDIT_NODE }}
        </button>
      </div>
    </div>
    <div class="">
      <div class="search-bar">
        <span class="float-left">
          <input
            [(ngModel)]="search"
            (ngModelChange)="
              search = $event; setChildOk($event, dataSource.data)
            "
            class="search-txt-area"
            placeholder="Search"
          />
        </span>
        <span class="float-left"
          ><img class="w-10 mt-2 -ml-6" src="assets/images/search.svg"
        /></span>
      </div>
    </div>
    <div class="custom-row">
      <div class="source-type" *ngIf="addManageSource">
        <mat-form-field class="select-dropdown">
          <mat-placeholder class="mat-placeholder">{{
            MESSAGES.SOURCE_HIERARCHY_LABELS.SELECT_HIERARCHY
          }}</mat-placeholder>
          <mat-select
            (selectionChange)="addNewItem($event)"
            [(ngModel)]="selectedType"
            [disabled]="!isEditActive"
          >
            <mat-option
              *ngFor="let type of sourceType; let i = index"
              [value]="type.id"
              >{{ type.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="tree-view">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node
          *matTreeNodeDef="let node"
          matTreeNodeToggle
          matTreeNodePadding
          [style.display]="!search || node.ok ? 'inline-block' : 'none'"
        >
          <div
            class="border-tree"
            [ngClass]="{
              'bg-blue text-white': checklistSelection.isSelected(node),
              'opacity-80': node.element.status == '0'
            }"
            (click)="todoLeafItemSelectionToggle($event, node)"
          >
            <button mat-icon-button disabled></button>
            <span
              pTooltip="{{ node.item }} | {{ node.sourceType }}"
              tooltipPosition="top"
              class="checklist-leaf-node node-label"
              (click)="todoLeafItemSelectionToggle($event, node)"
              >{{ node.item | shortenText: 25 }}&nbsp;&nbsp; |&nbsp;&nbsp;
              <span class="source-type-label"
                >{{ node.sourceType | shortenText: 10 }}
                <div
                  class="source-type-label delete-button"
                  title="Delete Source"
                  *ngIf="deleteManageSource"
                  (click)="$event.stopPropagation(); deleteNode(node)"
                >
                  <img class="delete-icon" src="../../assets/img/delete.png" />
                </div>
              </span>
            </span>
          </div>
        </mat-tree-node>
        <mat-tree-node
          *matTreeNodeDef="let node; when: hasNoContent"
          matTreeNodePadding
          [style.display]="!search || node.ok ? 'flex' : 'none'"
        >
          <div class="flex-col">
            <div>{{ MESSAGES.SOURCE_HIERARCHY_LABELS.NEW_ITEM }}</div>
            <div class="w-full mb-4">
              <gdx-input-box
                [id]="'newNode'"
                [name]="'newNode'"
                [placeholder]="'Item'"
                [disabled]="false"
                [class]="
                  'italic h-10 w-28 border-gray-200 focus:border-gray-200'
                "
                [rows]="10"
                [control]="'itemValue'"
                [parentForm]="sourceHierarchyFormGroup"
              >
              </gdx-input-box>
            </div>
            <div class="flex flex-row">
              <div
                class="expand-btn-wrapper save-btn"
                (click)="saveNode(node)"
                title="Save"
              >
                <img
                  class="expand-btn"
                  src="../../../assets/img/svg-icon/save.svg"
                />
              </div>
              <div
                class="expand-btn-wrapper save-btn"
                (click)="removeNewNode()"
                title="Cancel"
              >
                <img
                  class="expand-btn"
                  src="../../../assets/img/svg-icon/remove.svg"
                />
              </div>
            </div>
          </div>
        </mat-tree-node>
        <mat-tree-node
          *matTreeNodeDef="let node; when: hasChild"
          matTreeNodePadding
          [style.display]="!search || node.ok ? 'inline-block' : 'none'"
        >
          <div
            class="border-tree"
            (click)="todoLeafItemSelectionToggle($event, node)"
            [ngClass]="checklistSelection.isSelected(node) ? 'active' : ''"
          >
            <div
              class="expand-btn-wrapper"
              *ngIf="!treeControl.isExpanded(node)"
              matTreeNodeToggle
              [attr.aria-label]="'toggle ' + node.filename"
            >
              <img
                *ngIf="checklistSelection.isSelected(node)"
                class="expand-btn"
                src="../../../assets/img/svg-icon/colleps_blue.svg"
              />
              <img
                *ngIf="!checklistSelection.isSelected(node)"
                class="expand-btn"
                src="../../../assets/img/svg-icon/colleps_black.svg"
              />
            </div>
            <div
              class="expand-btn-wrapper"
              *ngIf="treeControl.isExpanded(node)"
              matTreeNodeToggle
              [attr.aria-label]="'toggle ' + node.filename"
            >
              <img
                *ngIf="checklistSelection.isSelected(node)"
                class="expand-btn"
                src="../../../assets/img/svg-icon/expanded_blue.svg"
              />
              <img
                *ngIf="!checklistSelection.isSelected(node)"
                class="expand-btn"
                src="../../../assets/img/svg-icon/expanded_black.svg"
              />
            </div>
            <span
              pTooltip="{{ node.item }} | {{ node.sourceType }}"
              tooltipPosition="top"
              class="node-label"
              (click)="todoLeafItemSelectionToggle($event, node)"
              >{{ node.item | shortenText: 25 }}&nbsp;&nbsp; |&nbsp;&nbsp;
              <span class="source-type-label"
                >{{ node.sourceType | shortenText: 10 }}
                <div
                  class="source-type-label delete-button"
                  title="Delete Source"
                  *ngIf="deleteManageSource"
                  (click)="deleteNode(); $event.stopPropagation()"
                >
                  <img class="delete-icon" src="../../assets/img/delete.png" />
                </div>
              </span>
            </span>
          </div>
        </mat-tree-node>
      </mat-tree>
    </div>
  </div>
</div>
