<div
  class="-mt-8 bg-white fixed h-auto z-50 shadow-md right-0"
  [ngClass]="experimentClass"
>
  <div
    *ngIf="filterOpen"
    class="border-r pb-5 border-b-0 border-gray-40 min-h-screen shadow-md"
  >
    <form [formGroup]="experimentFilter">
      <div class="flex flex-row border-b border-gray-400 justify-between">
        <div class="ml-8 mt-1 mb-1 text-sm font-bold">{{ label.runExp }}</div>
        <div
          class="cursor-pointer flex items-center pr-0.5"
          (click)="expandCollapse()"
        >
          <span [inlineSVG]="collapse"> </span>
        </div>
      </div>
      <div class="grid grid-cols-1 gap-1 mr-4">
        <div class="mt-5 mb-1 flex flex-col">
          <div class="ml-8 mb-1 text-sm font-medium">
            {{ label.trayName }}
            <span class="text-red">*</span>
          </div>
          <div class="flex md:flex-row sm:flex-col ml-6 pr-4 w-11/12">
            <p-autoComplete
              [placeholder]="label.searchTray"
              [emptyMessage]="label.noRecords"
              [styleClass]="'w-96'"
              [inputStyleClass]="
                'ml-2 border border-gray-300 hover:border-gray-300 focus:outline-none focus:shadow-none focus:border-gray-500 '
              "
              [suggestions]="trayDataTemp"
              field="name"
              (completeMethod)="searchTrays($event)"
              [dropdown]="true"
              (onClear)="ifTrayEmpty()"
              (onSelect)="trayChanged($event)"
            ></p-autoComplete>
          </div>
        </div>
        <div class="mt-1.5 mb-1 flex flex-col">
          <div class="ml-8 mb-1 text-sm font-medium">
            {{ label.dataset }}
            <span class="text-red">*</span>
          </div>
          <div
            class="md:flex-col sm:flex-col ml-8 pr-4 pt-1 w-11/12 h-10"
            *ngIf="allDatasets.length > 6"
          >
            <input
              class="border border-gray-300 h-8 w-1/2 rounded-md outline-none pl-4 text-sm"
              placeholder="Search..."
              [(ngModel)]="searchKey"
              (input)="searchWithkeywords($event.target.value)"
            />
          </div>
          <div
            [ngClass]="allDatasets.length > 3 ? 'h-44' : 'h-24'"
            class="flex md:flex-col sm:flex-col ml-8 pr-4 pt-1 w-11/12 overflow-auto"
            [formGroup]="experimentForm"
            *ngIf="radioButtons.radioList.length"
          >
            <label
              class="mb-1 ml-1 cursor-pointer"
              *ngFor="let item of radioButtons.radioList; let i = index"
            >
              <input
                type="radio"
                [id]="item.id"
                [name]="item.name"
                [attr.disabled]="item.disabled ? '' : null"
                (change)="radioChange($event)"
                [class]="item.radioClass"
                [required]="item.required"
                [value]="item.id"
                [checked]="item.checked"
                [formControlName]="item.control"
              />
              <span class="text-sm ml-2 text-gray-800">{{ item.label }}</span>
            </label>
          </div>
          <div
            [ngClass]="allDatasets.length > 3 ? 'h-44' : 'h-24'"
            class="flex md:flex-col sm:flex-col ml-6 pl-2 pr-4 w-11/12"
            *ngIf="!radioButtons.radioList.length"
          >
            <div
              [ngClass]="allDatasets.length > 3 ? 'pt-16' : 'pt-7'"
              class="w-full h-full border border-gray-300 text-center rounded-md text-sm text-gray-600"
            >
              <p *ngIf="!traySelected" class="mt-2">
                {{ label.pleaseSelectTray }}
              </p>
              <p *ngIf="traySelected" class="mt-2">{{ label.noData }}</p>
            </div>
          </div>
        </div>
        <div class="mt-5 mb-1 flex flex-col">
          <div class="ml-8 mb-1 text-sm font-medium">
            {{ label.expName }}
            <span class="text-red">*</span>
          </div>
          <div class="flex md:flex-row sm:flex-col ml-6 pr-4 w-11/12">
            <gdx-input-box
              [type]="'text'"
              [id]="'txtEntity'"
              [name]="'txtEntity'"
              [control]="'experimentName'"
              [placeholder]="'Enter Experiment Name'"
              [disabled]="false"
              [textClass]="'w-3/4 ml-2'"
              class="w-80"
              [parentForm]="experimentForm"
            >
            </gdx-input-box>
            <small
              [style.visibility]="isExpNameEmpty ? '' : 'hidden'"
              class="text-danger large-font"
              >{{ label.nameRequired }}</small
            >
            <p
              [style.visibility]="isExpNameExists ? '' : 'hidden'"
              class="text-danger large-font"
            >
              {{ label.nameExists }}
            </p>
          </div>
        </div>
      </div>
      <div class="h-9 flex justify-end w-11/12 mt-8">
        <gdx-button
          class="-mr-3"
          [id]="'btn_filter-apply'"
          [type]="btnType.submit"
          [disabled]="validateForm()"
          [text]="'Go'"
          (clickEvent)="trainModel()"
        >
        </gdx-button>
      </div>
    </form>
  </div>
</div>
