import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChange,
  AfterViewInit,
  SimpleChanges,
  ComponentFactoryResolver,
} from '@angular/core';
import {
  INavigation,
  IDesign,
  INavList,
} from '../navigation/navigation.component.types';

import { LoadCompHelperDirective } from '../../directives/load-comp-helper.directive';
import { ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'gdx-sub-nav',
  templateUrl: './sub-nav.component.html',
})
export class SubNavComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() subNavListDetails!: INavigation;
  @ViewChild(LoadCompHelperDirective)
  loadCompHelpDirective: any = LoadCompHelperDirective;

  navDesign: IDesign = {
    styleElmentList: '',
    styleElementAnchor: '',
    styleActiveLink: '',
  };
  navList: INavList[] = [
    {
      label: '',
      path: '',
      icon: '',
      key: '',
    },
  ];
  activeLink = 'text-red-700 font-medium border-b-2 border-red-700 pb-1.5';
  subNavListStyle = '';
  subNavAnchorStyle = '';
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setupSubNav();
    this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.findActiveNavItem();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setupSubNav();
  }

  ngAfterViewInit(): void {
    this.setupSubNav();
  }
  private setupSubNav(): void {
    if (this.subNavListDetails && Object.keys(this.subNavListDetails).length) {
      this.navDesign = this.subNavListDetails.design
        ? this.subNavListDetails.design
        : this.navDesign;
      this.navList = this.subNavListDetails.navList
        ? this.subNavListDetails.navList
        : this.navList;
      this.subNavListStyle = this.navDesign.styleElmentList
        ? this.navDesign.styleElmentList
        : this.subNavListStyle;
      this.subNavAnchorStyle = this.navDesign.styleElementAnchor
        ? this.navDesign.styleElementAnchor
        : this.subNavAnchorStyle;
      this.activeLink = this.navDesign.styleActiveLink
        ? this.navDesign.styleActiveLink
        : this.activeLink;
    }
    if (
      this.navList.find((item) => item.path === this.router.url) ||
      this.navList.find((item) => this.router.url.includes(item.path))
    ) {
      this.router.navigate([this.router.url]);
    } else {
      this.router.navigate([this.navList[0].path]);
    }

    this.findActiveNavItem();
  }

  findActiveNavItem(): any {
    for (const navIndex of this.navList) {
      const navItem = navIndex;
      const pathLength = location.pathname.replace(navItem.path, '').split('/')
        .length;
      if (
        location.pathname !== navItem.hidePath &&
        location.pathname.includes(navItem.path) &&
        pathLength <= 2
      ) {
        this.loadComponent(navItem.toolbarComponent);
        return;
      }
    }
    this.loadComponent(undefined);
  }

  getActiveClass($vent: any): void {
    if (this.navDesign && this.navDesign.styleActiveLink) {
      this.activeLink = this.navDesign.styleActiveLink;
    }
  }

  loadComponent(dynamicComponent?: any): void {
    const viewContainerRef = this.loadCompHelpDirective?.viewContainerRef;
    viewContainerRef?.clear();

    if (typeof dynamicComponent !== 'undefined') {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
        dynamicComponent as any
      );
      const componentRef = viewContainerRef?.createComponent(componentFactory);
    }
  }
}
