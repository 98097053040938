import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import {
  WorkflowModelComponent,
  WorkflowNode,
} from '../../models/workflow-model';
@Component({
  selector: 'gdx-node',
  templateUrl: './node.component.html',
})
export class NodeComponent implements OnInit {
  xOffset = 0;
  yOffset = 0;

  @Input() node: any = WorkflowNode;
  @Input() container: any;
  @Input() gridSize = 1;

  @Input() workflow: any = WorkflowModelComponent;
  @Output() connectorReleaseEvent = new EventEmitter();
  @Output() moveEvent = new EventEmitter();

  @ViewChild('nodeView') nodeViewRef: any;

  ngOnInit(): void {
    this.xOffset = this.node.x || 0;
    this.yOffset = this.node.y || 0;
  }

  onMove(e: any): void {
    if (this.workflow.draggable) {
      this.node.x = e.x;
      this.node.y = e.y;
      this.moveEvent.emit(e.y);
    }
  }

  onRelease(e: any): void {
    // Snap to grid on release
    if (this.workflow.draggable) {
    }
  }

  connectorSelected(event: any): void {
    this.workflow.startCreatingConnection(this.sideOf(event.target), this.node);
  }

  connectorReleased(event: any): void {
    this.workflow.finishCreatingConnection(
      this.sideOf(event.target),
      this.node
    );

    this.connectorReleaseEvent.emit('event');
  }

  nodeDeleted(): void {
    this.connectorReleaseEvent.emit();
  }

  sideOf(target: HTMLElement): 'top' | 'left' | 'bottom' | 'right' | undefined {
    const sideClass = Array.from(target.classList).find(
      (cl) => cl.toString().indexOf('connector-') === 0
    );
    if (sideClass) {
      const side = sideClass?.toString().replace('connector-', '');
      if (['top', 'left', 'right', 'bottom'].indexOf(side) >= 0) {
        return side as 'top' | 'left' | 'right' | 'bottom';
      }
      return undefined;
    }
    return undefined;
  }
}
