import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'shortenText',
})
export class ShortenTextPipe implements PipeTransform {
  constructor() {}
  transform(value: string, limit: number): string {
    if (value.length > limit) {
      return value.substring(0, limit - 3) + '...';
    }
    return value;
  }
}
