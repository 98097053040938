<div [formGroup]="parentForm">
  <p-dropdown
    [id]="id"
    [name]="name"
    [options]="options"
    [formControlName]="control"
    [optionLabel]="optionLabel"
    [(ngModel)]="optionValue"
    optionDisabled="inactive"
    [placeholder]="placeholder"
    [style]="style"
    [panelStyleClass]="panelStyleClass"
    [showClear]="showClear"
    [styleClass]="class"
    [filter]="filter"
    [disabled]="disabled"
    [virtualScroll]="virtualScroll"
    [itemSize]="itemSize"
    (onChange)="dropdownChange($event)"
    (onBlur)="dropdownBlur($event)"
    (onClear)="dropdownClear($event)"
  >
  </p-dropdown>
</div>
