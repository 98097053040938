import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnChanges,
  Input,
} from '@angular/core';
import { IDesign, INavigation, INavList } from './settings-nav.compoent.types';

@Component({
  selector: 'gdx-settings-nav',
  templateUrl: './settings-nav.component.html',
})
export class SettingsNavComponent implements OnInit, OnChanges {
  @Input('navListDetails') navListDetails: INavigation | undefined;
  @Output() selectedItem = new EventEmitter<INavList>();
  navDesign: IDesign = {
    styleElmentList: '',
    styleElementAnchor: '',
    styleActiveLink: '',
  };
  navList: INavList[] = [
    {
      label: '',
      path: '',
      icon: '',
    },
  ];
  activeLink = 'bg-red-800 text-white';
  navListStyle = '';
  navAnchorStyle = '';

  ngOnInit(): void {
    if (this.navListDetails && Object.keys(this.navListDetails).length) {
      this.navDesign = this.navListDetails.design
        ? this.navListDetails.design
        : this.navDesign;
      this.navList = this.navListDetails.navList
        ? this.navListDetails.navList
        : this.navList;
      this.navListStyle = this.navDesign.styleElmentList
        ? this.navDesign.styleElmentList
        : this.navAnchorStyle;
      this.navAnchorStyle = this.navDesign.styleElementAnchor
        ? this.navDesign.styleElementAnchor
        : this.navAnchorStyle;
      this.activeLink = this.navDesign.styleActiveLink
        ? this.navDesign.styleActiveLink
        : this.activeLink;
    }
  }

  ngOnChanges(): void {
    if (this.navListDetails && Object.keys(this.navListDetails).length) {
      this.navDesign = this.navListDetails.design
        ? this.navListDetails.design
        : this.navDesign;
      this.navList = this.navListDetails.navList
        ? this.navListDetails.navList
        : this.navList;
      this.navListStyle = this.navDesign.styleElmentList
        ? this.navDesign.styleElmentList
        : this.navAnchorStyle;
      this.navAnchorStyle = this.navDesign.styleElementAnchor
        ? this.navDesign.styleElementAnchor
        : this.navAnchorStyle;
      this.activeLink = this.navDesign.styleActiveLink
        ? this.navDesign.styleActiveLink
        : this.activeLink;
    }
  }

  getActiveClass(event: any): void {
    if (this.navDesign && this.navDesign.styleActiveLink) {
      this.activeLink = this.navDesign.styleActiveLink;
    }
    this.selectedItem.emit(event);
  }
}
