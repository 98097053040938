import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { add } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  modelAddedItSubject: BehaviorSubject<any>;
  onHide: Subject<boolean>;
  onToggle: Subject<boolean>;
  onToggleShow: Subject<boolean>;
  showExplorer: Subject<boolean>;
  onUserToggle: Subject<boolean>;
  onHelpToggle: Subject<boolean>;
  showHidePanel: Subject<boolean>;
  showHideStatePanel: Subject<boolean>;
  showHideLeftPanel: Subject<boolean>;
  private events: any;
  dataListeners: any = {};
  private data: any;
  private filterData = new BehaviorSubject('');
  currentMessage = this.filterData.asObservable();
  private subject = new Subject<any>();
  addEditSubject = new BehaviorSubject('');
  dataAdd = new BehaviorSubject('');
  editComputeDetailSubject = new BehaviorSubject('');
  editCustomDsSubject = new BehaviorSubject('');
  getchanged = new BehaviorSubject('');
  scopeGetchanged = new BehaviorSubject('');
  modelAddEditSubject: BehaviorSubject<any>;
  modelAddEditCustomSubject: BehaviorSubject<any>;
  generateCodeSubject: Subject<any>;
  generateCodeParamsSubject: Subject<any>;
  dataQualityFormList: Subject<any>;
  qualityReportjobInfo: Subject<any>;
  interpolate: BehaviorSubject<any>;
  getSelectedRole = new BehaviorSubject<any>({});
  isNavigationLoad: Subject<boolean>;
  isAppLoad: Subject<boolean>;
  homeDashboard = new BehaviorSubject('');
  appsOnboardingStatus = new BehaviorSubject('');
  stateDashboardSubject: BehaviorSubject<any>;
  storagechanged = new BehaviorSubject('');
  closePanel: BehaviorSubject<any>;
  appStorageTypeChange: BehaviorSubject<any>;
  storageModeChange: BehaviorSubject<any>;
  appOnboardingAddEdit: BehaviorSubject<any>;
  appOnboardingAddCompute: BehaviorSubject<any>;

  constructor() {
    this.onHide = new Subject<boolean>();
    this.onToggle = new Subject<boolean>();
    this.onToggleShow = new Subject<boolean>();
    this.showExplorer = new Subject<boolean>();
    this.showHidePanel = new Subject<boolean>();
    this.showHideLeftPanel = new Subject<boolean>();
    this.showHideStatePanel = new Subject<boolean>();
    this.addEditSubject = new BehaviorSubject<any>({
      mode: 'add',
    });
    this.dataAdd = new BehaviorSubject<any>({
      mode: 'added',
    });
    this.editComputeDetailSubject = new BehaviorSubject<any>({
      id: '',
    });
    this.editCustomDsSubject = new BehaviorSubject<any>({
      id: '',
    });
    this.getchanged = new BehaviorSubject<any>({
      mode: 'added',
    });
    this.scopeGetchanged = new BehaviorSubject<any>({
      mode: 'added',
    });
    this.storagechanged = new BehaviorSubject<any>({
      mode: 'added',
    });
    this.homeDashboard = new BehaviorSubject<any>({
      data: '',
    });

    this.modelAddEditSubject = new BehaviorSubject({
      mode: 'Edit',
      objId: '',
    });

    this.modelAddEditCustomSubject = new BehaviorSubject({
      mode: 'Edit',
      objId: '',
    });

    this.stateDashboardSubject = new BehaviorSubject({
      wfState: '',
      type: '',
    });

    this.closePanel = new BehaviorSubject<any>('');
    this.isNavigationLoad = new Subject<boolean>();
    this.isAppLoad = new Subject<boolean>();
    this.onUserToggle = new Subject<boolean>();
    this.onHelpToggle = new Subject<boolean>();
    this.generateCodeSubject = new Subject<boolean>();
    this.generateCodeParamsSubject = new Subject<boolean>();
    this.dataQualityFormList = new Subject<boolean>();
    this.qualityReportjobInfo = new Subject<any>();
    this.data = {};
    this.events = from(this.subject);
    this.events.subscribe(({ name, args }: any) => {
      if (this.dataListeners[name]) {
        for (const listener of this.dataListeners[name]) {
          listener(...args);
        }
      }
    });
    this.modelAddedItSubject = new BehaviorSubject({
      mode: 'Add',
      objId: '',
      state: 'Draft',
    });

    this.interpolate = new BehaviorSubject({
      code: '',
    });

    this.appsOnboardingStatus = new BehaviorSubject<any>({
      mode: 'add',
    });

    this.appStorageTypeChange = new BehaviorSubject({
      name: '',
      id: '',
      status: false,
    });

    this.storageModeChange = new BehaviorSubject({
      mode: 'Edit',
      objId: '',
    });

    this.appOnboardingAddEdit = new BehaviorSubject({
      mode: 'add',
      id: '',
    });
    this.appOnboardingAddCompute = new BehaviorSubject({
      mode: 'add',
    });
  }

  isExplorerChange(message: boolean): void {
    this.showExplorer.next(message);
  }
  Ischange(message: any): void {
    this.onHide.next(message);
  }
  Istoggle(message: any): void {
    this.onToggle.next(message);
  }
  isUserToggle(message: any): void {
    this.onUserToggle.next(message);
  }
  ishelpToggle(message: any): void {
    this.onHelpToggle.next(message);
  }
  IstoggleShow(message: any): void {
    this.onToggleShow.next(message);
  }

  changeMessage(message: string): void {
    this.filterData.next(message);
  }

  getData(key: any): any {
    return this.data[key];
  }
  setData(key: any, value: any): void {
    this.data[key] = value;
  }

  on(name: any, listener: any): void {
    if (!this.dataListeners[name]) {
      this.dataListeners[name] = [];
    }
    this.dataListeners[name] = [];
    this.dataListeners[name].push(listener);
  }

  broadcast(name: any, ...args: any[]): void {
    this.subject.next({
      name,
      args,
    });
  }
  removeListener(arr: any[]): void {
    for (const item of arr) {
      delete this.dataListeners[item];
    }
  }

  addeditnotify(selectmode: any): void {
    this.addEditSubject.next(selectmode);
  }

  editComputeDetails(id: any): void {
    this.editComputeDetailSubject.next(id);
  }

  editCustomDs(id: any): void {
    this.editCustomDsSubject.next(id);
  }

  changeMappingMode(obj: { [k: string]: string | undefined }): void {
    this.modelAddedItSubject.next(obj);
  }

  changeInterpolationMode(obj: { [k: string]: string }): void {
    this.modelAddedItSubject.next(obj);
  }

  getChanged(mode: any): void {
    this.getchanged.next(mode);
  }
  scopeChanged(mode: any): void {
    this.scopeGetchanged.next(mode);
  }
  homedashboard(data: any): void {
    this.homeDashboard.next(data);
  }
  dataAdded(mode: any): void {
    this.dataAdd.next(mode);
  }

  closeSidePanel(page: any): void {
    this.closePanel.next(page);
  }

  changeLibraryMode(obj: { [k: string]: string | undefined }): void {
    this.modelAddEditSubject.next(obj);
  }

  changeCustomDsMode(obj: { [k: string]: string | undefined }): void {
    this.modelAddEditCustomSubject.next(obj);
  }

  isGeneratedCode(message: any): void {
    this.generateCodeSubject.next(message);
  }

  isGeneratedCodeParams(message: any): void {
    this.generateCodeParamsSubject.next(message);
  }

  isDataQualityForm(message: any): void {
    this.dataQualityFormList.next(message);
  }

  isQualityReportJobInfo(message: any): void {
    this.qualityReportjobInfo.next(message);
  }

  panelStatus(status: any): void {
    this.showHidePanel.next(status);
  }

  leftPanelStatus(status: any): void {
    this.showHideLeftPanel.next(status);
  }

  interpolateCodeChange(code: string): void {
    this.interpolate.next(code);
  }
  swithRole(mode: any): void {
    this.getSelectedRole.next(mode);
  }

  isNavigationLoaded(message: any): void {
    this.isNavigationLoad.next(message);
  }

  isAppLoaded(isLoad: any): void {
    this.isAppLoad.next(isLoad);
  }

  appsOnboarding(mode: any): void {
    this.appsOnboardingStatus.next(mode);
  }

  stateDashboard(obj: { [k: string]: string }): void {
    this.stateDashboardSubject.next(obj);
  }
  stateStatus(status: any): void {
    this.showHideStatePanel.next(status);
  }
  storageState(mode: any): void {
    this.storagechanged.next(mode);
  }

  appsStorageUpdate(obj: { [k: string]: string | boolean | undefined }): void {
    this.appStorageTypeChange.next(obj);
  }
  changeStorageMode(obj: { [k: string]: string | undefined }): void {
    this.storageModeChange.next(obj);
  }

  appAddEditMode(obj: { [k: string]: string | boolean | undefined }): void {
    this.appOnboardingAddEdit.next(obj);
  }

  appAddCompute(mode: any): void {
    this.appOnboardingAddCompute.next(mode);
  }

  currentTimestampExport(): any {
    const timestamp = new Date()
      .toISOString()
      .replace('Z', '')
      .replace('T', '_')
      .replace(/:/g, '-')
      .split('.')[0];
    return timestamp;
  }
}
