import { Injectable } from '@angular/core';
import { SharedService } from '@core-services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private classificationFilter;
  private uploadLimitInBytes;
  private inspectionDownloadLimit;
  private exampleImageName;
  private sendForInspectionViType;
  private sendForInspectionViMailId;
  private zoom_percent;
  private zoom_levels;
  private defect_type;
  private smartImageManagerUrl;
  private storageUrl;
  private sort_items;
  private messages;
  private encrKey = '';
  private fileExtensions = {};
  private processAttachmentPath = '';
  appConfigData: any;

  constructor(private sharedService: SharedService) {}

  public setAppConfigData(appConfigData: any): void {
    this.classificationFilter = appConfigData?.classification_filter;
    this.uploadLimitInBytes = appConfigData?.uploadLimitInBytes;
    this.exampleImageName = appConfigData?.exampleImageName;
    this.sendForInspectionViType = appConfigData?.sendForInspectionViType;
    this.sendForInspectionViMailId = appConfigData?.sendForInspectionViMailId;
    this.zoom_percent = appConfigData?.zoom_percent;
    this.zoom_levels = appConfigData?.zoom_levels;
    this.defect_type = appConfigData?.defect_type;
    this.smartImageManagerUrl = appConfigData?.smartImageManagerUrl;
    this.storageUrl = appConfigData?.storageUrl;
    this.sort_items = appConfigData?.sort_items;
    this.messages = appConfigData?.messages;
    this.encrKey = appConfigData?.encrKey;
    this.fileExtensions = appConfigData?.extensions;
    this.inspectionDownloadLimit = appConfigData.pdfDownloadLimit;
    this.processAttachmentPath = appConfigData.processAttachmentPath;
    this.sharedService.setAppConfig(appConfigData);
  }

  public getClassificationFilter(): any {
    return this.classificationFilter;
  }
  public getUploadLimitBytes(): any {
    return this.uploadLimitInBytes;
  }
  public getSampleImage(): any {
    return this.exampleImageName;
  }
  public getInspectionMailType(): any {
    return this.sendForInspectionViType;
  }
  public getInspectionMailId(): any {
    return this.sendForInspectionViMailId;
  }
  public getZoomPercentData(): any {
    return this.zoom_percent;
  }
  public getZoomLevels(): any {
    return this.zoom_levels;
  }
  public getDefectTypes(): any {
    return this.defect_type;
  }
  public getSortItems(): any {
    return this.sort_items;
  }
  public getStorageUrl(): any {
    return this.storageUrl;
  }
  public getSmartImageManagerUrl(): any {
    return this.smartImageManagerUrl;
  }
  public getMessages(): any {
    return this.messages;
  }
  public getEncryptionKey(): string {
    return this.encrKey;
  }
  public getFileExtensions(): any {
    return this.fileExtensions;
  }
  public getInspectionDownloadLimit(): any {
    return this.inspectionDownloadLimit;
  }
  public getProcessAttachmentPath(): any {
    return this.processAttachmentPath;
  }
}
