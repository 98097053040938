import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import {
  WorkflowNode,
  WorkflowConnection,
  WorkflowModelComponent,
} from '../../models/workflow-model';

@Component({
  selector: 'gdx-workflow',
  templateUrl: './workflow.component.html',
})
export class WorkflowComponent implements OnInit, OnChanges {
  @Input() public nodes: WorkflowNode[] = [];
  @Input() public connections: WorkflowConnection[] = [];
  @Input() workflow: WorkflowModelComponent = new WorkflowModelComponent();
  @Input() gridSize = 10;
  @Input() lineColor = 'darkgray';
  // Do not allow connection from same node it itself
  // and also prevent circular dependency
  // Example: if A → B. them B → A is not allowed.
  // However, B → C → A will be allowed
  @Input() allowCircular = false;
  @Output() connectionNodeChange = new EventEmitter();
  maxHeight: any = '450px';

  ngOnInit(): void {
    this.workflow = new WorkflowModelComponent();
    this.workflow.allowCircular = this.allowCircular;
    this.workflow.nodes = this.nodes;
    this.workflow.connections = this.connections;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.workflow.nodes = changes.nodes
      ? changes.nodes.currentValue
      : this.nodes;
    this.workflow.connections = changes.connections
      ? changes.connections.currentValue
      : this.connections;
    let h: any = 450;
    if (this.nodes.length) {
      this.nodes.forEach((element: any) => {
        h = element.y > h ? element.y : h;
      });
    }
    this.maxHeight = h === 450 ? '450px' : h + 100 + 'px';
  }

  discardConnection(): void {
    this.workflow.discardCurrentConnection();
  }

  getClickEvent(e: WorkflowConnection | WorkflowNode): any {
    if (e.click) {
      return e.click(e);
    }
  }
  nodeMoveEvent(y: any): void {
    if (
      y > Number(this.maxHeight.substring(0, this.maxHeight.length - 2) - 100)
    ) {
      this.maxHeight = y + 100 + 'px';
    }
  }

  getReleaseEvent(): void {
    const nodeData = {
      nodes: { ...this.nodes },
      connections: { ...this.connections },
    };
    this.connectionNodeChange.emit(nodeData);
  }
}
