import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PageNotFoundComponent, HeaderComponent } from './components/';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { PageUnderConstructionComponent } from './components/page-under-construction/page-under-construction.component';
import { InputBoxComponent } from './components/input-box/input-box.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { TextAreaComponent } from './components/text-area/text-area.component';
import { ButtonComponent } from './components/button/button.component';
import { FooterComponent } from './components/footer/footer.component';
import { MultiselectDropdownComponent } from './components/multiselect-dropdown/multiselect-dropdown.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { InlineSVGModule } from 'ng-inline-svg';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ColorPickerModule } from 'primeng/colorpicker';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { LoadCompHelperDirective } from './directives/load-comp-helper.directive';
import { TabulatorTableComponent } from './components/tabulator-table/tabulator-table.component';
import { SelectButtonComponent } from './components/select-button/select-button.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { AppLauncherComponent } from './components/app-launcher/app-launcher.component';
import { SubNavComponent } from './components/sub-nav/sub-nav.component';
import { CardDisplayComponent } from './components/card-display/card-display.component';
import { LabelBadgeComponent } from './components/label-badge/label-badge.component';

import { ChangerolesComponent } from './components/changeroles/changeroles.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { SubmitModalComponent } from './components/submit-modal/submit-modal.component';
// TODO: Fix imports by creating index files in folders, check how implemented GDX module, us as example
import { GridUserComponent } from './components/grid-user/grid-user.component';
import { DropdownWithDeleteComponent } from './components/dropdown-with-delete/dropdown-with-delete.component';
import { SearchPipe } from './pipes/search.pipe';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { HtmlPipe } from './pipes/html.pipe';
import { CamelCase } from './pipes/camelcase.pipe';
import { SasTokenPipe } from './pipes/sas-token.pipe';
import { AngularDraggableDirective } from './directives/draggable.directive';
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { NodeComponent } from './components/node/node.component';
import { SvgLineComponent } from './components/svg-line/svg-line.component';
import { WorkflowComponent } from './components/workflow/workflow.component';
import { SettingsNavComponent } from './components/settings-nav/settings-nav.component';
import { NoRoleTagComponent } from './components/no-role-tag/no-role-tag.component';
import { PermissionDeniedComponent } from './components/permission-denied/permission-denied.component';
import { GeniusDataModalComponent } from './components/genius-data-modal/genius-data-modal.component';
import { ToastComponent } from './components/toast/toast.component';
import { DownloadDropdownComponent } from './components/download-dropdown/download-dropdown.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NoAppComponent } from './components/no-app/no-app.component';
import { AuditFilterComponent } from './components/audit-filter/audit-filter.component';
import { InspectionFilterComponent } from './components/inspection-filter/inspection-filter.component';
import { PerformanceFilterComponent } from './components/performance-filter/performance-filter.component';
import { CommonFilterComponent } from './components/common-filter/common-filter.component';
import { CardModule } from 'primeng/card';
import { GradingDrawerComponent } from './components/grading-drawer/grading-drawer.component';
import { UserComponent } from './components/user/user.component';
import { PaginatorModule } from 'primeng/paginator';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AngularSvgIconPreloaderModule } from 'angular-svg-icon-preloader';
import { MatIconModule } from '@angular/material/icon';
import { ExperimentDrawerComponent } from './components/experiment-drawer/experiment-drawer.component';
import { SourceHierarchyComponent } from './components/source-hierarchy/source-hierarchy.component';
import { MatTreeModule } from '@angular/material/tree';
import { DialogTemplateComponent } from './components/dialog-template/dialog-template.component';
import { CommonSnackbarUiComponent } from './components/common-snackbar-ui/common-snackbar-ui.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CreateButtonComponent } from './components/create-button/create-button.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { CustomDropdownComponent } from './components/custom-dropdown/custom-dropdown.component';
import { TooltipModule } from 'primeng/tooltip';
import { DateTransformPipe } from './pipes/date-transform.pipe';
import { ShortenTextPipe } from './pipes/shorten-text.pipe';
import { ConcatTextPipe } from './pipes/concat-text.pipe';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { DateLocalPipe } from './pipes/date-local.pipe';
import { ESignComponent } from './components/e-sign/e-sign.component';

@NgModule({
  declarations: [
    CommonFilterComponent,
    PerformanceFilterComponent,
    InspectionFilterComponent,
    AuditFilterComponent,
    ExperimentDrawerComponent,
    AppLauncherComponent,
    PageNotFoundComponent,
    PageUnderConstructionComponent,
    HeaderComponent,
    InputBoxComponent,
    DropdownComponent,
    TextAreaComponent,
    ButtonComponent,
    FooterComponent,
    MultiselectDropdownComponent,
    RadioButtonComponent,
    NavigationComponent,
    LoadCompHelperDirective,
    AutocompleteComponent,
    DatePickerComponent,
    TabulatorTableComponent,
    SelectButtonComponent,
    SubNavComponent,
    CardDisplayComponent,
    LabelBadgeComponent,
    ChangerolesComponent,
    CheckboxComponent,
    GridUserComponent,
    DropdownWithDeleteComponent,
    SearchPipe,
    ClickOutsideDirective,
    HtmlPipe,
    CamelCase,
    SasTokenPipe,
    AngularDraggableDirective,
    StopPropagationDirective,
    NodeComponent,
    SvgLineComponent,
    WorkflowComponent,
    SettingsNavComponent,
    NoRoleTagComponent,
    PermissionDeniedComponent,
    GeniusDataModalComponent,
    ToastComponent,
    DownloadDropdownComponent,
    LoaderComponent,
    NoAppComponent,
    UserComponent,
    GradingDrawerComponent,
    ToggleButtonComponent,
    LoaderComponent,
    SourceHierarchyComponent,
    DialogTemplateComponent,
    CommonSnackbarUiComponent,
    CreateButtonComponent,
    SubmitModalComponent,
    CustomDropdownComponent,
    DateTransformPipe,
    ShortenTextPipe,
    ConcatTextPipe,
    DateLocalPipe,
    ESignComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DropdownModule,
    CalendarModule,
    MultiSelectModule,
    AutoCompleteModule,
    SelectButtonModule,
    InlineSVGModule,
    ColorPickerModule,
    CardModule,
    PaginatorModule,
    MatIconModule,
    MatTreeModule,
    MatDialogModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    TooltipModule,
    AngularSvgIconModule.forRoot(),
    AngularSvgIconPreloaderModule.forRoot({
      configUrl: '../../assets/icons/index.json',
    }),
  ],
  exports: [
    CommonFilterComponent,
    PerformanceFilterComponent,
    InspectionFilterComponent,
    PageUnderConstructionComponent,
    AuditFilterComponent,
    ExperimentDrawerComponent,
    TranslateModule,
    FormsModule,
    HeaderComponent,
    RouterModule,
    DropdownModule,
    AutoCompleteModule,
    MultiSelectModule,
    InputBoxComponent,
    DropdownComponent,
    TextAreaComponent,
    ButtonComponent,
    FooterComponent,
    GradingDrawerComponent,
    MultiselectDropdownComponent,
    RadioButtonComponent,
    NavigationComponent,
    AutocompleteComponent,
    DatePickerComponent,
    TabulatorTableComponent,
    LoadCompHelperDirective,
    SubNavComponent,
    SelectButtonComponent,
    SelectButtonModule,
    CardDisplayComponent,
    LabelBadgeComponent,
    ChangerolesComponent,
    CheckboxComponent,
    GridUserComponent,
    DropdownWithDeleteComponent,
    SearchPipe,
    ClickOutsideDirective,
    HtmlPipe,
    CamelCase,
    SasTokenPipe,
    AngularDraggableDirective,
    StopPropagationDirective,
    NodeComponent,
    SvgLineComponent,
    WorkflowComponent,
    InlineSVGModule,
    SettingsNavComponent,
    NoRoleTagComponent,
    PermissionDeniedComponent,
    GeniusDataModalComponent,
    ToastComponent,
    DownloadDropdownComponent,
    LoaderComponent,
    NoAppComponent,
    CardModule,
    UserComponent,
    PaginatorModule,
    ToggleButtonComponent,
    LoaderComponent,
    SourceHierarchyComponent,
    DialogTemplateComponent,
    CommonSnackbarUiComponent,
    CreateButtonComponent,
    SubmitModalComponent,
    CustomDropdownComponent,
    TooltipModule,
    DateTransformPipe,
    ShortenTextPipe,
    ConcatTextPipe,
    DateLocalPipe,
    ESignComponent,
  ],
})
export class SharedModule {}
