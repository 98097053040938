<div class="relative flex w-full flex-wrap" [formGroup]="parentForm">
  <span
    *ngIf="icon"
    class="z-10 h-7 w-8 mt-1 leading-snug font-normal absolute text-center bg-white text-base flex items-center justify-center"
    [class]="iconClass"
    (mouseover)="iconMouseOver()"
    (mouseout)="iconMouseOut()"
  >
    <button (click)="iconClick($event)">
      <img class="w-4" [class]="iconClass" [src]="iconSource" />
    </button>
  </span>
  <input
    #inputBox
    [type]="type"
    [id]="id"
    [name]="name"
    [min]="min"
    [max]="max"
    [step]="step"
    [formControlName]="control"
    [placeholder]="placeholder"
    [attr.disabled]="disabled ? '' : null"
    [readonly]="readonly"
    [pattern]="pattern"
    [checked]="checked"
    class="input-control"
    [class]="textClass"
    [ngClass]="{
      'border-red-500 focus:border-red-500':
        parentForm.controls[control].invalid &&
        parentForm.controls[control].dirty
    }"
    (change)="valueChange($event)"
    (keypress)="keyPress($event)"
    (keyup)="keyUp($event)"
    (keydown)="keyDown($event)"
    (input)="inputChange($event)"
    (keyup.enter)="keyEnter($event)"
    (dblclick)="doubleClick($event)"
    (blur)="blur($event)"
    (paste)="paste($event)"
  />
</div>
