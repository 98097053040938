const INSPECTION_LABELS = {
  TRAY: 'Tray :',
  KIT: 'Kit :',
  HEADER: 'Inspection Report',
};
const COMMON_LABELS = {
  DOWNLOADAS: 'Download as ',
  CSV: 'CSV',
  EXCEL: 'Excel',
  PDF: 'PDF',
  APPLIEDFILTER: 'Applied Filter',
  INSPECTEDBY: 'inspectedBy',
  KIT_ID: 'kitId',
  TRAY_ID: 'trayId',
};
const AUDIT_LABELS = {
  HEADER: 'Audit Report',
  TIME: 'time',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
};
const AUDIT_FILTER_LABELS = {
  transactionStatus: 'Transaction Status',
  category: 'Category',
  selectPeriod: 'Select Period',
  user: 'User',
  esignReason: 'Esign Reason',
  role: 'Role',
  event: 'Event',
  advancedFilters: 'Advanced Filters',
  action: 'Action',
  filterBy: 'FILTER BY',
};
const PERFORMANCE_LABELS = {
  HEADER: 'Performance Report',
};
const MESSAGES = {
  SELECTION_PROCEED: 'Please select kit and tray to proceed',
  PDF_NOT_SUPPORTED: 'This browser does not support PDF!',
  DOWNLOAD_LIMITED: 'Download limited to ',
};
const BTN_DOWNLOAD_IMG = 'btn_download_image';
const AISI_CANADA = 'AISI-CANADA';
const REPORTS_CONSTANTS = {
  period: 'Period',
  user: 'User',
  status: 'Status',
  category: 'Category',
  role: 'Role',
  event: 'Event',
  action: 'Action',
  table: 'Table',
  kit: 'Kit',
  tray: 'Tray',
  default_period: 7,
  start_date: 'startDate',
  end_date: 'endDate',
};
const TNT_UPLOAD = {
  SHEETNAME: 'TNTMasterData',
  HEADERS: [
    'batch_no',
    'RFID',
    'material',
    'material_desc',
    'Set Type',
    'Set Brand',
    'division',
    'ship_to_ucn',
    'indv_cust_nm',
    'terr_id',
    'terr_nm',
    'reg_id',
    'reg_nm',
    'area_id',
    'area_nm',
    'BLE Tag associated',
    'In SAP?',
  ],
};
export {
  INSPECTION_LABELS,
  COMMON_LABELS,
  AUDIT_LABELS,
  MESSAGES,
  PERFORMANCE_LABELS,
  AUDIT_FILTER_LABELS,
  BTN_DOWNLOAD_IMG,
  AISI_CANADA,
  REPORTS_CONSTANTS,
  TNT_UPLOAD,
};
