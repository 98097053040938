<li
  class="w-8 hover:bg-red-800 h-full flex justify-center"
  *ngFor="let item of navList; let i = index"
>
  <a
    [routerLink]="[item.path]"
    (click)="getActiveClass(item)"
    [ngClass]="navAnchorStyle"
    [routerLinkActive]="activeLink"
    class="w-8 hover:bg-red-800 pb-0.5 flex items-center justify-center"
  >
    <span
      *ngIf="item && item.icon && item.icon.indexOf('.svg') != -1"
      [inlineSVG]="item.icon"
      class="inline inlineSVG"
    ></span>
    <img
      *ngIf="item && item.icon && item.icon.indexOf('.svg') == -1"
      [src]="item.icon"
      class="inline"
    />
  </a>
</li>
