export const AISI_ROLES = {
  PLATFORM_ADMIN: 'Platform Admin',
  PLATFORM_APPROVER: 'Platform Approver',
  PLATFORM_REVIEWER: 'Platform Reviewer',
  SITE_ADMIN: 'Site Admin',
  SITE_OPERATOR: 'Site Operator',
  QUALITY_APPROVER: 'Quality Approver',
  QUALITY_REVIEWER: 'Quality Reviewer',
  SALES_ADMIN: 'Sales Admin',
  SALES_OPERATOR: 'Sales Operator',
  SPD_TECH: 'SPD Tech',
};

export const PLATFORM_LOGO_NAMES = {
  SIP: 'SIP',
  AISI: 'AISI',
  TNT: 'TnT',
};

export const APPNAME_KEYS = {
  TNT: 'TRACK',
  AISI: 'AISI',
};

export const SELECTED_APP_NAME = 'selectedAppName';

export const DAYS = 'days';
export const MONTHS = 'months';
export const RELOAD_TIME = 720;
export const RELOAD_MESSAGE = {
  WARN_MESSAGE: 'Warning',
  MAIN_MESSAGE: 'Session Expired. Please reload the page.',
};
export const DIALOG_INTERVAL = 2000;
export const DATE_TIME_FORMAT = 'lll';

export const toastParams = {
  duration: 4000,
  speed: 1000,
  positionX: 'end',
  positionY: 'top',
};

export const paginationConfig = {
  rowOptions: [10, 20, 40, 100, 200],
  initialRows: 20,
  initialPage: 1,
  pageLinkSize: 5,
  firstPage: 0,
};

export const CONSTANTS = {
  train: 'TRAIN',
};

export const fileTypes = {
  xlsx: 'xlsx',
  csv: 'csv',
};

export const multiSelect = {
  ITEM_SIZE: '50',
};

export const singleSelect = {
  ITEM_SIZE: '50',
};

export const accessDenied = {
  NL: 'NL',
  NO_ROLE: 'No Role',
};

export const debounceTimeValues = {
  manageUserAssignmentSearchDebounceTime: 500,
};


export const mailFormatRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
