<div [formGroup]="parentForm">
  <p-calendar
    #myStartCalendar
    [id]="id"
    [name]="name"
    [formControlName]="control"
    (onSelect)="selectedDate($event)"
    [styleClass]="class"
    [inputStyleClass]="inputStyleClass"
    [disabled]="disabled"
    [required]="required"
    [placeholder]="placeholder"
    [yearNavigator]="yearNavigator"
    [showIcon]="showIcon"
    [showTime]="showTime"
    [timeOnly]="timeOnly"
    [hideOnDateTimeSelect]="hideOnDateTimeSelect"
    [panelStyleClass]="panelStyleClass"
    [monthNavigator]="monthNavigator"
    [readonlyInput]="readonlyInput"
    [dateFormat]="dateFormat"
    [selectionMode]="selectionMode"
    [yearRange]="yearRange"
    [view]="view"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [baseZIndex]="baseZIndex"
    appendTo="body"
  >
    <p-footer *ngIf="isFooter">
      <div class="absolute inset-x-0 m-2 pt-1 border-t-2">
        <div class="float-left ml-3 w-20">
          <gdx-button
            [id]="'btn_filter-apply'"
            [type]="btnType.reset"
            [text]="clear"
            (clicked)="clearDateRange()"
          >
          </gdx-button>
        </div>
        <div class="float-right mr-3 w-20">
          <gdx-button
            [id]="'btn_filter-apply'"
            [type]="btnType.success"
            [text]="apply"
            (clicked)="applyDateRange()"
          >
          </gdx-button>
        </div></div
    ></p-footer>
  </p-calendar>
</div>
