import {
  Component,
  Input,
  OnInit,
  Output,
  OnChanges,
  EventEmitter,
  SimpleChanges,
  ChangeDetectorRef,
  HostBinding,
} from '@angular/core';
import { btnTypes } from '../../enum/button';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'gdx-button',
  templateUrl: './button.component.html',
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input('id') id = '';
  @Input('text') text = '';
  @Input('type') type = '';
  @Input('icon') icon = '';
  @Input('iconHover') iconHover = '';
  @Input('disabled') disabled = false;
  @Input('tooltip') tooltip = '';
  @Input('control') control = '';
  @Input('parentForm') parentFosrm: FormGroup = new FormGroup({});
  @Input('iconClass') iconClass = 'w-5';
  @Output('clicked') clicked = new EventEmitter();
  @Output('clickEvent') clickEvent = new EventEmitter();
  public iconSource = '';
  public class = '';
  constructor(private ref: ChangeDetectorRef) {}

  @HostBinding('style.pointer-events') get textColor(): string {
    if (this.disabled) {
      return 'none';
    } else {
      return 'auto';
    }
  }
  ngOnInit(): void {
    this.iconSource = this.icon;
    this.class =
      'font-sans text-sm  rounded font-medium focus:outline-none disabled:opacity-50 disabled:pointer-events-none';
    switch (this.type) {
      case btnTypes.primary:
        this.class +=
          ' pt-0 pb-0.5 px-1.5   border border-gray-300 w-full h-full bg-blue-500 border-transparent text-white';
        this.iconSource = this.icon = this.iconHover;
        break;
      case btnTypes.reset:
        this.class +=
          ' pt-0 pb-0.5 px-1.5   border border-gray-500 w-full h-full bg-gray-500 border-transparent text-white';
        this.iconSource = this.icon = this.iconHover;
        break;
      case btnTypes.success:
        this.class +=
          ' pt-0 pb-0.5 px-1.5 border border-green-500 w-full h-full bg-green-500 border-transparent text-white';
        this.iconSource = this.icon = this.iconHover;
        break;
      case btnTypes.submit:
        this.class +=
          ' py-1.5 px-4 text-white bg-green w-full h-full border-2 border-transparent';
        break;
      case btnTypes.test:
        this.class +=
          ' py-0.5 px-1.5 text-center text-white bg-trueGray-dark w-full h-full border-2 border-transparent';
        break;
      case btnTypes.approve:
        this.class +=
          ' py-1.5 px-4 text-center text-green bg-white w-full h-full border-2 border-green';
        break;
      case btnTypes.reject:
        this.class +=
          ' pt-0 pb-0.5 px-1.5 text-red-500 bg-white w-full h-full border border-red';
        break;
      case btnTypes.image:
        this.class =
          ' w-full rounded font-medium focus:outline-none  disabled:opacity-50';
        this.iconClass = ' w-7 rounded';
        break;
      case btnTypes.rule:
        this.class +=
          ' py-1 px-2 text-center text-black bg-blue-100 w-full h-full';
        break;
      case btnTypes.outline:
        this.class +=
          ' py-1 px-2 text-center text-black border border-gray-200 w-full h-full text-md';
        break;
      case btnTypes.deprecated:
        this.class += ' line-through';
        break;
      case btnTypes.icons:
        this.class += ' text-center border-none w-full h-full text-md';
        break;
      case btnTypes.cancel:
        this.class +=
          'py-1.5 px-4 text-white bg-gray-dark w-full h-full border-2 border-transparent';
        this.iconSource = this.icon = this.iconHover;
        break;
      default:
        break;
    }
  }

  btnClick(event: any): any {
    event.stopPropagation();
    this.clicked.emit(this.id);
  }

  btnClickEvent(event: any): any {
    event.stopPropagation();
    this.clickEvent.emit(event);
  }

  btnMouseOver(): any {
    this.iconSource = this.iconHover ? this.iconHover : this.icon;
  }

  btnMouseOut(): any {
    this.iconSource = this.icon;
  }

  ngOnChanges(change: SimpleChanges): void {
    this.ref.detectChanges();
  }
}
