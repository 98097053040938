<div x-data class="fixed top-0 right-0 p-4 mt-8 z-10 overflow-x-hidden">
  <div *ngFor="let toast of list; let i = index">
    <div
      *ngIf="toast.visible"
      (click)="destroyToast(i)"
      x-transition:enter=" "
      x-transition:enter-start="transform opacity-0 translate-y-2"
      x-transition:enter-end="transform opacity-100"
      x-transition:leave="transition ease-out duration-500"
      x-transition:leave-start="transform translate-x-0 opacity-100"
      x-transition:leave-end="transform translate-x-full opacity-0"
      class="bg-gray-900 bg-gradient-to-r text-white m-6 p-3 rounded mb-3 shadow-lg flex items-center transition ease-in duration-200 translate-x-0 opacity-100 transition-visibility ease-in duration-500 transition-opacity duration-500 ease-in-out"
      [ngClass]="getClass(toast)"
    >
      <div class="capitalize font-bold">{{ toast.type }} ! &nbsp;</div>
      <div>{{ toast.message }}</div>
    </div>
  </div>
</div>
