<ng-container>
  <div class="flex">
    <div
      *ngIf="startInd > 0"
      class="float-left flex justify-center items-center mr-2"
    >
      <button class="focus:outline-none" (click)="scrollLeft()">
        <img src="assets/images/angel-left.svg" alt="backbtn" />
      </button>
    </div>
    <div class="flex overflow-hidden">
      <div
        class="w-8 h-8 rounded-3xl flex justify-center items-center bg-gray-300 text-xs mr-2 cursor-default"
        title="{{ row.fullName }}"
        *ngFor="let row of displayList; let i = index"
      >
        {{ row.shortName }}
      </div>
    </div>
    <div
      class="float-right flex justify-center items-center"
      *ngIf="endInd < totalCount"
    >
      <button class="focus:outline-none" (click)="scrollRight()">
        <img src="assets/images/angel-right.svg" alt="forwardBtn" />
      </button>
    </div>
  </div>
</ng-container>
