import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastComponent } from '@shared/components/toast/toast.component';
import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DialogTemplateComponent } from '@shared/components/dialog-template/dialog-template.component';
import { CommonSnackbarUiComponent } from '@shared/components/common-snackbar-ui/common-snackbar-ui.component';
import { DIALOG_INTERVAL } from '@shared/shared.constant';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  @ViewChild('toaster') toaster!: ToastComponent;
  constructor(public dialog: MatDialog, private snackBar: MatSnackBar) {}

  openModal(
    title: string,
    message: string,
    type: string,
    yes: (result) => void,
    showContinue: boolean = false,
    showDeleteWarning: boolean = false
  ): void {
    console.log('showDeleteWarning', showDeleteWarning, showContinue);
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title,
      message,
      type,
      yes,
      showContinue,
      showDeleteWarning,
    };
    dialogConfig.minWidth = 400;

    const dialogRef = this.dialog.open(DialogTemplateComponent, dialogConfig);
  }
  showSnackBar(message: any, type: any): void {
    this.snackBar.openFromComponent(CommonSnackbarUiComponent, {
      data: {
        message,
        snackType: type,
      },
      duration: DIALOG_INTERVAL,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: 'inner-snack',
    });
  }
}
