import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ImagemetadataService } from '@pages/home-page/services/imagemetadata.service';
import { PlatformAdminService } from '../../../pages/application-page/services/platform-admin.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { WorkspaceService } from '@core-services/workspace.service';
import { CONSTANTS } from '@shared/shared.constant';
import { MESSAGES } from '@pages/home-page/home-page.constants';
const { toast } = require('tailwind-toast');

@Component({
  selector: 'gdx-grading-drawer',
  templateUrl: './grading-drawer.component.html',
  styleUrls: ['./grading-drawer.component.scss'],
})
export class GradingDrawerComponent implements OnInit, OnChanges {
  panZoomController;
  currentZoomLevel: number;
  navigationExtras: any;
  imageDetails: any;
  imageSrc: any;
  screenHeight: number;
  screenWidth: number;

  failedReason: any;
  fetchedComments: string;
  fetchedLabelingValue: string;
  fetchedGradingValue: string;
  fetchedClassifyValue: string;
  imageID: string;

  public gradingFormGroup: FormGroup = new FormGroup({});
  imageListingData: any;
  nextImageIndex: number;
  prevImageIndex: number;
  currentImageIndex: number;

  showDropdown = false;

  selectedZoomPercentage: any = 100;
  isDrawerOpen = false;
  isGradingSelected = true;
  selectedGradeValue = '';
  selectedFMMeasure = '';
  comments = '';
  created: any;
  modified: any;
  isFail = false;
  selectedFailedReason: any = '';
  selected = -1;
  isViewFullScreen = false;
  isExternalStorageRequired = false;
  panInstance: any;
  lineArray: any;
  selectedLineId: any;
  gradingList: any;
  selectedConectionValue;
  chkAisiApp = false;
  allConnections;
  isPresent = false;
  @Output() toggleGrading = new EventEmitter();
  @Output() updateMetadata = new EventEmitter();
  @Input() isPassFail: boolean;
  @Input() isUploadScreen: boolean;
  @Input() isUpdateLineNo: boolean;
  @Input() allFilter: any;
  @Input() isClassify: any;
  @Input() isCreateTotalDataSet: boolean;
  @Input() selectedImagesArray;
  @Input() selectedVideosArray;
  @Input() disableTotalSelected = false;
  videoStatus = true;
  trayIdMatch = true;
  nameLoading = false;

  constructor(
    private imageMetadataService: ImagemetadataService,
    private shared: SharedService,
    private platformAdminService: PlatformAdminService,
    private workspaceService: WorkspaceService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.chkAisiApp = this.shared.checkAisiApp();
    this.gradingFormGroup = this.formBuilder.group({
      datasetName: ['', Validators.required],
      datasetPurpose: [''],
    });
    this.shared.gradingStatusList.subscribe((gradingList) => {
      this.gradingList = gradingList;
    });
    this.shared.failReasonList.subscribe((failedReasonList) => {
      this.failedReason = failedReasonList;
    });
    if (this.isPassFail) {
      this.isGradingSelected = true;
    }
    if (this.allFilter && this.allFilter.Line) {
      this.lineArray = this.allFilter.Line.list;
    }
    if (this.isCreateTotalDataSet) {
      for (const i of this.selectedVideosArray) {
        if (i.status === 'imagesExtracted') {
          this.videoStatus = false;
        }
      }
      if (!this.videoStatus) {
        toast()
          .danger('Warning! ', MESSAGES.NOT_ANNOTATED)
          .with({
            duration: 4000,
            speed: 1000,
            positionX: 'end',
            positionY: 'top',
          })
          .show();

        setTimeout(() => {
          this.onClose();
          this.selectedVideosArray = [];
        }, 2000);
      }
    }
  }
  getAllConnections(): void {
    this.platformAdminService.getAllConnections().subscribe((result: any) => {
      this.allConnections = result.data;
    });
  }
  checkDuplicateName($event): void {
    /* here we pass dataset name to get dataset details,
    if data exists, name is already there */
    this.nameLoading = true;
    const filter = '?type=' + CONSTANTS.train + '&name=' + $event.target.value;
    this.workspaceService
      .getDataSetsWithFilter(filter)
      .subscribe((data: any) => {
        const dataElement = data.data[0];
        if (dataElement != null) {
          this.isPresent = true;
        } else {
          this.isPresent = false;
        }
      });
    setTimeout(() => {
      this.nameLoading = false;
    }, 1000);
  }
  ngOnChanges(changes): void {
    console.log(changes);
    if (this.isPassFail) {
      this.isGradingSelected = true;
    }
    if (this.selectedVideosArray?.length < 1) {
      this.isCreateTotalDataSet = false;
    }
  }
  onClose(): void {
    this.toggleGrading.emit();
  }
  // fm tab click
  onFmSelected(): void {
    this.isGradingSelected = false;
  }
  omit_special_char(event): boolean {
    let k;
    k = event.keyCode;
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k === 95 ||
      k === 8 ||
      k === 189 ||
      (k >= 48 && k <= 57)
    );
  }
  // grading tab click
  onGradingSelected(): void {
    this.isGradingSelected = true;
  }
  onLineChange(e, id): void {
    this.selectedLineId = id;
  }
  // grading value radio button change
  radioChange($event): void {
    this.selectedGradeValue = $event.value;
    if (this.selectedGradeValue === 'Fail') {
      this.isFail = true;
    } else {
      this.isFail = false;
      this.selectedFailedReason = '';
    }
  }
  // text area for comments
  onTextAreaChange(value): void {
    this.comments = value;
  }
  // selected fail reason for grading
  getFailedReason($event): void {
    this.selectedFailedReason = $event;
  }
  getFMMeasure(e): void {
    this.selectedFMMeasure = e.value;
  }
  // full screen button click
  viewFullScreen(): void {
    this.isViewFullScreen = !this.isViewFullScreen;
    this.isDrawerOpen = false;
  }
  getConnectionSelected(e): void {
    console.log('value connection', e.value.id);
    this.selectedConectionValue = e.value.name;
  }
  isStorageRequiredChange(value): void {
    this.isExternalStorageRequired = value;
  }
  updateMetada(): void {
    let data;
    if (this.isUpdateLineNo) {
      data = {
        line: this.selectedLineId,
      };
    } else if (this.isCreateTotalDataSet) {
      this.videoStatus = true;
      this.trayIdMatch = true;
      if (this.chkAisiApp) {
        for (const i of this.selectedVideosArray) {
          if (i.status === 'imagesExtracted') {
            this.videoStatus = false;
          }
        }
        if (!this.videoStatus) {
          toast()
            .danger('Warning! ', MESSAGES.NOT_ANNOTATED)
            .with({
              duration: 4000,
              speed: 1000,
              positionX: 'end',
              positionY: 'top',
            })
            .show();
        } else {
          const trayId = this.selectedVideosArray[0].trayBarcode;
          for (const i of this.selectedVideosArray) {
            if (i.trayBarcode !== trayId) {
              this.trayIdMatch = false;
            }
          }
          if (this.trayIdMatch) {
            const datasetPurpose = this.gradingFormGroup.controls.datasetPurpose
              .value
              ? this.gradingFormGroup.controls.datasetPurpose.value
              : null;
            data = {
              dataset: {
                name: this.gradingFormGroup.controls.datasetName.value.replace(
                  /-/g,
                  '_'
                ),
                purpose: datasetPurpose,
                appId: localStorage.getItem('selectedApp'),
                type: CONSTANTS.train,
                status: true,
                level: 'Base',
              },
              objectId: this.selectedVideosArray[0].trayId,
              medias: { videos: [] },
            };
            const vidArray = [];
            this.selectedVideosArray.map((x) => {
              vidArray.push(x.videoId);
            });
            data.medias.videos = vidArray;
            this.imageMetadataService.createMasterDataset(data).subscribe(
              (res: any) => {
                if (res.data) {
                  toast()
                    .success('Success! ', MESSAGES.DATASET_ADDED)
                    .with({
                      duration: 4000,
                      speed: 1000,
                      positionX: 'end',
                      positionY: 'top',
                    })
                    .show();
                  this.gradingFormGroup.controls.datasetName.setValue('');
                  this.gradingFormGroup.controls.datasetPurpose.setValue('');
                  this.onClose();
                } else if (res?.message) {
                  toast()
                    .danger('Error! ', res.message)
                    .with({
                      duration: 4000,
                      speed: 1000,
                      positionX: 'end',
                      positionY: 'top',
                    })
                    .show();
                }
              },
              (error) => {
                toast()
                  .danger('Error! ', error.error.message)
                  .with({
                    duration: 4000,
                    speed: 1000,
                    positionX: 'end',
                    positionY: 'top',
                  })
                  .show();
              }
            );
          } else {
            toast()
              .danger('Warning! ', MESSAGES.DIFFERENT_TRAY)
              .with({
                duration: 4000,
                speed: 1000,
                positionX: 'end',
                positionY: 'top',
              })
              .show();
          }
        }
      } else {
        const datasetPurpose = this.gradingFormGroup.controls.datasetPurpose
          .value
          ? this.gradingFormGroup.controls.datasetPurpose.value
          : null;
        data = {
          dataset: {
            name: this.gradingFormGroup.controls.datasetName.value.replace(
              /-/g,
              '_'
            ),
            purpose: datasetPurpose,
            appId: localStorage.getItem('selectedApp'),
            type: 'MASTER',
            level: 'Base',
          },
          medias: {
            images: [],
          },
        };
        this.selectedImagesArray.map((image) => {
          data.medias.images.push(image.id);
        });
        this.imageMetadataService.createMasterDataset(data).subscribe(
          (res) => {
            if (res.data) {
              toast()
                .success('Success! ', MESSAGES.DATASET_ADDED)
                .with({
                  duration: 4000,
                  speed: 1000,
                  positionX: 'end',
                  positionY: 'top',
                })
                .show();
              this.gradingFormGroup.controls.datasetName.setValue('');
              this.gradingFormGroup.controls.datasetPurpose.setValue('');
              this.onClose();
            } else if (res?.message) {
              toast()
                .danger('Error! ', res.message)
                .with({
                  duration: 4000,
                  speed: 1000,
                  positionX: 'end',
                  positionY: 'top',
                })
                .show();
            }
          },
          (error) => {
            toast()
              .danger('Error! ', error.error.message)
              .with({
                duration: 4000,
                speed: 1000,
                positionX: 'end',
                positionY: 'top',
              })
              .show();
          }
        );
      }
    } else {
      data = {
        labeling: '',
        grading: this.selectedGradeValue,
        classification: 'Classified',
        comments: this.comments,
        status: 'Labelled',
        fmMeasure: this.selectedFMMeasure,
        failReason: this.selectedFailedReason,
      };
    }
    if (this.comments.trim() !== '') {
      data.comments = this.comments;
    }

    if (!this.isCreateTotalDataSet) {
      this.updateMetadata.emit(data);
    }
  }

  checkCreateTotalDataset(data: any): any {
    this.videoStatus = true;
    this.trayIdMatch = true;
    for (const i of this.selectedVideosArray) {
      if (i.status === 'imagesExtracted') {
        this.videoStatus = false;
      }
    }
    if (!this.videoStatus) {
      this.warning();
    } else {
      const trayId = this.selectedVideosArray[0].trayBarcode;
      for (const i of this.selectedVideosArray) {
        if (i.trayBarcode !== trayId) {
          this.trayIdMatch = false;
        }
      }
      if (this.trayIdMatch) {
        data = this.trayIdMatchCheck(data);
      } else {
        this.warningdiffTrayId();
      }
    }
    return data;
  }

  setData(data: any): any {
    data = {
      labeling: '',
      grading: this.selectedGradeValue,
      classification: 'Classified',
      comments: this.comments,
      status: 'Labelled',
      fmMeasure: this.selectedFMMeasure,
      failReason: this.selectedFailedReason,
    };
    return data;
  }

  trayIdMatchCheck(data: any): any {
    const datasetPurpose = this.gradingFormGroup.controls.datasetPurpose.value
      ? this.gradingFormGroup.controls.datasetPurpose.value
      : null;
    data = {
      dataset: {
        name: this.gradingFormGroup.controls.datasetName.value.replace(
          /-/g,
          '_'
        ),
        purpose: datasetPurpose,
        appId: localStorage.getItem('selectedApp'),
        type: CONSTANTS.train,
        status: true,
        level: 'Base',
      },
      objectId: this.selectedVideosArray[0].trayId,
      medias: { videos: [] },
    };
    const vidArray = [];
    this.selectedVideosArray.map((x) => {
      vidArray.push(x.videoId);
    });
    data.medias.videos = vidArray;
    this.createMasterDataset(data);
    return data;
  }

  warningdiffTrayId(): void {
    toast()
      .danger('Warning! ', MESSAGES.DIFFERENT_TRAY)
      .with({
        duration: 4000,
        speed: 1000,
        positionX: 'end',
        positionY: 'top',
      })
      .show();
  }

  warning(): void {
    toast()
      .danger('Warning! ', MESSAGES.NOT_ANNOTATED)
      .with({
        duration: 4000,
        speed: 1000,
        positionX: 'end',
        positionY: 'top',
      })
      .show();
  }

  createMasterDataset(data: any): void {
    this.imageMetadataService.createMasterDataset(data).subscribe(
      (res: any) => {
        if (res.data) {
          toast()
            .success('Success! ', MESSAGES.DATASET_ADDED)
            .with({
              duration: 4000,
              speed: 1000,
              positionX: 'end',
              positionY: 'top',
            })
            .show();
          this.gradingFormGroup.controls.datasetName.setValue('');
          this.gradingFormGroup.controls.datasetPurpose.setValue('');
          this.onClose();
        } else if (res?.message) {
          toast()
            .danger('Error! ', res.message)
            .with({
              duration: 4000,
              speed: 1000,
              positionX: 'end',
              positionY: 'top',
            })
            .show();
        } else {
        }
      },
      (error) => {
        toast()
          .danger('Error! ', error.error.message)
          .with({
            duration: 4000,
            speed: 1000,
            positionX: 'end',
            positionY: 'top',
          })
          .show();
      }
    );
  }

  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    const trimmedText = pastedText.replace(/[^a-zA-Z0-9_]/g, '_');
    const name = this.gradingFormGroup.value.datasetName.replace(
      pastedText,
      ''
    );
    setTimeout(() => {
      this.gradingFormGroup.controls.datasetName.setValue(name + trimmedText);
    }, 1000);
  }
}
