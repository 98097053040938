import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from '@core-services/util.service';
@Pipe({
  name: 'dateLocal',
})
export class DateLocalPipe implements PipeTransform {
  constructor(private utilService: UtilService) {}
  transform(value: any, args: any[]): any {
    const formattedDate = this.utilService.formatDateUTCToLocal(value);
    return formattedDate;
  }
}
