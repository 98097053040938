const modes = {
  experiment: 1,
  results: 2,
  mflow: 3,
};
const pages = {
  management: 1,
  deployment: 2,
  details: 3,
};
const toastParams = {
  duration: 4000,
  speed: 1000,
  positionX: 'end',
  positionY: 'top',
};
const datasetTypes = {
  master: 'master',
  train: 'train',
};
const btnValues = {
  retrain: 'Retrain',
  result: 'Result',
};
const status = {
  successful: 'SUCCESSFUL',
  failed: 'FAILED',
  success: 'SUCCESS',
  reTrain: 'RETRAIN',
  notDeployed: 'NOT DEPLOYED',
};
const hyperParams = {
  lr: 0.005,
  momentum: 0.9,
  weight_decay: 0.0005,
  step_size: 3,
  gamma: 0.1,
  epochs: 1,
  threshold: 0.7,
  tray_id: '',
  site_id: '',
  repeat: 1,
  batch_size: 4,
};

const tokenInterval = 720;

const performanceThreshold = 0.98;

const searchTypes = {
  experiments: 'experiments',
  models: 'models',
  datasets: 'datasets',
};

const dataTypes = {
  number: 'number',
  string: 'string',
};
const BTN_DOWNLOAD_IMG = 'btn_download_image';

export {
  modes,
  datasetTypes,
  pages,
  toastParams,
  status,
  tokenInterval,
  hyperParams,
  searchTypes,
  BTN_DOWNLOAD_IMG,
  performanceThreshold,
  btnValues,
  dataTypes,
};
