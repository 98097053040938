<div class="relative flex w-full flex-wrap p-2 pl-4">
  <div class="inline-grid grid-cols-3 gap-x-4 w-full">
    <span class="col-span-2 text-sm text-gray-500">{{ text }}</span>
    <span class="text-sm text-gray-500">{{ value }}</span>
  </div>
  <div class="overflow-hidden h-2 mt-2 text-xs flex rounded bg-gray-100 w-5/6">
    <div
      [style]="style"
      class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-300"
    ></div>
  </div>
</div>
