<button
  [id]="id"
  (mouseover)="btnMouseOver()"
  (mouseout)="btnMouseOut()"
  [title]="tooltip"
  [ngClass]="class"
  (click)="btnClick($event); btnClickEvent($event)"
  [disabled]="disabled"
>
  <span class="flex flex-row justify-center">
    <span *ngIf="icon">
      <img
        [ngClass]="{ 'mt-0.5': text }"
        [class]="iconClass"
        [src]="iconSource"
        [alt]="id"
      />
    </span>
    <span *ngIf="text" [ngClass]="{ 'ml-2 mt-0.5': icon }">
      {{ text }}
    </span>
  </span>
</button>
