<div class="container mx-auto flex justify-between">
  <ul class="flex">
    <!--Regular Link-->
    <li
      *ngFor="let item of navList; let i = index"
      class="navigation-main-li text-center"
      [ngClass]="
        item.label.trim() == ''
          ? ' w-min ' + navListStyle
          : ' w-max ' + navListStyle
      "
    >
      <a
        [routerLink]="[item.path]"
        (click)="getActiveClass(item)"
        class="navigation-main-anchor"
        [ngClass]="navAnchorStyle"
        [routerLinkActive]="activeLink"
      >
        <span
          *ngIf="item && item.icon && item.icon.indexOf('.svg') != -1"
          [inlineSVG]="item.icon"
          class="inline -mt-1 inlineSVG"
        ></span>
        <img
          *ngIf="item && item.icon && item.icon.indexOf('.svg') == -1"
          [src]="item.icon"
          class="inline -mt-1"
        />
        {{ item.label }}</a
      >
    </li>
  </ul>
</div>
