import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { singleSelect } from '@shared/shared.constant';

@Component({
  selector: 'gdx-dropdown',
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent {
  @Input('id') id: any;
  @Input('name') name = '';
  @Input('placeholder') placeholder = 'Select';
  @Input('options') options: any[] = [];
  @Input('control') control: any;
  @Input('showClear') showClear = false;
  @Input('class') class = '';
  @Input('optionLabel') optionLabel = 'name';
  @Input('optionValue') optionValue = 'id';
  @Input('filter') filter = false;
  @Input('disabled') disabled = false;
  @Input('virtualScroll') virtualScroll = false;
  @Input('itemSize') itemSize = singleSelect.ITEM_SIZE;
  @Input('parentForm') parentForm: FormGroup = new FormGroup({});
  @Input('style') style = '';
  @Input('panelStyleClass') panelStyleClass = '';
  @Output('onChange') onChange = new EventEmitter();
  @Output('onBlur') onBlur = new EventEmitter();
  @Output('onClear') onClear = new EventEmitter();

  dropdownChange(event: any): any {
    this.onChange.emit(event);
  }

  dropdownBlur(event: any): any {
    this.onBlur.emit(event);
  }

  dropdownClear(event: any): any {
    this.onClear.emit(event);
  }
}
