export enum btnTypes {
  primary = 'PRIMARY',
  submit = 'SUBMIT',
  reset = 'RESET',
  test = 'TEST',
  approve = 'APPROVE',
  reject = 'REJECT',
  image = 'IMAGE',
  rule = 'RULE',
  outline = 'OUTLINE',
  icons = 'ICON',
  deprecated = 'DEPRECATED',
  success = 'SUCCESS',
  cancel = 'CANCEL',
}
