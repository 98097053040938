<mat-card-content *ngIf="source.show">
  <h6 class="hierarchy-label">{{ source.sourceType }}</h6>
  <section class="mat-card-line">
    <span>
      <mat-radio-group [(ngModel)]="selectedSource">
        <mat-radio-button
          *ngFor="let pp of source.list; let i = index"
          [value]="pp.id"
          [checked]="pp.checked"
          (change)="onParentChange($event, source.sourceType, pp.name)"
        >
          {{ pp.name }}
        </mat-radio-button>
      </mat-radio-group>
    </span>
  </section>
</mat-card-content>
