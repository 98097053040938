import {
  Component,
  OnInit,
  DoCheck,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataService } from 'src/app/core/services/data.service';
import { ReportsService } from '@pages/reports/services/reports-service.service';
import { btnTypes } from '../../enum/button';
import * as moment from 'moment';
import { AppService } from '../../../core/services/app.service';
import { PerformanceConfigInfo } from '@shared/components/performance-filter/performance-filter.component.types';
import { REPORTS_CONSTANTS } from '@pages/reports/reports-constant';
import { labels } from '@shared/components/performance-filter/performance-filter.message';

@Component({
  selector: 'gdx-performance-filter',
  templateUrl: './performance-filter.component.html',
})
export class PerformanceFilterComponent implements OnInit, DoCheck {
  constructor(
    public formBuilder: FormBuilder,
    private dataService: DataService,
    private reportsService: ReportsService,
    private appService: AppService
  ) {}
  @Output() expanded = new EventEmitter<boolean>();
  @Output() onApplyFilter = new EventEmitter<object>();
  @Input() performanceClass = '';
  userList: Array<any> = [];
  categories = [];
  events = [];
  actions = [];
  table = [];
  apps = [];
  esignReasons = [];
  status = [{ name: 'success' }, { name: 'failed' }];
  isAdvanced = false;
  filterOpen = false;
  isShow = true;
  label = labels;
  expand = 'assets/images/filter.svg';
  collapse = 'assets/images/cross.svg';
  advanced = '(+)';
  notAdvanced = '(-)';
  public btnType = btnTypes;
  public performanceFilter: FormGroup = new FormGroup({});
  allSelectPlaceHolder = 'Select';
  maxDate: any = new Date();
  errorMessage = '';
  isAppDisabled = false;
  trayData = [];
  kitData = [];

  performanceForm(): void {
    this.performanceFilter = this.formBuilder.group({
      selectedPeriod: [
        [
          moment(new Date(new Date().setHours(0, 0, 0)))
            .subtract(1, 'months')
            .toDate(),
          new Date(),
        ],
      ],
      selectedUser: [] as any,
      selectedTray: [] as any,
      selectedKit: [] as any,
    });
  }

  ngOnInit(): void {
    this.getUserList();
    this.performanceForm();
  }
  getUserList(): void {
    this.reportsService
      .getUserList()
      .subscribe((result: PerformanceConfigInfo) => {
        if (result && result.data) {
          this.userList = result.data;
        }
      });
  }

  getCategoriesData(): void {
    const queryParam = 'getCategoryList';
  }
  getEventsData(): void {
    const queryParam = 'getEventList';
  }

  getActionData(): void {
    const queryParam = 'getActionList';
  }

  getTableData(): void {
    const queryParam = 'getTableList';
  }

  getEsignReasonsData(): void {
    const queryParam = 'getReasonList';
  }
  getAppList(): void {
    const queryParam = 'getAppList';
  }
  ngDoCheck(): void {
    this.dataService.onHide.subscribe((result) => {
      this.isShow = result;
    });
  }

  expandCollapse = () => {
    this.filterOpen = !this.filterOpen;
    this.expanded.emit(this.filterOpen);
  };

  expandCollapseAdvanced = () => {
    this.isAdvanced = !this.isAdvanced;
  };
  applyFilter(event: any): void {
    const filterData = this.performanceFilter.value;
    const filterdValue = {
      startDate: new Date(),
      endDate: new Date(),
      inspectedBy: [] as any,
      kitId: [] as any,
      trayId: [] as any,
    };

    this.filterCheck(filterData, filterdValue);
    if (
      this.performanceFilter.controls.selectedPeriod &&
      this.performanceFilter.controls.selectedPeriod.value[0] &&
      this.performanceFilter.controls.selectedPeriod.value[1]
    ) {
      const startDate = moment(
        this.performanceFilter.controls.selectedPeriod.value[0]
      );
      const endDate = moment(
        this.performanceFilter.controls.selectedPeriod.value[1]
      );
      const diff = endDate.diff(startDate, 'days');
      if (diff === 0) {
        filterdValue.startDate = moment(
          new Date(filterdValue.startDate).setHours(0, 0, 0)
        ).toDate();
        filterdValue.endDate = moment(
          new Date(filterdValue.startDate).setHours(23, 59, 59)
        ).toDate();
      }
    }
    this.onApplyFilter.emit({ filterdValue, event });
    this.expandCollapse();
  }

  filterCheck(
    filterData: any,
    filterdValue: {
      startDate: Date;
      endDate: Date;
      inspectedBy: any;
      kitId: any;
      trayId: any;
    }
  ): void {
    Object.keys(filterData).map((item: string) => {
      const key = item.replace('selected', '');
      if (item.includes(key)) {
        if (key === REPORTS_CONSTANTS.period) {
          this.periodCheck(filterdValue, filterData);
        } else if (key === REPORTS_CONSTANTS.user) {
          this.userCheck(filterdValue, filterData);
        } else if (key === REPORTS_CONSTANTS.kit) {
          this.kitCheck(filterdValue, filterData);
        } else if (key === REPORTS_CONSTANTS.tray) {
          this.trayCheck(filterdValue, filterData);
        } else {
        }
      }
    });
  }

  trayCheck(
    filterdValue: {
      startDate: Date;
      endDate: Date;
      inspectedBy: any;
      kitId: any;
      trayId: any;
    },
    filterData: any
  ): void {
    filterdValue.trayId = [];
    if (filterData.selectedTray && filterData.selectedTray.length > 0) {
      filterData.selectedTray.map((obj: any) => {
        filterdValue.trayId.push(obj.id);
      });
    }
  }

  kitCheck(
    filterdValue: {
      startDate: Date;
      endDate: Date;
      inspectedBy: any;
      kitId: any;
      trayId: any;
    },
    filterData: any
  ): void {
    filterdValue.kitId = [];
    if (filterData.selectedKit && filterData.selectedKit.length > 0) {
      filterData.selectedKit.map((obj: any) => {
        filterdValue.kitId.push(obj.id);
      });
    }
  }

  userCheck(
    filterdValue: {
      startDate: Date;
      endDate: Date;
      inspectedBy: any;
      kitId: any;
      trayId: any;
    },
    filterData: any
  ): void {
    filterdValue.inspectedBy = [];
    if (filterData.selectedUser && filterData.selectedUser.length > 0) {
      filterData.selectedUser.map((obj: any) => {
        filterdValue.inspectedBy.push(obj.inspectedBy);
      });
    }
  }

  periodCheck(
    filterdValue: {
      startDate: Date;
      endDate: Date;
      inspectedBy: any;
      kitId: any;
      trayId: any;
    },
    filterData: any
  ): void {
    filterdValue.startDate = filterData.selectedPeriod[0]
      ? filterData.selectedPeriod[0]
      : '';
    filterdValue.endDate = filterData.selectedPeriod[1]
      ? filterData.selectedPeriod[1]
      : moment(new Date(filterdValue.startDate).setHours(11, 59, 59)).toDate();
  }

  resetFilter(): void {
    this.performanceForm();
  }
}
