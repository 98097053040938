<div class="w-screen flex">
  <div class="w-20">
    <!--Add dynamic component -->
    <ng-template sipLoadCompHelper></ng-template>
  </div>
  <div class="flex justify-between">
    <ul class="flex -ml-2">
      <!--Regular Link-->
      <li
        *ngFor="let item of navList; let i = index"
        class="sub-navigation-li"
        [ngClass]="subNavListStyle"
      >
        <a
          [routerLink]="[item.path]"
          (click)="getActiveClass($event)"
          class="sub-navigation-anchor text-xs"
          [ngClass]="subNavAnchorStyle"
          ><span [routerLinkActive]="activeLink">
            <span
              *ngIf="item && item.icon && item.icon.indexOf('.svg') != -1"
              [inlineSVG]="item.icon"
              class="inline -mt-1 inlineSVG"
            ></span>
            <img
              *ngIf="item && item.icon && item.icon.indexOf('.svg') == -1"
              [src]="item.icon"
              class="inline -mt-1"
            />
            {{ item.label }}</span
          ></a
        >
      </li>
    </ul>
  </div>
</div>
