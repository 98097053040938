import { Component, Input, OnInit } from '@angular/core';
import { each } from 'lodash';

@Component({
  selector: 'gdx-grid-user',
  templateUrl: './grid-user.component.html',
  styles: [],
})
export class GridUserComponent implements OnInit {
  @Input() users: any = [];
  tooltipposition = 'above';

  gridUserList: any = [];
  totalCount = 0;
  displayList: any = [];
  startInd = 0;
  endInd = 0;
  listSize = 3;

  ngOnInit(): void {
    this.formatUserCircle();
  }

  scrollLeft(): void {
    this.startInd -= this.listSize;
    this.endInd -= this.listSize;
    this.changeDisplay();
  }

  scrollRight(): void {
    this.startInd += this.listSize;
    this.endInd += this.listSize;
    this.changeDisplay();
  }

  formatUserCircle(): void {
    each(this.users, (u) => {
      const sn = this.loadUserName(u);
      this.gridUserList.push({
        shortName: sn,
        fullName: u,
      });
    });
    this.totalCount = this.gridUserList.length;
    if (this.gridUserList.length > this.listSize) {
      this.endInd = this.listSize;
      this.changeDisplay();
    } else {
      this.endInd = this.gridUserList.length;
      this.displayList = this.gridUserList;
    }
  }

  changeDisplay(): void {
    this.displayList = this.gridUserList.slice(this.startInd, this.endInd);
  }

  private loadUserName(name: string): string {
    const formatedName = name.replace(/\[.+\]/g, '');
    let shortName: string;
    if (formatedName.indexOf(',') >= 0) {
      const arrName = formatedName.split(',');
      const lastName = arrName[0].trim();
      const firstName = arrName[1].trim();
      shortName = firstName.substring(0, 1) + lastName.substring(0, 1);
    } else {
      if (formatedName.indexOf(' ') >= 0) {
        const arrName = formatedName.split(' ');
        shortName = arrName[0].substring(0, 1) + arrName[1].substring(0, 1);
      } else {
        shortName = formatedName.substring(0, 1);
      }
    }
    return shortName;
  }
}
