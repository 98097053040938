import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { btnTypes } from '../../enum/button';

@Component({
  selector: 'gdx-no-app',
  templateUrl: './no-app.component.html',
  styles: [],
})
export class NoAppComponent implements OnInit {
  @Input() showModal = false;
  @Input() selectedRole = '';
  public btnType = btnTypes;
  roleList: any = [];
  actualRoleList: any = [];
  viewRoleModal = false;
  isSwitchRoleClose = false;
  role: any;
  roleName = '';
  constructor(private readonly msalService: MsalService) {}

  ngOnInit(): void {
    const token: any = this.msalService.getAccount();
    if (token?.idToken) {
      this.roleList = token.idToken.roles;
      this.actualRoleList = this.roleList.filter(
        (item: any) =>
          !item.toUpperCase().includes('APPACCESS') &&
          !item.toUpperCase().includes('DATAACCESS')
      );
    }
  }

  logout(): void {
    this.showModal = false;
    this.selectedRole = '';
    this.msalService.logout();
  }

  switchRoleClick(): void {
    this.viewRoleModal = true;
    this.showModal = false;
  }

  changeRoleEvent(role: any): void {
    this.viewRoleModal = false;
    if (role && role.initial) {
      this.role = role.initial;
      this.roleName = role.name;
    }
  }
}
