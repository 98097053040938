import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(value: any, args?: any, keyFilter: any = []): any {
    if (!value) {
      return null;
    }
    if (!args) {
      return value;
    }

    args = args.toLowerCase();
    if (keyFilter.length > 0) {
      const rtItems: any = value;
      return value.filter((it: any) => {
        let result = false;
        for (const f of keyFilter) {
          if (it[f].toLowerCase().indexOf(args.toLowerCase()) > -1) {
            result = true;
          }
        }
        return result;
      });
    }

    return value.filter((item: any) => {
      return JSON.stringify(item).toLowerCase().includes(args);
    });
  }
}
