import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';

// Stackable Toast messages and does not block any element
@Component({
  selector: 'gdx-toast',
  templateUrl: './toast.component.html',
  styles: [],
})
export class ToastComponent {
  counter = 0;
  list: any = [];

  public createToast(message: string, type = 'info'): any {
    const index = this.list.length;
    const totalVisible =
      this.list.filter((toast: any) => {
        return toast.visible;
      }).length + 1;

    this.list.push({
      id: this.counter++,
      message,
      type,
      visible: true,
    });

    setTimeout(() => {
      this.destroyToast(index);
    }, 4000 * totalVisible);
  }

  destroyToast(index: number): void {
    this.list[index].visible = false;
  }

  getClass(toast: any): string {
    let ret = '';

    switch (toast.type) {
      case 'info':
        ret = 'from-blue-500 to-blue-600';
        break;
      case 'success':
        ret = 'from-green-500 to-green-600';
        break;
      case 'warning':
        ret = 'from-yellow-400 to-yellow-500';
        break;
      case 'error':
      default:
        ret = 'from-red-600 to-red-700';
        break;
    }
    return ret;
  }
}
