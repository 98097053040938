import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-create-button',
  templateUrl: './create-button.component.html',
  styleUrls: ['./create-button.component.css'],
})
export class CreateButtonComponent {
  @Output() fnClick = new EventEmitter();
  @Input() hasIcon: boolean; // Whether to show + icon
  @Input() btnLabel: string; // labelName is the button text to be appended after "Create New"
  onClick(): any {
    this.fnClick.emit();
  }
}
