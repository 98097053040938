<div [formGroup]="parentForm">

  <label for="id" class="text-sm cursor-pointer"><input
    type="checkbox"
    class="form-checkbox h-5 w-5 mr-2 text-blue-600 border-gray-300 rounded-sm float-left"
    [name]="name"
    id="id"
    [formControlName]="control"
    [checked]="checked"
    (change)="valueChange($event)"
  />
  <p class="float-left" *ngIf="label && label !== ''">
    {{
      label
    }}
  </p>
</label>
</div>
