import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { each, cloneDeep } from 'lodash';

@Component({
  selector: 'gdx-dropdown-with-delete',
  templateUrl: './dropdown-with-delete.component.html',
})
export class DropdownWithDeleteComponent implements OnInit {
  @Input() datalist: any;
  @Output() deleteValue = new EventEmitter<any>();

  ngOnInit(): void {
    this.prepareData();
  }

  prepareData(): void {
    each(this.datalist, (data) => {
      data.drpdownOpen = false;
      if (data.value && data.value.length > 2) {
        data.displayValue = data.value.slice(0, 2);
        data.otherValues = data.value.slice(2, data.value.length);
        data.toolTip = 'Click to see all ' + data.key;
      } else {
        data.toolTip = '';
        data.displayValue = cloneDeep(data.value);
        data.otherValues = [];
      }
    });
  }

  onDeleteValue(key: any, value: any): any {
    this.deleteValue.emit({ map: key, val: value });
  }

  toggleDropdown(data: any): void {
    if (data.otherValues && data.otherValues.length > 0) {
      if (!data.drpdownOpen) {
        each(this.datalist, (d) => {
          d.drpdownOpen = false;
        });
      }
      data.drpdownOpen = !data.drpdownOpen;
    }
  }
}
