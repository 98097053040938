import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ESignService {
  baseURL = environment.entryAPI;

  constructor(private http: HttpClient) {}

  private hideSourceHierarchy = new Subject<boolean>();
  hideSource$ = this.hideSourceHierarchy.asObservable();

  sendMessage(message: boolean): void {
    this.hideSourceHierarchy.next(message);
  }

  getEsignReasonList(type: string): any {
    return this.http.get(this.baseURL + 'esigns/reasons/' + type);
  }

  authenticateUserDetails(user: any): any {
    return this.http.post(this.baseURL + 'esigns/authenticate', user);
  }
}
