const mainTitle = 'SIP';
const LABELS = {
  GO: 'Go',
  TRACK_AND_TRACE: 'TRACK_AND_TRACE',
  VERSION: 'Version',
  UPDATED: 'Updated',
  train: 'TRAIN',
};
const messages = {
  esign_message: 'e-signature failed',
};
const esign_status = {
  success: 'Success',
  fail: 'Failure',
};

export { mainTitle, LABELS, messages, esign_status };
