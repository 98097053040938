<div class="flex flex-row mb-3" *ngIf="hierarchyFilter">
  Filter
  <div class="ml-3">
    <select #fieldEl id="filter-field" (change)="updateFilter()">
      <option></option>
      <option *ngFor="let i of FilterDropdownArr" value="{{ i }}">
        {{ i }}
      </option>
    </select>
  </div>
  <div class="ml-3">
    <select #typeEl id="filter-type" (change)="updateFilter()">
      <option value="=">=</option>
      <option value="<"><</option>
      <option value="<="><=</option>
      <option value=">">></option>
      <option value=">=">>=</option>
      <option value="!=">!=</option>
      <option value="like">like</option>
    </select>
  </div>
  <div class="ml-3">
    <input
      #valueEl
      id="filter-value"
      type="text"
      placeholder="value to filter"
      (input)="updateFilter()"
    />
  </div>
  <div class="ml-3">
    <gdx-button
      [id]="'Clear'"
      text="Clear"
      [class]="'text-white bg-blue-500 hover:bg-blue-700 ml-2 md:mt-0 sm:mt-2'"
      (clicked)="ClearFilter()"
    >
    </gdx-button>
  </div>
</div>
<div #tabulator class="z-0"></div>
