import {
  Component,
  DoCheck,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'gdx-genius-data-modal',
  templateUrl: './genius-data-modal.component.html',
  styles: [],
})
export class GeniusDataModalComponent {
  @Input() showGeniusDataModel = false;
  @Output('closeModal') closeModal = new EventEmitter();
  collapse = 'assets/images/cross.svg';

  close(): void {
    this.closeModal.emit('false');
  }
}
