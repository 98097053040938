<label class="switch">
  <input
    class="form-check-input rounded"
    type="checkbox"
    [value]="value"
    [checked]="checked"
    (change)="onToggle($event)"
  />
  <span class="slider round"></span>
</label>
