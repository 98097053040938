import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'sasToken',
})
export class SasTokenPipe implements PipeTransform {
  sas = localStorage.getItem('sasToken');
  transform(value: any, args: any[]): any {
    const url = value + '?' + this.sas;
    return url;
  }
}
