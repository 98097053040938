import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { btnTypes } from 'src/app/shared/enum/button';
import { ESignService } from '@core-services/e-sign.service';
import { IesignReason } from './e-sign.component.type';
import * as crypto from 'crypto-js';
const { toast } = require('tailwind-toast');
import { messages } from '@shared/shared.message';
import { AppConfigService } from '@core-services/app-config.service';
import { labels } from './e-sign.labels';

@Component({
  selector: 'sip-e-sign',
  templateUrl: './e-sign.component.html',
  styleUrls: ['./e-sign.component.scss'],
})
export class ESignComponent implements OnInit {
  @Output() eVerified: EventEmitter<any> = new EventEmitter();
  @Output() closePanel: EventEmitter<any> = new EventEmitter();
  @Input() type: any;
  @Input() compType: any;
  public esign: any = {};
  public userName = '';
  public fullUserName = '';
  public labels = labels;

  @ViewChild('esignModal', { static: false }) modal!: ElementRef;
  showEsign = true;
  public btnType = btnTypes;
  public eSignFormGrp: FormGroup = new FormGroup({});
  public eSignList: Array<IesignReason> = [];
  public saveSigned = false;
  public userLogged = false;
  public userFull = false;
  toggleModal(): void {
    this.showEsign = !this.showEsign;
  }
  constructor(
    public formBuilder: FormBuilder,
    private readonly msalService: MsalService,
    private readonly eSignService: ESignService,
    private appConfigService: AppConfigService
  ) {
    this.esignForm();
  }
  ngOnInit(): void {
    this.userName = this.msalService
      .getAccount()
      .userName.split('@')[0]
      .toLowerCase();
    this.getEsignReasonList();
  }
  esignForm(): void {
    this.eSignFormGrp = this.formBuilder.group({
      esignUsername: ['', [Validators.required]],
      esignPassword: ['', [Validators.required]],
      esignReason: ['', [Validators.required]],
      esignComment: [''],
    });
  }
  checkUserName(user: string): void {
    const email = this.msalService.getAccount().userName;
    if ((user && user.toLowerCase() === this.userName) || user === email) {
      this.userLogged = false;
      this.userFull = true;
      this.fullUserName = this.msalService
        .getAccount()
        .idToken.name.split('[')[0];
    } else {
      this.userLogged = true;
      this.userFull = false;
    }
  }
  closeEsign(): void {
    this.showEsign = false;
    this.closePanel.emit('close');
  }
  getEsignReasonList(): void {
    this.eSignService
      .getEsignReasonList(this.compType)
      .subscribe((data: any) => {
        this.eSignList = data.data;
      });
  }
  verifyEsign(): void {
    this.saveSigned = true;
    this.eSignService
      .authenticateUserDetails({
        username: this.eSignFormGrp.value.esignUsername.toLowerCase(),
        secret: this.encrypt(this.eSignFormGrp.value.esignPassword),
      })
      .subscribe(
        (res: any) => {
          if (res) {
            const data = {
              user: this.msalService.getAccount().userName,
              reason: this.eSignFormGrp.value.esignReason.name,
              comment: this.eSignFormGrp.value.esignComment,
              state: labels.success,
              status: 1,
            };
            this.eVerified.emit(data);
            this.showEsign = false;
          }
        },
        (err) => {
          if (err) {
            toast()
              .danger('Error! ', messages.esign_message)
              .with({
                duration: 4000,
                speed: 1000,
                positionX: 'end',
                positionY: 'top',
              })
              .show();
            const data = {
              user: this.msalService.getAccount().userName,
              reason: this.eSignFormGrp.value.esignReason.name,
              comment: this.eSignFormGrp.value.esignComment,
              state: labels.failed,
              status: 0,
            };
            this.eVerified.emit(data);
            this.showEsign = false;
          }
        }
      );
  }
  encrypt(msg: any): string {
    const encKey = this.appConfigService.getEncryptionKey();
    const encrypted = crypto.AES.encrypt(msg, encKey);
    const transitmessage = encrypted.toString();
    return transitmessage;
  }
}
