import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './shared/components';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },

  {
    path: 'reports',
    loadChildren: () =>
      import('./pages/reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'jjvc/reports',
    loadChildren: () =>
      import('./pages/reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'access-denied',

    loadChildren: () =>
      import('@pages/access-denied/access-denied.module').then(
        (m) => m.AccessDeniedModule
      ),
  },
  {
    path: 'manage-dataset',
    loadChildren: () =>
      import('./pages/manage-dataset/manage-dataset.module').then(
        (m) => m.ManageDatasetModule
      ),
  },
  {
    path: 'content-management',
    loadChildren: () =>
      import('./pages/content-management/content-management.module').then(
        (m) => m.ContentManagementModule
      ),
  },
  {
    path: 'set-management-page',
    loadChildren: () =>
      import('./pages/set-management-page/set-management-page.module').then(
        (m) => m.SetManagementPageModule
      ),
  },
  {
    path: 'annotation',
    loadChildren: () =>
      import('@pages/image-preview/image-preview.module').then(
        (m) => m.ImagePreviewModule
      ),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home-page/home-page.module').then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./pages/app-settings/app-settings.module').then(
        (m) => m.AppSettingsModule
      ),
  },
  {
    path: 'jjvc/settings',
    loadChildren: () =>
      import('./pages/app-settings/app-settings.module').then(
        (m) => m.AppSettingsModule
      ),
  },
  {
    path: 'manage-source',
    loadChildren: () =>
      import('./pages/manage-source/manage-source.module').then(
        (m) => m.ManageSourceModule
      ),
  },
  {
    path: 'role-management',
    loadChildren: () =>
      import('./pages/role-management/role-management.module').then(
        (m) => m.RoleManagementModule
      ),
  },
  {
    path: 'jjvc/role-management',
    loadChildren: () =>
      import('./pages/role-management/role-management.module').then(
        (m) => m.RoleManagementModule
      ),
  },
  {
    path: 'application',
    loadChildren: () =>
      import('./pages/application-page/application-page.module').then(
        (m) => m.ApplicationPageModule
      ),
  },
  {
    path: 'upload',
    loadChildren: () =>
      import('./pages/image-upload/image-upload.module').then(
        (m) => m.ImageUploadModule
      ),
  },
  {
    path: 'jjvc/upload',
    loadChildren: () =>
      import('./pages/image-upload/image-upload.module').then(
        (m) => m.ImageUploadModule
      ),
  },
  {
    path: 'image',
    loadChildren: () =>
      import('@pages/image-fullview-page/image-fullview.module').then(
        (m) => m.ImageFullViewModule
      ),
  },

  {
    path: 'manage-dataset-app-admin',
    loadChildren: () =>
      import(
        './pages/manage-dataset-app-admin/manage-dataset-app-admin.module'
      ).then((m) => m.ManageDatasetAppAdminModule),
  },
  {
    path: 'manage-external-users',
    loadChildren: () =>
      import('./pages/manage-external-users/manage-external-users.module').then(
        (m) => m.ManageExternalUsersModule
      ),
  },
  {
    path: 'track-n-trace',
    loadChildren: () =>
      import('./pages/track-n-trace/track-n-trace.module').then(
        (m) => m.TrackNTraceModule
      ),
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
