import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from '@core-services/util.service';
@Pipe({
  name: 'concatText',
})
export class ConcatTextPipe implements PipeTransform {
  constructor(private utilService: UtilService) {}
  transform(value: any[], args: any[]): string {
    const mergedText = this.utilService.concatTexts(value);
    return mergedText;
  }
}
