<div
  *ngIf="showModal"
  class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none h-screen focus:outline-none justify-center items-center flex"
>
  <div class="relative w-auto my-6 mx-auto max-w-xl">
    <!--content-->
    <div
      class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
    >
      <!--header-->
      <div
        class="flex items-start h-12 border-b border-solid border-blueGray-200 rounded-t bg-red-700 p-3"
      >
        <h4 class="text-left absolute text-white">NO ROLE</h4>
      </div>
      <!--body-->
      <div class="relative">
        <div
          class="my-4 text-blueGray-500 leading-relaxed font-medium p-2 px-6"
        >
          <div>
            No role is tagged to this user. Please contact System Administrator.
          </div>
        </div>
      </div>
      <div class="flex items-center p-4 justify-end rounded-b">
        <gdx-button
          [id]="'noRoleLogout'"
          [text]="'Sign Out'"
          [type]="btnType.submit"
          (clicked)="logout()"
        >
        </gdx-button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
`
